import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Grid from "@material-ui/core/Grid";
import logo_small from "../../images/LOGO_new.jpg";
import logo from "../../images/LOGO_new.jpg";
import logo_average from "../../images/LOGO_new.jpg";
import card from "../../images/card_logo.jpg";
import {Box} from "@material-ui/core";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';



function Copyright() {
    return (

        <Typography variant="body2" color="textSecondary" align="left">
            <Link color="inherit" href="https:/pizza7stars.com.ua/">
                © "PIZZA 7 STARS"
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    footer: {
        backgroundColor: theme.palette.background.paper,
        // marginTop: theme.spacing(8),
        padding: theme.spacing(0, 0),
        margin: theme.spacing(2, 0),
    },
    logo: {
        height: '100px',
        margin: '5px',
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block',
        },

    },
    logo_small: {
        height: '80px',
        margin: '7px',
        display: 'block',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },

    },

    card: {
        height: '75px',
        margin: '5px',
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block',
        },

    },
    card_small: {
        height: '55px',
        margin: '7px',
        display: 'block',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },

    },

    logo_it: {
        height: '60px',
        margin: '5px',
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },

}));

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);


// function CustomizedDialogs (handleClose, open) {
//
//
//     return (
//
//     );
// }

export default function Footer(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    return (
        <footer className={classes.footer}>
            <Container maxWidth="lg">

                <Grid container spacing={3}
                      direction="row"
                      alignItems="flex-start">

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Box>
                            <img height='100%' className={classes.logo_it} alt="Logo Italy" src={logo_small}/>
                            <img className={classes.logo} alt="Logo PIZZA 7 STARS" src={logo}/>
                            <img className={classes.logo_small} alt="Small Logo PIZZA 7 STARS" src={logo_average}/>

                        </Box>

                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Copyright />
                        {/*<Typography variant="body2" color="textSecondary" align="left">*/}
                        {/*    (067) 523-35-33 Житомир*/}
                        {/*</Typography>*/}
                        <Typography variant="body2" color="textSecondary" align="left">
                            (066) 097-47-72 Звягель
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Box>
                            <Typography variant="body2" color="textSecondary" align="left" onClick={handleClickOpen}>
                                Правова інформація
                            </Typography>
                            <Typography variant="body2" color="textSecondary" align="left">
                                Калорійність
                            </Typography>

                        </Box>
                    </Grid><Grid item xs={12} sm={6} md={4} lg={3}>
                        <Box>
                            <img height='100%' className={classes.logo_it} alt="Logo Italy" src={card}/>
                            <img className={classes.card} alt="Logo Pizza7starS" src={card}/>
                            <img className={classes.card_small} alt="Small Logo Pizza7starS" src={card}/>

                        </Box>
                    </Grid>

                </Grid>

                <div>
                    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} scroll='paper'>
                        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                            ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ та ПУБЛІЧНА ОФЕРТА
                        </DialogTitle>
                        <DialogContent dividers>
                            <DialogContentText
                                id="scroll-dialog-description"
                                tabIndex={-1}
                            >
                                <Box>
                                <p>Справжня Політика
                                    конфіденційності персональних даних (далі - Політика конфіденційності) діє відносно
                                    всієї інформації, яку сайт Піцерії «Pizza7stars» (https://pizza7stars.com.ua, може
                                    отримати про Користувача під час використання сайту, програм і продуктів «Pizza7stars».</p>
                                    1. ВИЗНАЧЕННЯ ТЕРМІНІВ<p>1.1 У цій Політиці конфіденційності
                                    використовуються такі терміни:</p><p>1.1.1. «Адміністрація сайту Піцерії «Pizza7stars»
                                    (далі - Адміністрація сайту)» - уповноважені співробітники на управління сайтом, що
                                    діють від імені «Pizza7stars», які організовують і здійснює обробку персональних
                                    даних, а також визначає цілі обробки персональних даних, склад персональних даних,
                                    що підлягають обробці, дії або операції, що здійснюються з персональними даними.</p>
                                    <p>1.1.2. «Персональні дані» - будь-яка інформація, що стосується прямо або побічно
                                        певного або визначається фізичній особі (суб'єкту персональних даних).</p>
                                    <p>1.1.3. «Обробка персональних даних» - будь-яка дія (операція) або сукупність дій
                                        (операцій), що здійснюються з використанням засобів автоматизації або без
                                        використання таких засобів з персональними даними, включаючи збір, запис,
                                        систематизацію, накопичення, зберігання, уточнення (оновлення, зміна), витяг,
                                        використання, передачу (поширення, надання, доступ), знеособлення, блокування,
                                        видалення, знищення персональних даних.</p><p>1.1.4. «Конфіденційність
                                        персональних даних» - обов'язкова для дотримання Адміністратором вимога не
                                        допускати поширення персональних даних без згоди суб'єкта персональних
                                        даних.</p><p>1.1.5. «Користувач сайту «Pizza7stars» - особа, яка має доступ до
                                        Сайту, за допомогою мережі Інтернет і використовує Сайт «Pizza7stars».</p>
                                    <p>1.1.6. «Cookies» - невеликий фрагмент даних, відправлений веб-сервером і
                                        зберігається на комп'ютері користувача, який веб-клієнт або веб-браузер кожен
                                        раз пересилає веб-серверу в HTTP-запиті при спробі відкрити сторінку
                                        відповідного сайту.</p><p>1.1.7. «IP-адреса» - унікальна мережева адреса вузла в
                                        комп'ютерній мережі, побудована за протоколом IP.</p><p></p><p>2. ЗАГАЛЬНІ
                                        ПОЛОЖЕННЯ</p><p>2.1. Використання Користувачем сайту «Pizza7stars» означає
                                        згоду з цією Політикою конфіденційності та умовами обробки персональних даних
                                        Користувача.</p><p>2.2. У разі незгоди з умовами Політики конфіденційності
                                        Користувач повинен припинити використання сайту «Pizza7stars» .</p><p></p><p>3.
                                        ПРЕДМЕТ ПОЛІТИКИ КОНФІДЕНЦІЙНОСТІ</p><p>3.1. Справжня Політика конфіденційності
                                        встановлює зобов'язання Адміністрації сайту «Pizza7stars» у щодо
                                        нерозголошення та забезпечення режиму захисту конфіденційності персональних
                                        даних, які Користувач повинен за вимогою надати Адміністрації сайту при
                                        оформленні замовлення для придбання Товару.</p><p>3.2. Персональні дані,
                                        дозволені до обробки в рамках цієї Політики конфіденційності, надаються
                                        Користувачем шляхом заповнення реєстраційної форми на Сайті «Pizza7stars» і
                                        включають в себе наступну інформацію:</p><p>3.2.1. ПІБ Користувача;</p><p>3.2.2.
                                        контактний телефон Користувача;</p><p>3.2.3. адреса доставки Товару;</p><p>3.3.
                                        «Pizza7stars» захищає Дані, які автоматично передаються в процесі перегляду
                                        рекламних блоків і при відвідуванні сторінок, на яких встановлено статистичний
                                        скрипт системи ("піксель"):</p><p>IP адреса;</p><p>інформація з cookies;</p>
                                    <p>інформація про браузер (чи іншої програми, яка здійснює доступ до показу
                                        реклами);</p><p>час доступу;</p><p>адреса сторінки, на якій розташований
                                        рекламний блок;</p><p>реферер (адреса попередньої сторінки).</p><p>3.4. Будь-яка
                                        інша персональна інформація, не обумовлена вище (історія покупок,
                                        використовувані браузери і операційні системи і т.д.), підлягає надійному
                                        зберіганню і нерозповсюдження, за винятком випадків, передбачених в п.п. 5.2. і
                                        5.3. цієї Політики конфіденційності.</p><p>4. ЦІЛІ ЗБОРУ ПЕРСОНАЛЬНОЇ ІНФОРМАЦІЇ
                                        КОРИСТУВАЧА</p><p>4.1. Персональні дані Користувача Адміністрація сайту "Pizzaf7stars" може використовувати в цілях:</p><p>4.1.1. Визначення місця знаходження
                                        Користувача для забезпечення безпеки, запобігання шахрайства.</p><p>4.1.2.
                                        Підтвердження достовірності та повноти персональних даних, наданих
                                        Користувачем.</p><p>4.1.3. Повідомлення Користувача Сайту «Pizza7stars» про
                                        стан Замовлення.</p><p>4.1.4. Надання Користувачеві ефективної клієнтської і
                                        технічної підтримки при виникненні проблем, пов'язаних з використанням Сайту
                                        «Pizza7stars».</p><p>4.1.5. Надання Користувачеві з його згоди, оновлень
                                        продукції, спеціальних пропозицій, інформації про ціни, розсилки новин та інших
                                        відомостей від імені «Pizza7stars».</p><p>4.1.6. Здійснення рекламної
                                        діяльності за згодою Користувача.</p><p>5. СПОСОБИ І ТЕРМІНИ ОБРОБКИ
                                        ПЕРСОНАЛЬНОЇ ІНФОРМАЦІЇ</p><p>5.1. Обробка персональних даних Користувача
                                        здійснюється без обмеження терміну, будь-яким законним способом, в тому числі в
                                        інформаційних системах персональних даних з використанням засобів автоматизації
                                        або без використання таких засобів.</p><p>5.2. Користувач погоджується з тим, що
                                        Адміністрація сайту має право передавати персональні дані третім особам,
                                        зокрема, кур'єрським службам, організаціями поштового зв'язку, операторам
                                        електрозв'язку, виключно з метою виконання замовлення Користувача, оформленого
                                        на Сайті «Pizza7stars», включаючи доставку Товару.</p><p>5.3. Персональні дані
                                        Користувача можуть бути передані уповноваженим органам державної влади України
                                        тільки на підставах та в порядку, встановленим законодавством України.</p>
                                    <p>5.4. При втраті або розголошення персональних даних Адміністрація сайту інформує
                                        Користувача про втрату або розголошення персональних даних.</p><p>5.5.
                                        Адміністрація сайту вживає необхідних організаційних і технічних заходів для
                                        захисту персональної інформації Користувача від неправомірного або випадкового
                                        доступу, знищення, перекручення, блокування, копіювання, поширення, а також від
                                        інших неправомірних дій третіх осіб.</p><p>5.6. Адміністрація сайту спільно з
                                        Користувачем вживає всіх необхідних заходів щодо запобігання збиткам або інших
                                        негативних наслідків, викликаних втратою або розголошенням персональних даних
                                        Користувача.</p><p>6. ЗОБОВ'ЯЗАННЯ СТОРІН</p><p>6.1. Користувач
                                        зобов'язаний:</p><p>6.1.1. Надати інформацію про персональні дані, необхідну для
                                        користування Сайтом «Pizza7stars».</p><p>6.2. Адміністрація сайту
                                        зобов'язана:</p><p>6.2.1. Використовувати отриману інформацію виключно для
                                        цілей, зазначених у п. 4 цієї Політики конфіденційності.</p><p>6.2.2.
                                        Забезпечити зберігання конфіденційної інформації в таємниці, не розголошувати
                                        без попередньої письмової згоди Користувача, а також не здійснювати продаж,
                                        обмін, опублікування, або розголошення іншими можливими способами переданих
                                        персональних даних Користувача, за винятком п.п. 5.2. і 5.3. цієї Політики
                                        Конфіденційності.</p><p>6.2.3. Вживати заходів обережності для захисту
                                        конфіденційності персональних даних Користувача згідно з порядком, який зазвичай
                                        використовується для захисту такого роду інформації в існуючому діловому
                                        обороті.</p><p>6.2.4. Здійснити блокування персональних даних, що відносяться до
                                        відповідного Користувачеві, з моменту звернення або запиту Користувача або його
                                        законного представника або уповноваженого органу з захисту прав суб'єктів
                                        персональних даних на період перевірки, в разі виявлення недостовірних
                                        персональних даних або неправомірних дій.</p><p>7. ВІДПОВІДАЛЬНІСТЬ СТОРІН</p>
                                    <p>7.1. Адміністрація сайту, яка не виконала свої зобов'язання, несе
                                        відповідальність за збитки, понесені Користувачем у зв'язку з неправомірним
                                        використанням персональних даних, відповідно до законодавства України, за
                                        винятком випадків, передбачених п.п. 5.2., 5.3. і 7.2. цієї Політики
                                        Конфіденційності.</p><p>7.2. У разі втрати або розголошення Конфіденційної
                                        інформації Адміністрація сайту не несе відповідальність, якщо дана конфіденційна
                                        інформація:</p><p>7.2.1. Стала публічним надбанням до її втрати або
                                        розголошення.</p><p>7.2.2. Була отримана від третьої сторони до моменту її
                                        отримання Адміністрацією сайту.</p><p>7.2.3. Була розголошена за згодою
                                        Користувача.</p><p>8. ВИРІШЕННЯ СПОРІВ</p><p>8.1. До цієї Політики
                                        конфіденційності та відносин між Користувачем і Адміністрацією сайту
                                        застосовується чинне законодавство України.</p><p>9. ДОДАТКОВІ УМОВИ</p><p>9.1.
                                        Адміністрація сайту має право вносити зміни в справжню Політику конфіденційності
                                        без згоди Користувача.</p><p>9.2. Нова Політика конфіденційності вступає в силу
                                        з моменту її розміщення на Сайті «Pizza7stars», якщо інше не передбачено новою
                                        редакцією Політики конфіденційності.</p><p>ПРОПОЗИЦІЯ (ОФЕРТА)</p><p>Про
                                        укладення Договору купівлі-продажу товарів</p><p>Піцерія «Pizza7stars», цією
                                        пропозицією (офертою), надалі по тексту – пропозиція, пропонує необмеженому колу
                                        осіб укласти електронний договір купівлі-продажу товарів, надалі по тексту –
                                        договір, асортимент та детальна інформація про які міститься на сайті
                                        https://pizza7stars.com.ua, на умовах і в порядку, зазначених нижче:</p><p>1. ТЕРМІНИ
                                        ТА ВИЗНАЧЕННЯ</p><p>1.1. «Продавець», «Pizza7stars» – піцерія ««Pizza7stars».</p>
                                        <p>1.2. «Покупець» – фізична або юридична особа, що вступила в


                                        договірні відносини з «Pizza7stars» на умовах, зазначених у цій
                                        пропозиції.</p><p>1.3. «Сайт» – сайт https://pizza7stars.com.ua,</p><p>1.4. «Товар» —
                                        перелік найменувань асортименту, представлений на офіційному сайті Продавця, але
                                        не обмежений тільки сайтом.</p><p>1.5. «Замовлення» — окремі позиції з
                                        асортиментного переліку Товару, вказані Покупцем при розміщенні заявки на
                                        інтернет-сайті Продавця або замовлені будь-яким іншим способом.</p><p>1.6.
                                        «Самовивіз» - це спосіб отримання Замовлення, при котрому Покупець самостійно
                                        отримує Замовлення в приміщенні піцерії.</p><p>2. ПРЕДМЕТ ДОГОВОРУ</p><p>2.1.
                                        «Pizza7stars» зобов’язується передати у власність покупцеві товари, перелік
                                        яких зазначено у підтвердженому продавцем замовленні покупця, а покупець
                                        зобов’язується їх оплатити і прийняти в порядку та на умовах, що передбачені цим
                                        договором.</p><p>3. ПОРЯДОК ОФОРМЛЕННЯ ЗАМОВЛЕННЯ</p><p>3.1. Замовлення Покупця
                                        може бути оформлено за Телефоном, за допомогою заповнення електронної форми
                                        замовлення на Сайті чи в Піцерії.</p><p>3.2. Моментом вступу Покупця в договірні
                                        відносини з Продавцем вважається момент замовлення Товару, незалежно від способу
                                        замовлення та форми оплати.</p><p>3.3. При виконанні замовлення на Сайті,
                                        Покупець зобов'язується надати наступну реєстраційну інформацію:</p><p>3.3.1.
                                        Ім'я Покупця або вказаної ним особи (одержувача);</p><p>3.3.2. Адреса, за якою
                                        слід доставити Товар (якщо доставка до адреси Покупця);</p><p>3.3.3. Контактний
                                        телефон.</p><p>3.4. Найменування, кількість, розмір, ціна обраного Покупцем
                                        Товару вказуються в «кошику» Покупця на Сайті.</p><p>3.5. Якщо Продавцю
                                        необхідна додаткова інформація, він має право запросити її у Покупця. У разі
                                        ненадання необхідної інформації Покупцем, Продавець не несе відповідальності за
                                        надання якісної послуги Покупцю при покупці товарів.</p><p>3.6. При оформленні
                                        Замовлення через Оператора (п. 3.1. Цієї Оферти) Покупець зобов'язується надати
                                        інформацію, зазначену в п. 3.3. цієї Оферти.</p><p>3.7. Ухвалення Покупцем умов
                                        цієї Оферти здійснюється за допомогою внесення Покупцем відповідних даних в
                                        реєстраційну форму на Сайті або при оформленні Замовлення через Оператора. Після
                                        оформлення Замовлення через Оператора дані про Покупця реєструються в базі даних
                                        Продавця.</p><p>3.8. Покупець несе відповідальність за достовірність наданої
                                        інформації при оформленні Замовлення.</p><p>3.9 Покупець може замовити тільки ті
                                        товари, які є в наявності у Продавця в момент оформлення замовлення Товарів у
                                        Продавця.</p><p>3.10. При оформленні замовлення за Телефоном Продавець має право
                                        здійснювати аудіозапис телефонної розмови з Покупцем, якщо зазначені дії
                                        необхідні Продавцю для виконання обов'язків, передбачених цією офертою, і оцінки
                                        якості наданих послуг, без отримання від Покупця згоди на вчинення зазначених
                                        дій.</p><p>4. ВАРТІСТЬ І ПОРЯДОК ОПЛАТИ ЗАМОВЛЕННЯ</p><p>4.1 Ціна Товару
                                        зазначена в гривнях без урахування вартості комісії і додаткових зборів банків
                                        та платіжних систем.</p><p>4.2 Ціна Товару може бути змінена Продавцем в
                                        односторонньому порядку на умовах, зазначених в Договорі.</p><p>4.3. У разі
                                        зміни ціни Товару до оплати Замовлення Продавець зобов’язаний у найкоротші
                                        строки повідомити Покупця про такі зміни. Покупець має право підтвердити або
                                        анулювати Замовлення.</p><p>4.4. Продавець вказує спосіб доставки Товару на
                                        Сайті або повідомляє Покупцеві при оформленні Замовлення Оператором.</p><p>4.5.
                                        При готівковій формі оплати Покупець зобов’язаний сплатити Продавцю ціну товару
                                        в момент його передачі, якщо інше не передбачено домовленістю або договором між
                                        сторонами.</p><p>4.6. У разі неоплати, неповної оплати або несвоєчасної
                                        Продавець залишає за собою право ненадання товару, призупинення або повне
                                        невиконання взятих на себе зобов’язань і при цьому не несе відповідальності за
                                        можливі наслідки такого рішення / дій.</p><p>4.7. При безготівковій формі оплати
                                        обов'язок Покупця зі сплати вартості товару вважається виконаним з моменту
                                        зарахування відповідних грошових коштів на розрахунковий рахунок Продавця.</p>
                                    <p>4.8. Оплата Товару здійснюється:</p><p>4.8.1. під час самовивозу Товару -
                                        готівковим або безготівковим розрахунком;</p><p>4.8.2. при замовленні Товару
                                        доставкою:</p><p>- в м. Звягель – готівковим або безготівковим
                                        розрахунком;</p><p>5. ВИКОНАННЯ ЗАМОВЛЕННЯ</p><p>5.1. Покупець має право
                                        отримати замовлений ним товар шляхом самостійного вивозу (самовивіз) із місця
                                        видачі замовлення або скористатися послугами кур’єрської доставки «Pizza7stars».</p><p>5.2. Самовивіз здійснюється покупцем із місця видачі замовлення,
                                        зазначеного покупцем при оформленні замовлення з можливих варіантів, що вказані
                                        на сайті.</p><p>5.3. Самовивіз товару:</p><p>Покупець може розрахуватися і
                                        отримати свій товар за адресами:</p><p>- м.
                                        Звягель, вул. Житомирська,79 (10.00-20.00)</p><p>5.4. Кур’єрська доставка замовлення покупцю
                                        здійснюється за погодженням із покупцем і за вказаною ним при оформленні
                                        замовлення адресою.</p><p>5.5. Доставка Товарів за адресами, що не входять в
                                        зону доставки, не здійснюється. Географія адрес доставки може змінюватися за
                                        рішенням керівництва «Pizza7stars». За більш детальною інформацією звертайтеся
                                        до оператора.</p><p>5.6. Право власності на товар, а також ризик його
                                        випадкового пошкодження чи втрати переходять до покупця з моменту передачі
                                        товару.</p><p>5.7. При прийомі Товару Покупець здійснює огляд на відповідність
                                        асортименту, комплектності та якості та наявності дефектів на упаковці. У разі
                                        невідповідності Покупець може відмовитися від Товару.</p><p>5.8. У разі
                                        порушення встановлених термінів доставки Товарів, в силу обставин непереборної
                                        сили («форс-мажор» п.8.1) Продавець не вважається таким, що прострочив
                                        доставку.</p><p>5.9. «Pizza7stars» має право відмовити покупцю в оформленні
                                        замовлення та підтвердженні замовлення до виконання у випадку, якщо до цього
                                        моменту покупець оформив хоча б одне замовлення у продавця, яке безпідставно
                                        відмовився отримати. Під безпідставною відмовою отримати замовлення
                                        розуміється:</p><p>– неотримання замовлення покупцем в місці видачі замовлень
                                        протягом 1 (однієї) години з часу, на коли було погоджено видачу замовлених
                                        товарів, та/або</p><p>– відсутність відповіді покупця на будь-яку спробу
                                        встановити контакт з покупцем (телефонний дзвінок кур’єра, дзвінок по домофону,
                                        стук в двері тощо) з метою повідомлення про доставку товарів та готовність
                                        передати замовлені товари, та/або</p><p>– безпідставно відмовився отримати
                                        товари;</p><p>– надання невірної інформації для доставки замовлених товарів.</p>
                                    <p>При цьому ідентифікація покупця здійснюється за номером телефону.</p><p>6.
                                        ПОРЯДОК ПОВЕРНЕННЯ ТОВАРУ</p><p>6.1. Відповідно до законодавства України
                                        повернення продовольчої Продукції належної якості не допускається, повернення
                                        грошових коштів за таку Продукцію не проводиться.</p><p>6.2. При отриманні
                                        Товарів Покупець перевіряє відповідність отриманих Товарів Замовленню,
                                        комплектність.</p><p>6.3. У разі отримання неякісних Товарів, або
                                        невідповідності отриманих Товарів замовленому (відсутність заявлених
                                        інгредієнтів в Товарі), Покупець має право вимагати заміни таких Товарів
                                        Товарами належної якості відразу після перевірки відповідності отриманих
                                        Товарів.</p><p>6.4. У разі невиконання порядку пред'явлення претензії Покупця за
                                        зовнішнім виглядом Товарів і їх комплектності, передбачених пунктом 6.2, пунктом
                                        6.3 цієї оферти, претензії не приймаються.</p><p>6.5. Повернення Продукції
                                        неналежної якості</p><p>У разі якщо Покупець виявить, що йому переданий Товар
                                        неналежної якості, він має право до закінчення терміну придатності повернути
                                        Товар неналежної якості Продавцю і вимагати повернення сплаченої грошової суми,
                                        або вимагати заміни Товару неналежної якості.</p><p>При цьому вартість Товару
                                        повертається Клієнту протягом 14 днів з моменту отримання Продавцем заяви
                                        Покупця.</p><p>6.6. Порядок дій при пересорті</p><p>У разі виявлення в
                                        замовленні Товару, що не відповідає замовленому, Покупець має право прийняти
                                        даний Товар або відмовитися від нього і вимагати заміни на Товар, передбачений у
                                        Замовленні, або повернення грошових коштів за фактично не одержаний Товар.</p>
                                    <p>6.7. Заміна Товару, що не відповідає Замовленню за асортиментом, здійснюється
                                        шляхом оформлення нового замовлення.</p><p>6.8. Порядок дій при доставці не в
                                        повному обсязі скомплектованого замовлення</p><p>При доставці меншої кількості
                                        Товару, ніж визначено в Замовленні, Покупець має право прийняти Товар в частині,
                                        що відповідає Замовленню, і вимагати передати відсутню кількість Товару або,
                                        якщо відсутній Товар був оплачений, відмовитися від Замовлення в частині
                                        недостатнього Товару і вимагати повернення грошових коштів за відсутній Товар.
                                        Даний факт доставки Товару не в повному обсязі оформляється Актом в довільній
                                        формі, який підписується Покупцем / Одержувачем і кур'єром.</p><p>Доставка
                                        відсутнього Товару здійснюється за допомогою оформлення нового Замовлення.</p>
                                    <p>7. ОСОБЛИВІ УМОВИ</p><p>7.1. «Pizza7stars» не несе відповідальності за
                                        неналежне використання товарів покупцем, замовлених та отриманих від «Pizza7stars». Під неналежним використанням товарів розуміється їх використання не за
                                        прямим призначенням та/або після закінчення терміну їх споживання.</p><p>7.2.
                                        «Pizza7starS» має право передати свої права та обов’язки з виконання замовлень
                                        третім особам.</p><p>7.3. Покупець зобов’язується до моменту ініціювання
                                        оформлення замовлення ознайомитися зі змістом цього договору-оферти, умовами
                                        оплати і доставки на сайті.</p><p>7.4. Сторони зобов’язуються сумлінно
                                        користуватись правами та чітко виконувати обов’язки, що виникають у зв’язку з
                                        укладенням цього договору.</p><p>8. ФОРС-МАЖОРНІ ОБСТАВИНИ</p><p>8.1. «Pizza7stars»
                                        звільняється від відповідальності за часткове або повне невиконання
                                        зобов’язань по цьому договору, якщо таке невиконання зобов’язань являється
                                        наслідком обставин непоборної сили. Під обставинами непереборної сили
                                        розуміються надзвичайні та невідворотні обставини, що об’єктивно унеможливлюють
                                        виконання зобов’язань, передбачених умовами договору, виникнення та існування
                                        яких є поза контролем продавця. До обставин непереборної сили належать у тому
                                        числі: загроза війни, збройний конфлікт або серйозна погроза такого конфлікту,
                                        акти тероризму, диверсії, пожежа, вибух, тривалі перерви в роботі транспорту,
                                        регламентовані умовами відповідних рішень та актами державних органів влади,
                                        ембарго, заборона (обмеження) експорту/імпорту тощо, рішення органів влади,
                                        зміни національного законодавства, збій в роботі технічних систем, що
                                        використовуються продавцем, злочинні дії третіх осіб по відношенню до «Pizza7stars», а також викликані винятковими погодними умовами і стихійним лихом,
                                        затори на дорогах, тощо.</p><p>9. ТЕРМІН ДІЇ ЦЬОГО ДОГОВОРУ</p><p>9.1. Цей
                                        договір вважається укладеним в момент завершення оформлення замовлення покупцем
                                        та підтвердження прийняття замовлення до виконання продавцем.</p><p>9.2. Договір
                                        діє до повного виконання сторонами всіх умов цього договору.</p><p>10. ЗАГАЛЬНІ
                                        ПОЛОЖЕННЯ</p><p>10.1. Цей договір є публічним, тобто згідно зі статтею 633
                                        цивільного кодексу україни його умови є однаковими для всіх покупців.</p><p>10.2
                                        . У випадку встановлення особливих умов стосовно асортименту, вартості товарів,
                                        інших умов замовлення у зв’язку з проведенням акцій, тощо – такі дії не будуть
                                        вважатися порушенням п. 2 ст. 633 цивільного кодексу україни.</p><p>10.3.
                                        Оформлюючи замовлення незалежно від способу оформлення (усно по телефону чи в
                                        електронні формі на сайті) покупець підтверджує наступне:</p><p>Покупець
                                        ознайомлений з умовами цієї пропозиції (оферти) укласти договір та акцептує їх у
                                        повній мірі та беззастережно;</p><p>Покупець ознайомлений та погоджується з
                                        асортиментом та вартістю товарів, умовами їх оплати та доставки, іншими умовами
                                        купівлі-продажу товарів, зазначеними на сайті</p><p>Усі надані покупцем при
                                        оформленні замовлення дані (перелік товарів, свої персональні дані, адресу
                                        доставки, номер телефону тощо) є актуальними та повними. Покупець надав усі
                                        необхідні деталі замовлення стосовно асортименту, кількості товарів, тощо, які
                                        мав намір надати, під час оформлення замовлення. Внесення змін до замовлення або
                                        відмова від замовлення після його підтвердження «Pizza7stars» не
                                        допускається.</p><p>11. ІНФОРМАЦІЯ ТА ПОРЯДОК ІЇ ВИКОРИСТАННЯ</p><p>11.1.
                                        Оформлюючи замовлення товарів сторони надають свою згоду одна одній на збір,
                                        обробку відомостей про них, які надаються з метою оформлення та виконання
                                        замовлення, будь-які інші їхні персональні дані та відомості, які надаються
                                        добровільно. При обробці персональних даних сторони здійснюють всі необхідні
                                        організаційні та технічні заходи для захисту персональних даних.</p><p>11.2.
                                        Доступ до персональних даних покупця мають лише особи, які безпосередньо
                                        залучені до оформлення та виконання такого замовлення.</p><p>11.3. «Pizza7stars»
                                        збирає та опрацьовує персональні дані покупців (а саме: прізвище, ім’я,
                                        по-батькові покупця; адреса доставки; контактні телефони, адреса електронної
                                        пошти, тощо) з метою виконання умов цього договору.</p><p>11.4. Сторони
                                        зобов’язуються:</p><p>- зберігати конфіденційність з приводу особистої
                                        інформації (персональних даних) іншої сторони, яка стала відомою їм під час
                                        оформлення та виконання замовлення;</p><p>- не надавати доступ до цієї
                                        інформації третім особам, за винятком випадків, передбачених чинним в Україні
                                        законодавством;</p><p>- не допускати спроби несанкціонованого використання
                                        персональних даних іншої сторони третіми особами;</p><p>- виключити доступ до
                                        персональних даних іншої сторони особам, що не мають безпосереднього відношення
                                        до оформлення та виконання замовлення, окрім випадків, передбачених чинним в
                                        Україні законодавством.</p>
                                </Box>

                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus onClick={handleClose} color="primary">
                                Ознайомлений
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>



            </Container>
        </footer>
    );
}