import React, {useEffect} from "react";
import {
    Box,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    makeStyles,
    Paper,
    Slide,
    Typography
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import Basket from "./Basket";
import Ordering from "./Ordering";
import Button from "@material-ui/core/Button";
import "firebase/auth"

import {useFirebaseApp, useUser} from 'reactfire'
import app from "firebase/app";
import {useDocumentData} from "react-firebase-hooks/firestore";

import axios from 'axios'
import chefImg from "../../../images/Chef_pizza.jpg";


import './Cards.css'

// import MD5 from 'crypto-js/md5'
//
// import {decode as base64_decode, encode as base64_encode} from 'base-64';
// import {useLocation} from "react-router";
// import * as querystring from "querystring";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,

    },
    paper: {
        padding: 0,
        textAlign: 'center',
        color: theme.palette.text.secondary,
        minWidth: '100%'
    },

    toBox: {
        borderRadius: '2rem',
        margin: '1rem'
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '60%',
        maxHeight: '60%',
    },

    pricePizza: {
        fontWeight: '900',
        fontFamily: 'Comfortaa',
        fontSize: '1.8rem',
        padding: '8px',
        // marginBottom: '10px',

    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function MakeOrderDialog({
                                            showDialog, handleShowDialog, pizzaBox, setPizzaBox,
                                            incrProductCount, delProduct, decrProductCount,
                                            addSaucesToBox, addDrinkToBox, location, city
                                        }) {
    const classes = useStyles();

    //===== User
    const user = useUser()
    const firebase = useFirebaseApp();
    const db = app.firestore()
    let uid

    if (user.data) {
        uid = user.data.uid
    }
    const [activeUser, setActiveUser] = React.useState({
        name: '',
        phone: '',
        city: '',
        address: '',
        error: '',
        photoURL: '',
    })

    const [currentUser, load, err] = useDocumentData(
        firebase.firestore().doc('users/' + uid),
        {
            snapshotListenOptions: {includeMetadataChanges: true},
        }
    );
    useEffect(() => {
        if (currentUser) {
            setActiveUser({
                name: currentUser.name || '',
                phone: currentUser.phoneNumber,
                city: currentUser.city || '',
                address: currentUser.address || '',
                error: '',
                photoURL: currentUser.photoURL || '',
            })
        }
    }, [currentUser])

    const inputChange = (input, value) => {
        setActiveUser({...activeUser, [input]: value})
    }

    //========== City ===========

    // const [city, setCity] = React.useState(city);
    // const chooseCity = (city) => setCity(city);
    let LocalStorageCity = localStorage.getItem('city')
    let orderCity
    if(city.name === '') {
        orderCity = LocalStorageCity
    } else {
        orderCity = city.name
    }

    //========== Pizzeria`s ===========
    const [pizzeria, setPizzaria] = React.useState(``);
    const choosePizzeria = (value) => {
        setPizzaria(value);
    };

    //========= Delivery ==============
    const [delivery, setDelivery] = React.useState('Доставка');
    const [deliveryCount, setDeliveryCount] = React.useState(75);
    const chooseDelivery = (value) => setDelivery(value)

    useEffect(() => {
        if (delivery === 'Самовивіз') {
            setDeliveryCount(0)
        }
        if (delivery !== 'Самовивіз') {
            setDeliveryCount(75)
        }
        if (pizzaBox.totalPrice >= 400) {
            setDeliveryCount(0)
        }
        setToPay(pizzaBox.totalPrice + deliveryCount)
    }, [pizzaBox, delivery, deliveryCount])


    //========= Time ==================
    const [time, setTime] = React.useState('найближчим часом');
    const [chooseTime, setChooseTime] = React.useState('00:00')
    let choosesTime = (value) => setChooseTime(value)

    const putTime = (time) => setTime(time)

    const hours = Math.floor(time / 60 / 60);
    const minutes = Math.floor(time / 60) - (hours * 60);
    let formattedTime = [
        hours.toString().padStart(2, '0'),
        minutes.toString().padStart(2, '0'),
    ].join(':');

    if (chooseTime === '00:00' || formattedTime === '00:00') {
        formattedTime = 'найближчим часом'
    }


    //============Pay Metod ==================
    const [payMetod, setPayMetod] = React.useState('готівкою');
    const choosePayMetod = (value) => setPayMetod(value)
    const [toPay, setToPay] = React.useState(0)
    const [payStatus, setPayStatus] = React.useState('не сплачено')


    const handleClose = () => {
        handleShowDialog()
    };


    const addUser = (uid, activeUser) =>
        db.collection('users').doc(uid).set(
            {
                name: activeUser.name,
                city: activeUser.city,
                address: activeUser.address,
            }, {merge: true},)


    //============= Telegramm
    // const token = '1656686512:AAHEc40eCkm9MDqbr69vfCi9Z3HStEAVjNU'
    const token = '6417631293:AAEKmuj_UVuJZ7YnwmFKUj_4kp-2oHi7Pwo'
    // const chatId = '-1001378827367'
    const chatId = '-1002001797143'
    let address
    if (delivery === 'Доставка') {
        address = `за адресою: ${activeUser.address}`
    } else {
        address = `з піцерії: ${pizzeria.name}`
    }


    let p = []

    pizzaBox.products.forEach(function (product) {
        let el = [product.category + " " + product.name + " *" + product.capacity + "* " + product.count + " шт  за " + product.price + ".00"];
        p.push(el);
        let added = [...product.options.addedProducts]
        added.forEach(function (ad) {
            let addProd = [" плюс: " + ad.name];
            p.push(addProd)
        })
        let dell = [...product.options.deletedProducts]
        dell.forEach(function (del) {
            let dellProd = [" мінус:  " + del.letIngr];
            p.push(dellProd)
        })

        let sauce = product.options.chooseSauce
        if (sauce) {
            let addSauce = [' Обрано соус - ' + sauce.sauceName + ' по ' + sauce.saucePrice + '.00'];
            p.push(addSauce)
        }
        p.push("%0A"
        )
    });

    const messageText = `Нове замовлення з ${orderCity} %0A
    Сумма: ${toPay}, ${payStatus} %0A
    клієнт: ${activeUser.name}, ${activeUser.phone} %0A
    ${delivery} ${address} %0A
    час: ${time} %0A
    ${p}
    `

    const sendMessage = () => {
        axios.post(`https://api.telegram.org/bot${token}/sendMessage?chat_id=${chatId}&text=${messageText}`)
            .then(res => {
            })
    }


    const orderFinish = () => {

        const products = (pizzaBox.products)
        const orderDate = new Date()
        addUser(uid, activeUser)

        db.collection('orders').doc().set(
            {
                products: products,
                userName: activeUser.name,
                userPhone: activeUser.phone,
                delivery: delivery,
                countDelivery: deliveryCount,
                city: city.name,
                addressDelivery: activeUser.address,
                orderTime: orderDate,
                leadTime: time || 'найближчим часом',
                pizzeria: pizzeria,
                orderCount: toPay,
                payStatus: payStatus,
            }, {merge: true},
        )


        setPizzaBox({
            products: [],
            totalCount: 0,
            totalPrice: 0,
        })

        // console.log(messageText)
        sendMessage()
        handleClickOpenThanks()
        handleClose()

    }

    const disabled = () => {
        if (
            activeUser.name === '' ||
            city === '' ||
            // formattedTime === '00:00' ||
            (delivery === 'Доставка' && activeUser.address === '') ||
            (delivery !== 'Доставка' && pizzeria === '') ||
            pizzaBox.totalPrice <= 0
        ) {
            return true
        } else {
            return false
        }
    }

    //======== LOCALSTORAGE ==========

    




    //=========== Pay Form =========

    // const [payForm, setPayForm] = React.useState(false)
    //
    // const openPayForm = () => {
    //
    //     // setPayForm(true)
    //     handleShowDialog()
    // }

    // const handleClosePayForm = () => {
    //     setPayForm(false)
    //     handleShowDialog()
    // }


    //=========== thanks dialog ====

    const [open, setOpen] = React.useState(false);

    const handleClickOpenThanks = () => {
        setOpen(true);
    };

    const handleCloseThanks = () => {
        setOpen(false);
    };




    //====================================
    //  ========= Pay Platon =============


    // const key = 'EL251DGU22'
    // const pass = 't7hHYR2j5zKKqDxx60syf1bzPa1Yc2He'
    // const payment = 'CC'
    // const d = {amount: toPay + '.00',
    //     description: 'Test',
    //     currency: 'UAH',
    //     recurring: 'Y',}
    //
    //     const data = base64_encode(JSON.stringify(d))
    //
    //
    //
    // const req_token = 'Y'
    // // const url = 'http://platono.pizza'
    // const url = 'http://localhost:3000/'
    //
    // function reverseStr(str) {
    //     return str.split("").reverse().join("");
    // }
    //
    //
    // const sign =
    //     MD5((reverseStr(key)+
    //     reverseStr(payment) +
    //     reverseStr(data)+
    //     reverseStr(url)+
    //     reverseStr(pass)).toUpperCase())
    //
    //
    // const PROXY_URL = 'https://cors-anywhere.herokuapp.com/';
    //
    // const payWithCard = () => {
    //     axios.post(PROXY_URL + 'https://secure.platononline.com/payment/auth',
    //         {payment: payment,
    //             key: key,
    //             data: data,
    //             req_token: req_token,
    //             url: url,
    //             sign: sign}
    // )
    // .then(function (response) {
    //         console.log(response)
    //     })
    //         .catch(function (error) {
    //             console.log(error)
    //         })
    // }
    //
    //
    // const loc = useLocation();
    // const query = querystring.parse(loc.search)
    // // console.log(query)//123
    //




    //===============================


    return (
        <>

        <Dialog open={showDialog} onClose={handleShowDialog} aria-labelledby="form-dialog-title"
                fullWidth maxWidth='lg' className={classes.root}>
            <DialogContent>
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"


                >
                    <Grid item sm={12} md={8} xs={12}>

                        <Paper elevation={0} className={classes.paper}>
                            <Basket pizzaBox={pizzaBox}
                                    onClose={handleClose}
                                    incrProductCount={incrProductCount}
                                    delProduct={delProduct}
                                    decrProductCount={decrProductCount}
                                    addSaucesToBox={addSaucesToBox}
                                    addDrinkToBox={addDrinkToBox}
                                    location={location}/>
                        </Paper>


                    </Grid>


                    <Grid item sm={12} md={4} xs={12} style={{borderLeft: '1px solid green'}}>
                        <Paper className={classes.paper} elevation={0}>
                            <Ordering
                                user={user}
                                activeUser={activeUser}
                                inputChange={inputChange}
                                city={city}
                                pizzeria={pizzeria}
                                // chooseCity={chooseCity}
                                choosePizzeria={choosePizzeria}
                                delivery={delivery}
                                chooseDelivery={chooseDelivery}
                                choosesTime={choosesTime}
                                chooseTime={chooseTime}
                                time={time}
                                putTime={putTime}
                                payMetod={payMetod}
                                choosePayMetod={choosePayMetod}
                                deliveryCount={deliveryCount}

                            />
                        </Paper>

                    </Grid>


                </Grid>
            </DialogContent>

            <DialogActions>

                <Box>
                    <form  action="https://secure.platononline.com/payment/auth"
                           method="post"
                           target="_blank"
                           style={{margin: 0, width: '100%'}}>
                    {/*<input type="hidden" name='payment' value={payment}/>*/}
                    {/*<input type="hidden" name='key' value={key}/>*/}
                    {/*<input type="hidden" name='url' value={url}/>*/}
                    {/*<input type="hidden" name='data' value={data}/>*/}
                    {/*<input type="hidden" name='req_token' value={req_token}/>*/}
                    {/*<input type="hidden" name='sign' value={sign}/>*/}

                <Button onClick={handleClose} color="primary">
                    назад в меню
                </Button>


                {payMetod !== 'на сайті' ?

                    <Button
                        onClick={orderFinish}
                        variant="outlined"
                        color="secondary"
                        disableElevation size='large'
                        className={classes.toBox}
                        disabled={disabled()}>
                        {/*{payMetod === 'на сайті' ? <span>Замовити та сплатити {toPay} грн</span> :*/}
                            <span>Замовити за {toPay} грн</span>
                        {/*}*/}
                    </Button>
                    :


                        <Button
                            onClick={orderFinish}
                            variant="outlined"
                            color="secondary"
                            disableElevation size='large'
                            className={classes.toBox}
                            type='submit'
                            disabled={disabled()}
                            >
                            <span>Замовити та сплатити {toPay} грн</span>
                        </Button>



                }
                    </form>
                </Box>

            </DialogActions>
        </Dialog>




        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseThanks}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"

        >
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    <Box>
                        <Typography variant='h5' color='secondary' align='center' className={classes.priceText}>
                            ДЯКУЄМО...
                        </Typography>
                        <img src={chefImg} alt='chef waiting' className={classes.img}/>
                        <Divider/>
                        <Typography variant='h6' color='secondary' align='center' className={classes.priceText}>
                            ДЯКУЄМО за Ваше замовлення. Чекайте на виконання...
                        </Typography>
                        <Typography variant='h6' color='primary' align='center' className={classes.priceText}>
                            Ми зателефонуємо для уточнення деталей.
                        </Typography>

                    </Box>
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleCloseThanks} color="primary">
                    Закрити
                </Button>


            </DialogActions>
        </Dialog>




            {/*<Dialog open={payForm} onClose={handleClosePayForm} aria-labelledby="form-dialog-title">*/}
            {/*    <DialogTitle id="form-dialog-title">*/}
            {/*        <Grid*/}
            {/*            container*/}
            {/*            direction="row"*/}
            {/*            justify="space-evenly"*/}
            {/*            alignItems="flex-end"*/}
            {/*        >*/}
            {/*            <Grid item  xs={12} sm={8} lg={8}>*/}
            {/*                <Typography variant='h3' color="secondary" align='center' className={classes.pricePizza}>*/}
            {/*                    Сплатити замовлення*/}
            {/*                </Typography>*/}
            {/*            </Grid>*/}
            {/*            <Grid item xs={6} sm={2} lg={2}>*/}
            {/*                <img width='80px' height='100%' src={Card} alt="Visa_MC_logo" style={{ margin: 'auto',*/}
            {/*                    display: 'block', padding: '8px'}}/>*/}
            {/*            </Grid>*/}
            {/*            <Grid item xs={6} sm={2} lg={2}>*/}
            {/*                <img width='80px' height='100%' src={Platon} alt="Visa_MC_logo" style={{ margin: 'auto',*/}
            {/*                    display: 'block', padding: '8px'}}/>*/}
            {/*            </Grid>*/}

            {/*        </Grid>*/}

            {/*    </DialogTitle>*/}
            {/*    <DialogContent>*/}
            {/*        <DialogContentText>*/}
            {/*            To subscribe to this website, please enter your email address here. We will send updates*/}
            {/*            occasionally.*/}
            {/*        </DialogContentText>*/}
            {/*        <Paper className='App'>*/}
            {/*            <PayCards />*/}
            {/*        </Paper>*/}

            {/*    </DialogContent>*/}
            {/*    <DialogActions>*/}
            {/*        <Button onClick={handleClosePayForm} color="primary">*/}
            {/*            Cancel*/}
            {/*        </Button>*/}
            {/*        <Button onClick={handleClosePayForm} color="primary">*/}
            {/*            Subscribe*/}
            {/*        </Button>*/}
            {/*    </DialogActions>*/}
            {/*</Dialog>*/}




            </>



    )
}

