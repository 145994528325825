import React from "react";

import {useCollection} from 'react-firebase-hooks/firestore'
import firebase from 'firebase/app';

import { makeStyles, createMuiTheme, ThemeProvider  } from '@material-ui/core/styles'
import Grid from "@material-ui/core/Grid";
import { Container} from "@material-ui/core";

import Typography from '@material-ui/core/Typography';

import Spin from "react-reveal/Spin";
import {GiFullPizza} from "react-icons/gi";
import {ukUA} from "@material-ui/core/locale";
import SaucesCard from "../Cards/saucesCard";
import DefaultCard from "../Cards/defaultCard";
import Snacks from "./Snacks";

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#4c8c4a',
            main: '#025d0a',
            dark: '#003300',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ff867f',
            main: '#f50404',
            dark: '#c50e29',
            contrastText: '#fff',
        },
    },

}, ukUA);


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        height: '200px'
    },
    title:{
        margin: '2rem 0' ,
        padding: '1rem 1rem 1rem',
        fontWeight: '900',
        fontFamily: 'Comfortaa',
        fontSize: '2rem',

    }
}));

const Sauses = ({id, addSaucesToBox, location}) => {

    const classes = useStyles();

    const [sause, loading, error] = useCollection(
        firebase.firestore().collection('sauses'),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );



    return (
        <Container className={classes.root} spacing={0} maxWidth='lg' id={id}>
            <ThemeProvider theme={theme}>

                <Grid container
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                      spacing={0}>

                    <Grid item xl={1} >
                        <Spin forever={true}
                              duration={6000}>
                            <GiFullPizza color="red"
                                         fontSize='3rem'/>
                        </Spin>
                    </Grid>
                    <Grid item xl={11}>
                        <Typography variant="h4" className={classes.title}
                                    color='secondary'>
                            Соуси
                        </Typography>
                    </Grid>

                </Grid>

                {error && <strong>Error: {JSON.stringify(error)}</strong>}
                {loading &&
                <Grid container spacing={3}
                      direction="row"
                      alignItems="flex-start">

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <DefaultCard className={classes.paper}/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <DefaultCard className={classes.paper}/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <DefaultCard className={classes.paper}/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <DefaultCard className={classes.paper}/>
                    </Grid>
                </Grid>
                }

                {sause && (
                    <Grid container spacing={1}
                          direction="row"

                          alignItems="flex-start"
                    >
                        {sause.docs.map((sauce) => (
                            <Grid key={sauce.id} item xs={12} sm={6} md={4} lg={3}>

                                <SaucesCard
                                    key={sauce.id}
                                    className={classes.paper}
                                    id={sauce.id}
                                    addSaucesToBox={addSaucesToBox}
                                    location={location}/>
                            </Grid>)
                        )}
                    </Grid>)
                }

            </ThemeProvider>
        </Container>
    );
};




export default Sauses