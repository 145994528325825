import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    ImageField,
    DateField,
    useEditController,
    EditContextProvider,
    SimpleForm, SelectInput, ImageInput
} from 'react-admin';
import {createMuiTheme, ThemeProvider} from "@material-ui/core/styles";
import {cloneElement} from "react";


const theme = createMuiTheme ({
    overrides: {
        RaImageField: {
            image: {
                maxHeight: '3rem',
                margin: 0,

            }

        }
    }
})

export const UserList = props => (
    <ThemeProvider theme={theme}>
    <List {...props} title="Користувачі">
        <Datagrid rowClick="edit">
            <ImageField source="photoURL" title="Avatar" label='Avatar'>
                <div>
                    <img width='5px' height='5px' src="photoURL" alt='User'/>
                </div>
            </ImageField>

            <TextField source="name" label="Ім'я"/>
            <TextField source="phoneNumber" label='Номер телефону'/>
            <DateField source='lastSignData' label='Остання дата входу' showTime={true}/>
            <TextField source="city" label='Місто'/>
            <TextField source="address" label='Адреса'/>
            <TextField source="pizzaBox" label='Корзина'/>

            <TextField source="role" label='Роль'/>
            <TextField source="id" />
        </Datagrid>
    </List>
    </ThemeProvider>
);

//============

const MyEdit = props => {
    const controllerProps = useEditController(props);
    const {
        basePath, // deduced from the location, useful for action buttons
        // defaultTitle, // the translated title based on the resource, e.g. 'Post #123'
        // loaded, // boolean that is false until the record is available
        // loading, // boolean that is true on mount, and false once the record was fetched
        record, // record fetched via dataProvider.getOne() based on the id from the location
        redirect, // the default redirection route. Defaults to 'list'
        resource, // the resource name, deduced from the location. e.g. 'posts'
        save, // the update callback, to be passed to the underlying form as submit handler
        saving, // boolean that becomes true when the dataProvider is called to update the record
        version, // integer used by the refresh feature
    } = controllerProps;

    return (
        <EditContextProvider value={controllerProps}>
            <div>

                {cloneElement (props.children, {
                    basePath,
                    record,
                    redirect,
                    resource,
                    save,
                    saving,
                    version,
                })

                }
            </div>
        </EditContextProvider>
    );

}


export const UserEdit = (props) => {

    return (<MyEdit title='Редагування'  {...props} undoable={false}>
            <SimpleForm>
                <ImageInput source="photoURL" label="Avatar" accept="image/*">
                    <ImageField source="src" title="Avatar" label='Avatar'>
                        <div>
                            <img width='5px' height='5px' src="photoURL" alt='avatar'/>
                        </div>
                    </ImageField>
                </ImageInput>



                <TextField source="name" label="Ім'я"/>
                <TextField source="phoneNumber" label='Номер телефону'/>
                <DateField source='lastSignData' label='Остання дата входу' showTime={true}/>
                <TextField source="city" label='Місто'/>
                <TextField source="address" label='Адреса'/>
                <TextField source="pizzaBox" label='Корзина'/>

                <SelectInput source="role" allowEmpty emptyValue={null} choices={[
                    { id: 'Admin', name: 'Адміністратор' },
                    { id: 'Сashier', name: 'Касир' },
                    { id: 'Сlient', name: 'Клієнт' },
                ]} />



            </SimpleForm>
        </MyEdit>
    );
}