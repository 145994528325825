import React from "react";
import {useCollectionData} from "react-firebase-hooks/firestore";
import firebase from 'firebase/app';


const PromotionLabel = (props) => {

    const [label, loading, error] = useCollectionData(
        firebase.firestore().collection('tags'),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );


    return (
    <div>
        {!loading && props.promotion === 'none' && <div> </div>}
        {!loading && props.promotion === 'new' && <img height='70px' src={label[0].pictures.src} alt='new'/>}
        {!loading && props.promotion === 'top' && <img height='70px' src={label[2].pictures.src} alt='top'/>}
        {!loading && props.promotion === 'week' && <img height='70px' src={label[1].pictures.src} alt='week'/>}
        {!loading && props.promotion === 'gift' && <img height='70px' src={label[3].pictures.src} alt='gift'/>}

    </div>
)
}

export default PromotionLabel