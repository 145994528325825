import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App/App'

import firebaseConfig from "./components/Firebase/firebaseConfig";
import { FirebaseAppProvider } from 'reactfire';


import ErrorBoundry from "./components/error-boundry/error-boundry";
import {BrowserRouter as Router } from "react-router-dom";
import {createMuiTheme, ThemeProvider} from "@material-ui/core/styles";


const theme = createMuiTheme({

    palette: {
        primary: {
            light: '#4c8c4a',
            main: '#1b5e20',
            dark: '#003300',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ff867f',
            main: '#ff5252',
            dark: '#c50e29',
            contrastText: '#000',
        },
    },

    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
});




ReactDOM.render(




        <ErrorBoundry>
            <FirebaseAppProvider firebaseConfig={firebaseConfig}>
                <ThemeProvider theme={theme}>
            <Router>
                <App />
            </Router>
                </ThemeProvider>
            </FirebaseAppProvider>
        </ErrorBoundry>
    , document.getElementById('root')
);

