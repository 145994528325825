import * as React from "react";
import {
    Create, useEditController, EditContextProvider,
    NumberField, ImageInput, SimpleForm, List, TextInput, NumberInput,
    Datagrid, TextField, ImageField
} from 'react-admin';

import {makeStyles} from '@material-ui/core/styles';
import {cloneElement} from "react";


const useStyles = makeStyles({
    inlineBlock: {display: 'inline-flex', marginRight: '1rem'},
});

export const DrinksList = props => (
    <List {...props} title="НАПОЇ">
        <Datagrid rowClick="edit">
            <ImageField source="pictures.src" title="pictures.title" label='Малюнок'>
                <div>
                    <img width='100px' height='100px' src="pictures.src" alt='pictures.title'/>
                </div>
            </ImageField>
            <TextField source="name" label='Назва'/>
            <TextField source="info" label='Опис'/>
            <NumberField source="weight" label='Об`єм, ml'/>
            <NumberField source="price" label='Ціна, грн'/>
            <NumberField source="price_" label='Ціна +, грн'/>
        </Datagrid>
    </List>
);


export const DrinksCreate = (props) => {
    const classes = useStyles();
    return (
        <Create {...props} title='Додати напій'>
            <SimpleForm>
                <ImageInput source="pictures" label="Зображення" accept="image/*">

                    <ImageField source="src" title="title"/>
                </ImageInput>
                <TextInput source='name' label='Назва напою'
                           formClassName={classes.inlineBlock}/>
                <NumberInput source="weight" label='Об`єм'
                             formClassName={classes.inlineBlock}/>
                <NumberInput source="price" label='Ціна'
                             formClassName={classes.inlineBlock}/>
                <NumberInput source="price_" label='Ціна +'
                             formClassName={classes.inlineBlock}/>
                <TextInput source='info' label='Опис' fullWidth={true} multiline/>
            </SimpleForm>
        </Create>)
}

//============

const MyEdit = props => {
    const controllerProps = useEditController(props);
    const {
        basePath, record, redirect, resource, save,
        saving, version
    } = controllerProps;

    return (
        <EditContextProvider value={controllerProps}>
            <div>

                {cloneElement(props.children, {
                    basePath, record, redirect, resource,
                    save, saving, version,
                })
                }
            </div>
        </EditContextProvider>
    );

}


export const DrinksEdit = (props) => {
    const classes = useStyles();
    return (<MyEdit title='Редагування'  {...props} undoable={false}>
            <SimpleForm>
                <TextInput disabled source="id"/>

                <ImageInput source="pictures" label="Зображення" accept="image/*">

                    <ImageField source="src" title="title"/>
                </ImageInput>
                <TextInput source='name' label='Назва напою'
                           formClassName={classes.inlineBlock}/>
                <NumberInput source="weight" label='Об`єм'
                             formClassName={classes.inlineBlock}/>
                <NumberInput source="price" label='Ціна'
                             formClassName={classes.inlineBlock}/>
                <NumberInput source="price_" label='Ціна +'
                             formClassName={classes.inlineBlock}/>
                <TextInput source='info' label='Опис' fullWidth={true} multiline/>

            </SimpleForm>
        </MyEdit>
    );
}