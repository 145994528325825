import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import Button from '@material-ui/core/Button';
import pizzaDefault from '../../../images/pizza.png'
import {Alert, Skeleton} from "@material-ui/lab";
import {Box, Grid, Snackbar, Typography} from "@material-ui/core";

import {useDocumentData} from "react-firebase-hooks/firestore";
import firebase from 'firebase/app';
import DefaultCard from "./defaultCard";
import ChooseBurger from "../Dialogs/ChooseBurger";


const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '25rem',
        minHeight: '24rem',
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        flexGrow: 1,
        position: 'relative'

    },

    cardBody: {
        minHeight: '20rem'

    },


    media: {
        height: '60%',
        width: '95%',
        // paddingTop: '5%', // 16:9
        margin: '1% auto',
        '&:hover': {
            transform: 'scale(1.06)'
        }},

    toBox: {
        borderRadius: '2rem',
        margin: '0.5rem'
    },
    pricePizza: {
        fontWeight: '700',
        fontFamily: 'Comfortaa',
        fontSize: '2rem',
        padding: '0.5rem'
    },

    marginCard: {
        margin: '1% auto'
    },
    ingr: {
        marginLeft: '16px'
    },

}));

export default function BurgerCard(props) {
    const classes = useStyles();


    // const [burger, loading, error] = useDocumentData(
    //     firebase.firestore().doc('burgers/' + props.id),
    //     {
    //         snapshotListenOptions: { includeMetadataChanges: true },
    //     }
    // );

    let price = 0

    let place = localStorage.getItem('place')
    let city = localStorage.getItem('city')

        price = props.burger.price


    const [open, setOpen] = React.useState(false);

    const [showDialog, setShowDialog]= React.useState(false)

    const handleShowDialog = () => {
        setShowDialog(!showDialog)
    }

    const addedProduct = {
        category: 'Бургер',
        options: {
            deletedProducts: [],
            addedProducts: [],
        },
        capacity: '',
        name: props.burger.name,
        imageURL: props.burger.pictures.src,
        price: price,
        count: 1
    }


    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };


    return (
        <div>

            {props.burger.name !== 'Хот-дог французький' && (

                <Card className={classes.root} elevation={0} >
                    {/*{error && <strong>Error: {JSON.stringify(error)}</strong>}*/}


                    {/*{snack.id !== 'pU8aUPuneabJkUzk0iuv' ? <div>2222</div> : <div>11111</div>}*/}

                    {/*<Box*/}
                    {/*    position="absolute"*/}
                    {/*    top={0}*/}
                    {/*    right={0}*/}
                    {/*    zIndex="tooltip">*/}
                    {/*    <PromotionLabel promotion={!loading && snack.promotion} />*/}
                    {/*</Box>*/}


                    <Box displaay='flex'
                         flexDirection="column">

                        <CardActionArea className={classes.cardBody} onClick={handleShowDialog}>
                            <CardMedia
                                className={classes.media}
                                component="img"
                                alt="burger"
                                image={props.burger.pictures ? props.burger.pictures.src : pizzaDefault}/>
                            <Typography variant='h6' color="secondary" align='center' className={classes.pricePizza}>
                                {props.burger ? props.burger.name : <Skeleton height='1rem' />}
                            </Typography>
                            <Typography variant='body1' color="textSecondary" component="p"  className={classes.ingr}>
                                {props.burger ? props.burger.info : <Skeleton height='1rem' />}
                            </Typography>
                        </CardActionArea>


                        <CardActions className={classes.cardAction}>

                            <Grid container spacing={1}
                                  direction="column"
                                  justify="space-between"
                                  alignItems="center"
                                  className={classes.marginCard}
                                  style={{ padding: 0}}>

                                <Grid item>
                                    <Typography variant='h3' color='textPrimary' className={classes.pricePizza}>
                                        {props.burger.price + '.00'}
                                    </Typography>
                                </Grid>


                                <Grid item style={{ padding: 0}}>

                                        {/*<Button variant="outlined" color="secondary" disableElevation size='large'*/}
                                        {/*        className={classes.toBox} onClick={handleShowDialog}>*/}
                                        {/*    ВИБРАТИ*/}
                                        {/*</Button> :*/}
                                        <Button variant="outlined" color="secondary" disableElevation size='large'
                                                className={classes.toBox}
                                                onClick={() => {
                                                    props.addPizzaToBox(addedProduct);

                                                    handleClick();

                                                }}
                                        >
                                            ЗАМОВИТИ
                                        </Button>

                                    <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                                        <Alert onClose={handleClose} severity="success">
                                            До кошика додано Burger
                                        </Alert>
                                    </Snackbar>


                                        <ChooseBurger showDialog={showDialog} handleShowDialog={handleShowDialog}
                                                     burger={props.burger} addBurgerToBox={props.addPizzaToBox}
                                                     location={props.location}
                                        />


                                </Grid>


                            </Grid>


                        </CardActions>



                    </Box>
                </Card>)}


            {props.burger.name === 'Хот-дог французький' && city !== 'Звягель' && (

                <Card className={classes.root} elevation={0} >
                    {/*{error && <strong>Error: {JSON.stringify(error)}</strong>}*/}
                    {/*{loading && (<DefaultCard />)}*/}

                    {/*{snack.id !== 'pU8aUPuneabJkUzk0iuv' ? <div>2222</div> : <div>11111</div>}*/}

                    {/*<Box*/}
                    {/*    position="absolute"*/}
                    {/*    top={0}*/}
                    {/*    right={0}*/}
                    {/*    zIndex="tooltip">*/}
                    {/*    <PromotionLabel promotion={!loading && snack.promotion} />*/}
                    {/*</Box>*/}


                    <Box displaay='flex'
                         flexDirection="column">

                        <CardActionArea className={classes.cardBody} onClick={handleShowDialog}>
                            <CardMedia
                                className={classes.media}
                                component="img"
                                alt="Snack"
                                image={props.burger.pictures ? props.burger.pictures.src : pizzaDefault}/>
                            <Typography variant='h6' color="secondary" align='center' className={classes.pricePizza}>
                                {props.burger ? props.burger.name : <Skeleton height='1rem' />}
                            </Typography>
                            <Typography variant='body1' color="textSecondary" component="p"  className={classes.ingr}>
                                {props.burger ? props.burger.info : <Skeleton height='1rem' />}
                            </Typography>
                        </CardActionArea>


                        <CardActions className={classes.cardAction}>

                            <Grid container spacing={1}
                                  direction="column"
                                  justify="space-between"
                                  alignItems="center"
                                  className={classes.marginCard}
                                  style={{ padding: 0}}>

                                <Grid item>
                                    <Typography variant='h3' color='textPrimary' className={classes.pricePizza}>
                                        {price + '.00'}
                                    </Typography>
                                </Grid>


                                <Grid item style={{ padding: 0}}>


                                        <Button variant="outlined" color="secondary" disableElevation size='large'
                                                className={classes.toBox}
                                                onClick={() => {
                                                    props.addPizzaToBox(addedProduct);

                                                    handleClick();

                                                }}>
                                            ЗАМОВИТИ
                                        </Button>


                                    <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                                        <Alert onClose={handleClose} severity="success">
                                            До кошика додано Burger
                                        </Alert>
                                    </Snackbar>

                                    <ChooseBurger showDialog={showDialog} handleShowDialog={handleShowDialog}
                                                  burger={props.burger} addBurgerToBox={props.addPizzaToBox}
                                                  location={props.location}
                                    />

                                </Grid>


                            </Grid>


                        </CardActions>



                    </Box>
                </Card>)}






        </div>

    );

}