import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import {useCollectionData} from "react-firebase-hooks/firestore";
import firebase from 'firebase/app';
import {Checkbox, Grid, Hidden, ListItem, Paper, Typography} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
        width: '100%'
    },
    gridList: {
        width: '100%',
        height: '250px',

    },

    gridList_: {
        width: '100%',
        height: '250px',
        flexWrap: 'nowrap',

    },

    img: {
        maxWidth: '100%',
        display: 'block',
    },

    img_: {
        maxWidth: '50%',
        display: 'block',
        margin: '5px'
    },

    label: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        padding: '1px',
        backgroundColor: 'red',
    },

    checked: {
        border: '1px solid red'
    },
    listItem: {
        minHeight: '160px',
        position: 'relative',
        flexDirection: 'column'
    },
    listItemSmall: {
        position: 'relative',
        flexDirection: 'column'
    }
}));

export default function AdditivesList(props) {
    const classes = useStyles();


    const [additive, loading, error] = useCollectionData(
        firebase.firestore().collection('additives'),
        {snapshotListenOptions: {includeMetadataChanges: true},}
    );

    const [cheesebord, load, err] = useCollectionData(
        firebase.firestore().collection('cheesebord'),
        {snapshotListenOptions: {includeMetadataChanges: true},}
    );

    const [checked, setChecked] = React.useState([]);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);

    };

    props.handleAdded(checked);

    return (

        <div className={classes.root}>

                {/*<Hidden xsDown>*/}
                    {err && <strong>Error: {JSON.stringify(error)}</strong>}
                    {load && (<p>loading</p>)}
                    {cheesebord && (
                        <Grid container
                              direction="row"
                              justify="center"
                              alignItems="center"
                              md={12}
                              sm={12}
                              xs={12}
                        >

                            {cheesebord.map((value, index) => (

                                    <Grid item key={index} md={12} sm={12} xs={12}>

                                        <ListItem key={value} dense button onClick={handleToggle(value)}
                                                  disableGutters alignItems="center"
                                                  className={classes.listItem}>

                                            <Paper style={{minHeight: '170px'}}
                                                   elevation={0}
                                                   className={checked.indexOf(value) !== -1 ? classes.checked : null}>
                                                <Checkbox
                                                    edge="start"
                                                    checked={checked.indexOf(value) !== -1}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    style={{padding: 0, margin: 5}}
                                                />
                                                <Typography variant="subtitle2" display="inline" gutterBottom
                                                            align='center' color='secondary'>
                                                    {value.price} грн
                                                </Typography>

                                                <Typography variant="subtitle1" display="inline" gutterBottom={false}
                                                            align='left' color='secondary'
                                                            style={{margin: 5}}>
                                                    {value.name}
                                                </Typography>


                                                <img src={value.pictures.src} alt='added' className={classes.img_}/>



                                            </Paper>


                                        </ListItem>

                                    </Grid>
                                )
                            )}

                        </Grid>
                    )}

            <Typography display='inline' variant='h6' color='secondary'>Додатки до піци
            </Typography>

            <Hidden xsDown>
            <GridList className={classes.gridList}>
                {error && <strong>Error: {JSON.stringify(error)}</strong>}
                {loading && (<p>loading</p>)}
                {additive && (
                    <Grid container
                          direction="row"
                          justify="center"
                          alignItems="center"
                          md
                          sm={12}
                          xs={12}
                    >


                        {additive.map((value, index) => (

                            <Grid item key={index} md={3} sm={6} xs={12}>

                                <ListItem key={value} dense button onClick={handleToggle(value)}
                                          disableGutters alignItems="center"
                                          className={classes.listItem}>

                                    <Paper style={{margin: '3px', minHeight: '170px'}}
                                    className={checked.indexOf(value) !== -1 ? classes.checked : null}>
                                        <Checkbox
                                            edge="start"
                                            checked={checked.indexOf(value) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            style={{padding: 0, margin: 0}}
                                        />
                                        <img src={value.pictures.src} alt='added' className={classes.img}/>
                                        <Typography variant="caption" display="block" gutterBottom={false}
                                                    align='center' color='primary'>
                                            {value.name}
                                        </Typography>
                                        <Typography variant="subtitle2" display="block" gutterBottom
                                                    align='center' color='secondary'>
                                            {value.price} грн
                                        </Typography>
                                    </Paper>


                                </ListItem>

                            </Grid>
                            )
                        )}

                    </Grid>
                )}
            </GridList>

            </Hidden>
            <Hidden smUp>
                <GridList cellHeight='auto' cols={1} style={{height: '200px', width: '100%'}}>
                    {error && <strong>Error: {JSON.stringify(error)}</strong>}
                    {loading && (<p>loading</p>)}
                    {additive && (
                <Grid
                    container
                    justify="space-evenly"
                    direction="row"
                    alignItems="stretch"
                    wrap
                    xs
                >
                    {additive.map((value, index) => (
                    <Grid key={index} item xs={12}
                          // style={{ height: '10px'}}
                    >
                        {/*<ListItem key={value} dense button onClick={handleToggle(value)}*/}
                        {/*          disableGutters alignItems="center">*/}

                            <Paper style={{margin: '3px', height: '60px', width: '95%'}}
                                   onClick={handleToggle(value)}
                                   className={checked.indexOf(value) !== -1 ? classes.checked : null}>

                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="center"
                                >

                        <Grid item xs={2}>
                                    <Checkbox
                                name={value.name}
                                edge="start"
                                checked={checked.indexOf(value) !== -1}
                                tabIndex={-1}
                                disableRipple
                                style={{padding: '5px', margin: '5px 5px 5px 5px'}}
                                    />
                        </Grid>
                                    <Grid item xs={2}>


                                <Typography variant="h6" display="inline" gutterBottom
                                            align='left' color='secondary'>
                                    {value.price} ₴
                                </Typography>
                                    </Grid>


                                    <Grid item xs={8}>
                                <Typography variant="h6" display="inline" gutterBottom={false}
                                            align='left' color='primary'>
                                    {value.name}
                                </Typography>
                                    </Grid>


                                </Grid>


                            </Paper>
                        {/*</ListItem>*/}
                    </Grid>
                    ))}
                </Grid>
                    )}
                </GridList>
            </Hidden>
        </div>
    );
}