import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    useEditController,
    EditContextProvider,
    SimpleForm, TextInput, Create, required, NumberInput} from 'react-admin';
import {createMuiTheme, makeStyles, ThemeProvider} from "@material-ui/core/styles";
import {cloneElement} from "react";

const theme = createMuiTheme ({
    overrides: {
        RaImageField: {
            image: {
                maxHeight: '3rem',
                margin: 0,

            }

        }
    }
})

const useStyles = makeStyles({
    inlineBlock: {
        display: 'inline-flex',
        marginRight: '1rem',
    },
});

export const PizzariaList = props => (
    <ThemeProvider theme={theme}>
        <List {...props} title="Піцерії">
            <Datagrid rowClick="edit">
                <TextField source="name" label="Назва"/>
                <TextField source="phoneNumber" label='Номер телефону'/>
                <TextField source="city" label='Місто'/>
                <TextField source="address" label='Адреса'/>
                <TextField source="id" />
            </Datagrid>
        </List>
    </ThemeProvider>
);

//============

const MyEdit = props => {
    const controllerProps = useEditController(props);
    const {
        basePath, // deduced from the location, useful for action buttons
        // defaultTitle, // the translated title based on the resource, e.g. 'Post #123'
        // loaded, // boolean that is false until the record is available
        // loading, // boolean that is true on mount, and false once the record was fetched
        record, // record fetched via dataProvider.getOne() based on the id from the location
        redirect, // the default redirection route. Defaults to 'list'
        resource, // the resource name, deduced from the location. e.g. 'posts'
        save, // the update callback, to be passed to the underlying form as submit handler
        saving, // boolean that becomes true when the dataProvider is called to update the record
        version, // integer used by the refresh feature
    } = controllerProps;

    return (
        <EditContextProvider value={controllerProps}>
            <div>

                {cloneElement (props.children, {
                    basePath,
                    record,
                    redirect,
                    resource,
                    save,
                    saving,
                    version,
                })

                }
            </div>
        </EditContextProvider>
    );

}


export const PizzeriaEdit = (props) => {

    return (<MyEdit title='Редагування'  {...props} undoable={false}>
            <SimpleForm>

                <TextInput source="name" label="Назва"/>
                <TextInput source="phoneNumber" label='Номер телефону'/>
                <TextInput source="city" label='Місто'/>
                <TextInput source="address" label='Адреса'/>
                <NumberInput source="id" />

            </SimpleForm>
        </MyEdit>
    );
}

export const PizzeriaCreate = (props) => {
    const classes = useStyles();

    return (
        <Create {...props} title='Додати піцерію'>
            <SimpleForm>
                    {/*<TextInput source='id' label='id' fullWidth={true}/>*/}
                    <TextInput source='name' label='Назва' validate={[required()]}/>
                    <TextInput source='phoneNumber' label='Номер телефону'
                               // formClassName={classes.inlineBlock}
                    />
                    <TextInput source='city' label='Місто'
                               // formClassName={classes.inlineBlock}
                    />
                    <TextInput source='address' label='Адреса' fullWidth={true}/>


            </SimpleForm>
        </Create>)
}