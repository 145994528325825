import * as React from "react";
import { Create, useEditController, EditContextProvider,
    ImageInput, SimpleForm, List, TextInput,
    Datagrid, TextField, ImageField,
    EditButton } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import {cloneElement} from "react";


const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
});


export const TagsList = props => (
    <List {...props} title="Наліпки промоції">
        <Datagrid rowClick="edit">
            <EditButton label="змінити" />
            <TextField source="name" label='Назва наліпки'/>
            <ImageField source="pictures.src" title="pictures.title" label='Наліпка'>
                <div>
                    <img width='400px' src="pictures.src" alt='title' />
                </div>
            </ImageField>
            <TextField source="info" label='Опис'/>
        </Datagrid>
    </List>
);

export const TagCreate = (props) => {
    const classes = useStyles();
    return (
        <Create {...props} title="Створити наліпку">
            <SimpleForm>
                <TextInput source='name' label='Назва наліпки'
                           formClassName={classes.inlineBlock}/>
                <ImageInput source="pictures" label="Зображення наліпки" accept="image/*">

                    <ImageField source="src" title="title"/>
                </ImageInput>


                <TextInput source='info' options={{ multiLine: true }}
                           label='Опис наліпки' fullWidth/>
            </SimpleForm>
        </Create>)}


//================================



//============

const MyEdit = props => {
    const controllerProps = useEditController(props);
    const {
        basePath, // deduced from the location, useful for action buttons
        // defaultTitle, // the translated title based on the resource, e.g. 'Post #123'
        // loaded, // boolean that is false until the record is available
        // loading, // boolean that is true on mount, and false once the record was fetched
        record, // record fetched via dataProvider.getOne() based on the id from the location
        redirect, // the default redirection route. Defaults to 'list'
        resource, // the resource name, deduced from the location. e.g. 'posts'
        save, // the update callback, to be passed to the underlying form as submit handler
        saving, // boolean that becomes true when the dataProvider is called to update the record
        version, // integer used by the refresh feature
    } = controllerProps;

    return (
        <EditContextProvider value={controllerProps}>
            <div>

                {cloneElement (props.children, {
                    basePath,
                    record,
                    redirect,
                    resource,
                    save,
                    saving,
                    version,
                })

                }
            </div>
        </EditContextProvider>
    );

}


export const TagEdit = (props) => {
    const classes = useStyles();
    return (<MyEdit title='Редагування'  {...props} undoable={false}>
            <SimpleForm>
                <TextInput disabled source="id" />
                <TextInput source='name' label='Назва  Наліпки'
                           formClassName={classes.inlineBlock}/>
                <ImageInput source="pictures" label="Зображення" accept="image/*">

                    <ImageField source="src" title="title"
                                formClassName={classes.imageInput}/>
                </ImageInput>


                <TextInput source='info'
                           label='Опис застосування' fullWidth/>
            </SimpleForm>
        </MyEdit>
    );
}


