import React, {useEffect, useState} from "react";
import {
    Avatar,
    Badge,
    Box,
    Button,
    ButtonGroup,
    Container,
    Grid,
    IconButton,
    Paper,
    Tooltip,
    Zoom
} from "@material-ui/core";
import LocalPizzaRoundedIcon from '@material-ui/icons/LocalPizzaRounded';
import LocalMallRoundedIcon from '@material-ui/icons/LocalMallRounded';

import { Link, animateScroll as scroll } from "react-scroll";
import { makeStyles } from '@material-ui/core/styles';
import small_logo from '../../images/LOGO_small.png'
import Fade from 'react-reveal/Fade';
import MakeOrderDialog from "../pages/MakeAnOrder/MakeAnOrderDislog";
import {MiniBasket} from "../pages/MakeAnOrder/Basket";

import TouchAppIcon from '@material-ui/icons/TouchApp';





const useStyles = makeStyles((theme) => ({

    box: {
        zIndex: '2000',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        zIndex: '2000',
    },

    menuItem: {
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('xs')]: {
            maxWidth: '380px'
        },

    },

    logo: {
        width: '60px',
        padding: 0,
    },
    large: {
        width: theme.spacing(8),
        height: theme.spacing(8),
    },

    margin: {
        margin: theme.spacing(1),
    },
    active: {
        color: 'red',
    },
    navCard: {
        maxWidth: '1000px',
        minHeight: '50px',
        // overflowX: 'hidden'
    },

    navCardItem: {
        minWidth: '70px',
        margin: theme.spacing(1),
        fontSize: '1rem',

    },
    button_auth: {
        margin: '10px',
        borderRadius: "33px",
        border: '2px solid red',
        color: 'rgb(61 61 61)',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },

    basketIcon: {
        marginRight: '20px',
        [theme.breakpoints.up('md')]: {
            display: 'none',
            marginRight: '0px',
        },
        popover: {
            pointerEvents: 'none',
        },

    },
    customWidth: {
        maxWidth: 500,
        backgroundColor: '#fff',
        border: '1px solid red'


    },
    noMaxWidth: {
        maxWidth: 'none',
    },
}));

const scrollToTop = () => {
    scroll.scrollToTop();
};

const NavBar = ({pizzaBox, setPizzaBox, incrProductCount, delProduct, decrProductCount, addSaucesToBox, addDrinkToBox, seeSushi, setSeeSushi, location, city}) => {

    const classes = useStyles();

    const [offset, setOffset] = useState(0);

    useEffect(() => {
        window.onscroll = () => {
            setOffset(window.pageYOffset)
        }
    }, []);


    //========= Show Dialog

    const [showDialog, setShowDialog]= React.useState(false)

    const handleShowDialog = () => {
        setShowDialog(!showDialog)
    }


    // const handleClose = (event, reason) => {
    //     if (reason === 'clickaway') {
    //         return;
    //     }
    //
    // };






    return (
        <Box className={classes.box}
             zIndex="tooltip">
        <Paper elevation={0} >
        <Container style={{marginBottom: '2rem'}}>
            <Paper elevation={0}>
        <Grid container
              direction="row"
              justify="flex-start"
              alignItems="center"
              wrap='nowrap'
        spacing={2}>

            <Grid container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                  wrap='nowrap'
                  spacing={2}>

            {offset > 80 &&
            <Fade  left>
            <Grid item style={{margin: '0.5rem 1.5rem'}} onClick={scrollToTop}>
                <Avatar alt="logo" src={small_logo} className={classes.large}/>
            </Grid>
            </Fade>
            }


                <Grid item zeroMinWidth className={classes.menuItem}>



                    {/*{seeSushi ?*/}
                    {/*    <Box className={classes.navCard}*/}
                    {/*         component="div" my={2} overflow="auto" bgcolor="background.paper">*/}

                    {/*        <Link activeClass={classes.active}*/}
                    {/*              className={classes.navCardItem}*/}
                    {/*              to="sets"*/}
                    {/*              spy={true}*/}
                    {/*              smooth={true}*/}
                    {/*              offset={-60}*/}
                    {/*              duration= {1000}*/}
                    {/*        >*/}
                    {/*            <Button variant='outlined' size='large' color='secondary' style={{padding: '2px 15px', fontSize: '1.1rem', fontWeight: '800', borderRadius: '2rem', }}*/}
                    {/*                    onClick={() => setSeeSushi(false)}*/}
                    {/*                    endIcon={<TouchAppIcon/>}*/}
                    {/*            >Піца тут</Button>*/}
                    {/*        </Link>*/}

                    {/*        <Link activeClass={classes.active}*/}
                    {/*              className={classes.navCardItem}*/}
                    {/*              to="sets"*/}
                    {/*              spy={true}*/}
                    {/*              smooth={true}*/}
                    {/*              offset={-60}*/}
                    {/*              duration= {1000}*/}
                    {/*        >*/}
                    {/*            <Button variant='text' size='large' color='inherit' style={{padding: '3px', fontSize: '1rem'}}*/}
                    {/*            >СУШИ: Сети</Button>*/}
                    {/*        </Link>*/}

                    {/*        <Link*/}
                    {/*            activeClass={classes.active}*/}
                    {/*            className={classes.navCardItem}*/}
                    {/*            to="liteSets"*/}
                    {/*            spy={true}*/}
                    {/*            smooth={true}*/}
                    {/*            offset={-60}*/}
                    {/*            duration= {1000}>*/}
                    {/*            <Button size='large' color='inherit' style={{padding: '3px', fontSize: '1rem'}}>Lite Сети</Button>*/}
                    {/*        </Link>*/}

                    {/*        <Link*/}
                    {/*            activeClass={classes.active}*/}
                    {/*            className={classes.navCardItem}*/}
                    {/*            to="bigRolls"*/}
                    {/*            spy={true}*/}
                    {/*            smooth={true}*/}
                    {/*            offset={-60}*/}
                    {/*            duration= {1000}>*/}
                    {/*            <Button size='large' color='inherit' style={{padding: '3px', fontSize: '1rem'}}>роли: великі</Button>*/}
                    {/*        </Link>*/}

                    {/*        <Link*/}
                    {/*            activeClass={classes.active}*/}
                    {/*            className={classes.navCardItem}*/}
                    {/*            to="liteRolls"*/}
                    {/*            spy={true}*/}
                    {/*            smooth={true}*/}
                    {/*            offset={-60}*/}
                    {/*            duration= {1000}>*/}
                    {/*            <Button size='large' color='inherit' style={{padding: '3px', fontSize: '1rem'}}>Lite</Button>*/}
                    {/*        </Link>*/}

                    {/*        <Link*/}
                    {/*            activeClass={classes.active}*/}
                    {/*            className={classes.navCardItem}*/}
                    {/*            to="makiRolls"*/}
                    {/*            spy={true}*/}
                    {/*            smooth={true}*/}
                    {/*            offset={-60}*/}
                    {/*            duration= {1000}>*/}
                    {/*            <Button size='large' color='inherit' style={{padding: '3px', fontSize: '1rem'}}>макі</Button>*/}
                    {/*        </Link>*/}

                    {/*        <Link*/}
                    {/*            activeClass={classes.active}*/}
                    {/*            className={classes.navCardItem}*/}
                    {/*            to="cheeseRolls"*/}
                    {/*            spy={true}*/}
                    {/*            smooth={true}*/}
                    {/*            offset={-60}*/}
                    {/*            duration= {1000}>*/}
                    {/*            <Button size='large' color='inherit' style={{padding: '3px', fontSize: '1rem'}}>Сирні</Button>*/}
                    {/*        </Link>*/}

                    {/*        <Link*/}
                    {/*            activeClass={classes.active}*/}
                    {/*            className={classes.navCardItem}*/}
                    {/*            to="brandRolls"*/}
                    {/*            spy={true}*/}
                    {/*            smooth={true}*/}
                    {/*            offset={-60}*/}
                    {/*            duration= {1000}>*/}
                    {/*            <Button size='large' color='inherit' style={{padding: '3px', fontSize: '1rem'}}>Фірмові</Button>*/}
                    {/*        </Link>*/}

                    {/*        <Link*/}
                    {/*            activeClass={classes.active}*/}
                    {/*            className={classes.navCardItem}*/}
                    {/*            to="drinks"*/}
                    {/*            spy={true}*/}
                    {/*            smooth={true}*/}
                    {/*            offset={-60}*/}
                    {/*            duration= {1000}>*/}
                    {/*            <Button size='large' color='inherit' style={{padding: '3px', fontSize: '1rem'}}>Напої</Button>*/}
                    {/*        </Link>*/}

                    {/*    </Box>*/}
                    {/*:*/}
                        <Box className={classes.navCard}
                             component="div" my={2} overflow="auto" bgcolor="background.paper">

                            {/*<Link activeClass={classes.active}*/}
                            {/*      className={classes.navCardItem}*/}
                            {/*      to="sets"*/}
                            {/*      spy={true}*/}
                            {/*      smooth={true}*/}
                            {/*      offset={-60}*/}
                            {/*      duration= {1000}*/}
                            {/*>*/}
                            {/*    <Button variant='outlined' size='large' color='primary' style={{padding: '2px 15px', fontSize: '1.1rem', fontWeight: '800', borderRadius: '2rem', }}*/}
                            {/*            onClick={() => setSeeSushi(true)}*/}
                            {/*            endIcon={<TouchAppIcon/>}*/}
                            {/*    >Суші тут</Button>*/}
                            {/*</Link>*/}

                            <Link activeClass={classes.active}
                                  className={classes.navCardItem}
                                  to="pizzas"
                                  spy={true}
                                  smooth={true}
                                  offset={-60}
                                  duration= {1000}
                            >
                                <Button variant='text' size='large' color='inherit' style={{padding: '5px', fontSize: '1.1rem'}}
                                        onClick={() => setSeeSushi(false)}
                                >Піца</Button>
                            </Link>


                            <Link
                                activeClass={classes.active}
                                className={classes.navCardItem}
                                to="burgers"
                                spy={true}
                                smooth={true}
                                offset={-100}
                                duration= {1000}>
                                <Button size='large' color='inherit' style={{padding: '5px', fontSize: '1.1rem'}}>Бургери</Button>
                            </Link>






                            <Link
                                activeClass={classes.active}
                                className={classes.navCardItem}
                                to="combos"
                                spy={true}
                                smooth={true}
                                offset={-45}
                                duration= {1000}>
                                <Button size='large' color='inherit' style={{padding: '5px', fontSize: '1.1rem'}}>Комбо</Button>
                            </Link>

                            <Link
                                activeClass={classes.active}
                                className={classes.navCardItem}
                                to="snacks"
                                spy={true}
                                smooth={true}
                                offset={-40}
                                duration= {1000}>
                                <Button size='large' color='inherit' style={{padding: '5px', fontSize: '1.1rem'}}>Снекі</Button>
                            </Link>
                            <Link
                                activeClass={classes.active}
                                className={classes.navCardItem}
                                to="sauces"
                                spy={true}
                                smooth={true}
                                offset={-25}
                                duration= {1000}>
                                <Button size='large' color='inherit' style={{padding: '5px', fontSize: '1.1rem'}}>Соуси</Button>
                            </Link>
                            <Link
                                activeClass={classes.active}
                                className={classes.navCardItem}
                                to="drinks"
                                spy={true}
                                smooth={true}
                                offset={-20}
                                duration= {1000}>
                                <Button size='large' color='inherit' style={{padding: '5px', fontSize: '1.1rem'}}>Напої</Button>
                            </Link>
                            <Link
                                activeClass={classes.active}
                                className={classes.navCardItem}
                                to="actions"
                                spy={true}
                                smooth={true}
                                offset={-80}
                                duration= {1000}>
                                <Button size='large' color='inherit' style={{padding: '5px', fontSize: '1.1rem'}}>Акції</Button>
                            </Link>
                        </Box>
                    {/*}*/}



            </Grid>



            <Grid item />
            </Grid>

            <Grid item>


                <Tooltip title={
                    <Paper elevation={0}>
                    <MiniBasket
                        pizzaBox={pizzaBox}
                        incrProductCount={incrProductCount}
                        delProduct={delProduct}
                        decrProductCount={decrProductCount}
                    />
                    </Paper>
                } interactive
                classes={{tooltip: classes.customWidth}}
                         TransitionComponent={Zoom}>
                <ButtonGroup color="secondary"
                             variant="text"
                             className={classes.button_auth}
                             onClick={handleShowDialog}
                >
                    <Button>Кошик
                        <LocalPizzaRoundedIcon />
                        {pizzaBox.totalCount !== 0 ? pizzaBox.totalCount : ''}
                    </Button>
                    <Button>{pizzaBox.totalPrice !== 0 ? pizzaBox.totalPrice + '.грн' : 'пусто'}</Button>
                </ButtonGroup>
                </Tooltip>


                <Box
                    position="absolute"
                    top={50}
                    right={0}
                    zIndex={0}
                >

                    <IconButton aria-label="delete" className={classes.basketIcon}
                                onClick={handleShowDialog}>
                        <Badge badgeContent={pizzaBox.totalCount}
                               color="secondary"
                               overlap="circle"
                               anchorOrigin={{
                                   vertical: 'top',
                                   horizontal: 'left',
                               }}
                        >
                        <LocalMallRoundedIcon
                            fontSize="large"
                            color='primary'
                        />
                        </Badge>
                    </IconButton>
                </Box>


                <MakeOrderDialog
                    showDialog={showDialog}
                    handleShowDialog={handleShowDialog}
                    pizzaBox={pizzaBox}
                    incrProductCount={incrProductCount}
                    delProduct={delProduct}
                    decrProductCount={decrProductCount}
                    setPizzaBox={setPizzaBox}
                    addSaucesToBox={addSaucesToBox}
                    addDrinkToBox={addDrinkToBox}
                    location={location}
                    city={city}/>

            </Grid>

        </Grid>
            </Paper>
        </Container>
        </Paper>
        </Box>
    )
}

export default NavBar