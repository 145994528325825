import * as React from "react";
import {
    Create, useEditController, EditContextProvider,
    NumberField, ImageInput, SimpleForm, List, TextInput, NumberInput,
    Datagrid, TextField, ImageField, ArrayInput, SimpleFormIterator,
    ArrayField, RadioButtonGroupInput, required, SingleFieldList, ChipField
} from 'react-admin';

import AddBoxIcon from '@material-ui/icons/AddBox';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import {createMuiTheme, makeStyles, ThemeProvider} from '@material-ui/core/styles';
import {cloneElement} from "react";


const theme = createMuiTheme ({
    overrides: {
        RaImageField: {
            image: {
                maxHeight: '5rem',
                margin: 0,

            }

        }
    }
})


const useStyles = makeStyles({
    inlineBlock: {
        display: 'inline-flex',
        marginRight: '1rem',
    },
});



const Promotion = (props) => {
    return (
        <RadioButtonGroupInput source={props.sourse}
                               defaultValue={props.defaultValue}
                               row={false}
                               choices={[
                                   {id: 'none', name: 'Жодної категорії'},
                                   {id: 'top', name: 'Популярно'},
                                   {id: 'new', name: 'Новинка'},
                                   {id: 'week', name: 'Піца тиждня'},
                               ]}/>
    )
}

export const PizzasList = props => (
    <ThemeProvider theme={theme}>
    <List {...props} title='ПІЦА' sort={{ field: 'id#', order: 'ASC' }}
    exporter={false}>
        <Datagrid rowClick="edit">
            <NumberField source="id#" label='Номер'/>
            <TextField source="name" label='Назва'/>
            <TextField source="promotion" label='Акція'/>
            <ImageField source="pictures.src" title="pictures.title" label='Малюнок' />
            <TextField source="info" label='Інформація'/>


            <ArrayField source='possibleOptions' label='Доступні варіанти продукту'>
                <Datagrid>
                    <TextField source='surName' label='Варінат'/>
                    <TextField source="size" label='Розмір'/>
                    <NumberField source="weight" label='Вага,g'/>
                    <NumberField source="price" label='Ціна, грн'/>
                    <NumberField source="price_" label='Ціна +, грн'/>
                </Datagrid>
            </ArrayField>


            <ArrayField source="constIngadient" label='Базові складові рецепту'>
                    <SingleFieldList>
                        <ChipField source="constIngr" size='small'/>
                    </SingleFieldList>
            </ArrayField>

            <ArrayField source="letIngadient" label='Cкладові рецепту, що видаляються'>
                <SingleFieldList>
                    <ChipField source="letIngr" size='small'/>
                </SingleFieldList>
            </ArrayField>

        </Datagrid>
    </List>
    </ThemeProvider>
);


export const PizzasCreate = (props) => {
    const classes = useStyles();

    return (
        <Create {...props} title='Додати піцу'>
            <SimpleForm>
                    <TextInput source='name' label='Назва піци' validate={[required()]}/>
                    <NumberInput source="id#" label='Порядковий номер'
                                 formClassName={classes.inlineBlock}/>
                    <Promotion sourse='promotion' defaultValue='none'/>


                <ImageInput source="pictures" label="Зображення" accept="image/*"
                            formClassName={classes.inlineBlock}>
                    <ImageField source="src" title="title"/>
                </ImageInput>


                <ArrayInput source="possibleOptions" label='Доступні варіанти вибору' validate={[required()]}>
                    <SimpleFormIterator addButton={<AddBoxIcon/>} removeButton={<HighlightOffIcon/>}
                                        formClassName={classes.inlineBlock}>
                        <TextInput source='surName' label='Назва варіанту'
                                   formClassName={classes.inlineBlock}/>
                        <TextInput source='size' label='Розмір (діаметр)'
                                   formClassName={classes.inlineBlock}/>
                        <NumberInput source="weight" label='Вага'
                                     formClassName={classes.inlineBlock}/>
                        <NumberInput source="price" label='Ціна'
                                     formClassName={classes.inlineBlock}/>
                        <NumberInput source="price_" label='Ціна +'
                                     formClassName={classes.inlineBlock}/>
                    </SimpleFormIterator>
                </ArrayInput>


                <TextInput source='description' label='Опис' fullWidth={true}/>
                <TextInput source='info' label='Інформація' fullWidth={true}/>


                <ArrayInput source="constIngadient" label='Базові складові рецепту' validate={[required()]}>
                    <SimpleFormIterator addButton={<AddBoxIcon/>} removeButton={<HighlightOffIcon/>}>
                        <TextInput source='constIngr' label='Інградієнт'
                                   formClassName={classes.inlineBlock}
                                   validate={[required()]}/>
                    </SimpleFormIterator>
                </ArrayInput>
                <ArrayInput source="letIngadient" label='Cкладові рецепту, що видаляються' validate={[required()]}>
                    <SimpleFormIterator addButton={<AddBoxIcon/>} removeButton={<HighlightOffIcon/>}>
                        <TextInput source='letIngr' label='Інградієнт'
                                   formClassName={classes.inlineBlock}/>
                    </SimpleFormIterator>
                </ArrayInput>


            </SimpleForm>
        </Create>)
}

//============

const MyEdit = props => {
    const controllerProps = useEditController(props);
    const {
        basePath, record, redirect, resource, save,
        saving, version
    } = controllerProps;

    return (
        <EditContextProvider value={controllerProps}>
            <div>

                {cloneElement(props.children, {
                    basePath, record, redirect, resource,
                    save, saving, version,
                })
                }
            </div>
        </EditContextProvider>
    );

}


export const PizzasEdit = (props) => {
    const classes = useStyles();
    return (<MyEdit title='Редагування'  {...props} undoable={false}>
            <SimpleForm>
                <TextInput source='name' label='Назва піци'
                           formClassName={classes.inlineBlock}/>
                <NumberInput source="id#" label='Номер' formClassName={classes.inlineBlock}/>
                <TextInput disabled source="id" formClassName={classes.inlineBlock}/>
                <div>

                    <ImageInput source="pictures" label="Зображення" accept="image/*"
                                formClassName={classes.inlineBlock}>
                        <ImageField source="src" title="title"/>
                    </ImageInput>
                    <Promotion sourse='promotion' defaultValue='none'
                               formClassName={classes.inlineBlock}/>

                </div>





                {/*<Box>*/}
                {/*    <TextInput source='name' label='Назва піци'*/}
                {/*               // validate={[required()]}*/}
                {/*    />*/}
                {/*    <NumberInput source="id#" label='Порядковий номер'*/}
                {/*                 formClassName={classes.inlineBlock}/>*/}

                {/*</Box>*/}







                <ArrayInput source="possibleOptions" label='Доступні варіанти вибору' validate={[required()]}>
                    <SimpleFormIterator addButton={<AddBoxIcon/>} removeButton={<HighlightOffIcon/>}
                                        formClassName={classes.inlineBlock}>
                        <TextInput source='surName' label='Назва варіанту'
                                   formClassName={classes.inlineBlock}/>
                        <TextInput source='size' label='Розмір (діаметр)'
                                   formClassName={classes.inlineBlock}/>
                        <NumberInput source="weight" label='Вага'
                                     formClassName={classes.inlineBlock}/>
                        <NumberInput source="price" label='Ціна'
                                     formClassName={classes.inlineBlock}/>
                        <NumberInput source="price_" label='Ціна +'
                                     formClassName={classes.inlineBlock}/>
                    </SimpleFormIterator>
                </ArrayInput>

                <TextInput source='description' label='Опис' fullWidth={true}/>
                <TextInput source='info' label='Інформація' fullWidth={true}/>


                <ArrayInput source="constIngadient" label='Базові складові рецепту' validate={[required()]}>
                    <SimpleFormIterator addButton={<AddBoxIcon/>} removeButton={<HighlightOffIcon/>}>
                        <TextInput source='constIngr' label='Інградієнт'
                                   formClassName={classes.inlineBlock}
                                   validate={[required()]}/>
                    </SimpleFormIterator>
                </ArrayInput>
                <ArrayInput source="letIngadient" label='Cкладові рецепту, що видаляються' validate={[required()]}>
                    <SimpleFormIterator addButton={<AddBoxIcon/>} removeButton={<HighlightOffIcon/>}>
                        <TextInput source='letIngr' label='Інградієнт'
                                   formClassName={classes.inlineBlock}/>
                    </SimpleFormIterator>
                </ArrayInput>
            </SimpleForm>
        </MyEdit>
    );
}