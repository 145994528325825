import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Typography
} from "@material-ui/core";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import {useCollectionData} from "react-firebase-hooks/firestore";
import firebase from "firebase/app";
import {ToggleButton, ToggleButtonGroup} from "@material-ui/lab";


const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '25rem',
        minHeight: '32rem',
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        flexGrow: 1,
        position: 'relative'

    },

    img: {
        maxWidth: '100%',
    },

    paper: {
        display: 'flex',
        border: `1px solid ${theme.palette.divider}`,
        flexWrap: 'wrap',
    },


    media: {
        height: '60%',
        width: '95%',
        // paddingTop: '5%', // 16:9
        margin: '1% auto',
        '&:hover': {
            transform: 'scale(1.06)'
        }},

    toBox: {
        borderRadius: '2rem',
        margin: '1rem'
    },

    pricePizza: {
        fontWeight: '700',
        fontFamily: 'Comfortaa',
        fontSize: '1.9rem',
        paddding: '8px'
    },

    marginCard: {
        margin: '1% auto'
    },
    ingr: {
        marginLeft: '16px'
    },

    buttonGroup: {
        border: '0 solid',
        margin: '0'
    }

}));

const StyledToggleButton = withStyles({
    root: {
        border: '2px solid red',
        fontSize: '14px',
        fontWeight: '900',
        lineHeight: '15px',
        letterSpacing: '0.25px',
        color: 'rgba(0, 0, 0, 0.87)',
        padding: '10px 10px',
        textTransform: 'none',
        width: '80px',
        '&$selected': {
            backgroundColor: 'red',
            color: 'white',
            '&:hover': {
                backgroundColor: 'red',
                color: 'white',
            },
        },
    },
    selected: {},
})(ToggleButton);

const StyleToggleButton = withStyles({
    root: {
        border: '2px solid red',
        fontSize: '14px',
        fontWeight: '900',
        lineHeight: '15px',
        letterSpacing: '0.25px',
        color: 'rgba(0, 0, 0, 0.87)',
        padding: '10px 10px',
        textTransform: 'none',
        width: '140px',
        '&$selected': {
            backgroundColor: 'red',
            color: 'white',
            '&:hover': {
                backgroundColor: 'red',
                color: 'white',
            },
        },
    },
    selected: {},
})(ToggleButton);




export default function ChooseCombo({showDialog, combo, handleShowDialog, addComboToBox, price, location}) {
    const classes = useStyles();
    const handleClose = () => {
        handleShowDialog()
        setSaucePrice(0)
        setValue('')
    };



    //    Choose Sauce

    const [sauce, loading, error] = useCollectionData(
        firebase.firestore().collection('sauses'),
        {snapshotListenOptions: {includeMetadataChanges: true},}
    );





    const [value, setValue] = React.useState('');

    const [valueOn, setValueOn] = React.useState(true)
    const [saucePrice, setSaucePrice] = React.useState(0)

    const handleChooseSauce = (event) => {
            setValue(event.target.value);
            setValueOn(false)
            // combo.name !== 'FRI PIZZA' ?
            //     location ===2 ? setSaucePrice(sauce[0].price_) : setSaucePrice(sauce[0].price)
            //     :
                setSaucePrice(0)
    };

    const [alignment, setAlignment] = React.useState('Cola');

    const handleAlignment = (event, newAlignment) => {
        setAlignment(newAlignment);
    };

    let initialTaste = combo.possibleOptions[0].size
    const [taste, setTaste] = React.useState(initialTaste);

    const handleTaste = (event, newTaste) => {
        setTaste(newTaste);
    };




    const addedProduct = {
        category: 'Комбо з добавками',
        options: {
            chooseSauce: {
                sauceName: value,
                saucePrice: saucePrice,
            },
            deletedProducts: [],
            addedProducts: []
        },
        name: combo.name + " " + taste + " напій: " + alignment,
        imageURL: combo.pictures.src,
        price: price + saucePrice,
        count: 1,
    }

    const handleOrder = () => {
        addComboToBox(addedProduct)
        handleClose()
    }





    return (
        <div>

            <Dialog open={showDialog} onClose={handleShowDialog} aria-labelledby="form-dialog-title"
                    maxWidth='md'>
                <DialogContent>
                    <Grid container
                          spacing={1}
                          direction="row"
                          justify="flex-start"
                          alignItems="flex-start">


                        <Grid item xs={12} sm={6}>
                            <img src={combo.pictures.src} alt={combo.name} className={classes.img}/>
                        </Grid>

                        <Grid item xs={12} sm={1} />

                        <Grid item xs={12} sm={5}>

                            <Grid container
                                  spacing={1}
                                  direction="column"
                                  justify="flex-start"
                                  alignItems="flex-start">
                                <Grid item>

                                    <Typography variant='h6' color="secondary" align='left' className={classes.pricePizza}>
                                    "{combo.name}" {taste}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant='subtitle1'>Склад:
                                    </Typography>
                                    <Typography display='inline' variant='subtitle2'>{combo.info}
                                    </Typography>
                                </Grid>
                                <Grid item>

                                    {/*-----------Choice Taste----------------*/}

                                    {combo.name === 'Бургер МЕНЮ' ?
                                        <div>
                                            <Grid item>
                                                <Typography display='inline' variant='subtitle1'> Оберіть смак
                                                </Typography>
                                            </Grid>


                                            {/*Switch SIZE*/}
                                            <Grid item>
                                                <ToggleButtonGroup
                                                    value={taste}
                                                    exclusive
                                                    onChange={handleTaste}
                                                    aria-label="text alignment"
                                                    color="primary"
                                                >
                                                    <StyleToggleButton value={combo.possibleOptions[0].size}
                                                                  fullWidth={true}
                                                                  color="primary"
                                                                        aria-label={combo.possibleOptions[0].size} >
                                                        {combo.possibleOptions[0].size}
                                                    </StyleToggleButton>

                                                    <StyleToggleButton value={combo.possibleOptions[1].size}
                                                                  fullWidth={true}
                                                                        aria-label={combo.possibleOptions[1].size} >
                                                        {combo.possibleOptions[1].size}
                                                    </StyleToggleButton>

                                                </ToggleButtonGroup>
                                            </Grid>
                                        </div> :

                                        <div> </div>
                                    }





                                    {/*-----------End Choice Taste------------*/}




                                    {/*-----------Choice drink---------------*/}

                                    <Grid item>
                                        <Typography display='inline' variant='subtitle1'>Обраний
                                        </Typography>
                                        <Typography display='inline' variant='subtitle2'> напій {alignment}
                                        </Typography>
                                    </Grid>


                                    {/*Switch SIZE*/}
                                    <Grid item>
                                        <ToggleButtonGroup
                                            value={alignment}
                                            exclusive
                                            onChange={handleAlignment}
                                            aria-label="text alignment"
                                        >
                                            <StyledToggleButton value='Cola' aria-label="Cola">
                                                Cola
                                            </StyledToggleButton>
                                            <StyledToggleButton value='Sprite' aria-label="Sprite">
                                                Sprite
                                            </StyledToggleButton>
                                            <StyledToggleButton value='Fanta' aria-label="Fanta">
                                                Fanta
                                            </StyledToggleButton>
                                        </ToggleButtonGroup>
                                    </Grid>



                                    {/*-----------end Choice drink---------------*/}



                                    {combo.name !== 'FRI PIZZA' ?
                                    <Typography variant='h6' color='secondary'>Додати соус +
                                         {saucePrice} грн
                                    </Typography> :
                                        <Typography variant='h6' color='secondary'>Обрати соус
                                        </Typography>
                                    }
                                    {sauce &&

                                    <FormControl component="fieldset">
                                        <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChooseSauce}>

                                            {sauce.map((sauce, index) => (
                                            <FormControlLabel key={index} value={sauce.name} control={<Radio style={{padding: 3}} />} label={sauce.name} />))}

                                        </RadioGroup>
                                    </FormControl>
                                    }



                                </Grid>
                            </Grid>


                        </Grid>

                    </Grid>




                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        До меню
                    </Button>
                    <Button variant="outlined" color="secondary" disableElevation size='large' className={classes.toBox}
                    disabled={combo.name !== 'FRI PIZZA' ? false : valueOn}
                            onClick={handleOrder}
                    >
                        Замовити за {addedProduct.price} грн
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}