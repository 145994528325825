import * as React from "react";
import {
    Create, useEditController, EditContextProvider,
    SimpleForm, List, TextInput,
    Datagrid, TextField, NumberField, NumberInput, SimpleFormIterator
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import {cloneElement} from "react";


const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
});


export const CitiesList = props => (
    <List {...props} title="Міста">
        <Datagrid rowClick="edit">
            <TextField source="name" label='Місто'/>
            <TextField source="phoneNumber" label='Телефон'/>
            <TextField source="schedule" label='Графік роботи'/>
            <NumberField source="location" label='Ціновий регіон'/>
        </Datagrid>
    </List>
);

export const CitiesCreate = (props) => {
    const classes = useStyles();
    return (
        <Create {...props} title="Відкрити місто">
            <SimpleForm>
                <TextInput source='name' label='Місто'
                           formClassName={classes.inlineBlock}/>


                <TextInput source='phoneNumber'
                           label='Телефон для замовлень' fullWidth/>
                <TextInput source='schedule'
                           label='Графік роботи' fullWidth/>
                <NumberInput source="location" label='Цінова категорія'
                             formClassName={classes.inlineBlock}/>
            </SimpleForm>
        </Create>)}


//================================



//============

const MyEdit = props => {
    const controllerProps = useEditController(props);
    const {
        basePath, // deduced from the location, useful for action buttons
        // defaultTitle, // the translated title based on the resource, e.g. 'Post #123'
        // loaded, // boolean that is false until the record is available
        // loading, // boolean that is true on mount, and false once the record was fetched
        record, // record fetched via dataProvider.getOne() based on the id from the location
        redirect, // the default redirection route. Defaults to 'list'
        resource, // the resource name, deduced from the location. e.g. 'posts'
        save, // the update callback, to be passed to the underlying form as submit handler
        saving, // boolean that becomes true when the dataProvider is called to update the record
        version, // integer used by the refresh feature
    } = controllerProps;

    return (
        <EditContextProvider value={controllerProps}>
            <div>

                {cloneElement (props.children, {
                    basePath,
                    record,
                    redirect,
                    resource,
                    save,
                    saving,
                    version,
                })

                }
            </div>
        </EditContextProvider>
    );

}


export const CitiesEdit = (props) => {
    const classes = useStyles();
    return (<MyEdit title='Редагування'  {...props} undoable={false}>
            <SimpleForm>
                <TextInput disabled source="id" />
                <TextInput source='name' label='Назва міста'
                           formClassName={classes.inlineBlock}/>
                <TextInput source='phoneNumber'
                           label='Номер телефону для замовлень' fullWidth/>
                <TextInput source='schedule'
                           label='Графік роботи' fullWidth/>
                <NumberInput source="location" label='Цінова категорія'
                             formClassName={classes.inlineBlock}/>
            </SimpleForm>
        </MyEdit>
    );
}


