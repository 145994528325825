import React from 'react';
import {createMuiTheme, makeStyles, ThemeProvider} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import pizzaDefault from '../../../images/pizza.jpg'
import {Skeleton} from "@material-ui/lab";
import {Box, Grid} from "@material-ui/core";
import {ukUA} from "@material-ui/core/locale";

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#4c8c4a',
            main: '#025d0a',
            dark: '#003300',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ff867f',
            main: '#f50404',
            dark: '#c50e29',
            contrastText: '#fff',
        },
    },

}, ukUA);

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '25rem',
        minHeight: '25rem',
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },

    cardBody: {
        minHeight: '10rem',
        width: '18rem'
    },


    media: {
        height: '90%',
        width: '90%',
        // paddingTop: '5%', // 16:9
        margin: '1% 5%',
    },

    toBox: {
        borderRadius: '2rem',
        margin: '0.5rem'
    },

    pricePizza: {
        fontWeight: '700',
        fontFamily: 'Comfortaa',
        fontSize: '2.1rem'
    },

    marginCard: {
        margin: '0 5%'
    },
    ingr: {
        marginLeft: '16px'
    },

}));

export default function DefaultCard() {
    const classes = useStyles();


    return (

        <Card className={classes.root} elevation={0} >
            <Box>
                <CardActionArea className={classes.cardBody}>
                    <CardMedia
                        className={classes.media}
                        component="img"
                        alt="PIZZA"
                        image={pizzaDefault}/>

                        <Skeleton height='3rem'  className={classes.media}/>
                        <Skeleton height='3rem' className={classes.media}/>
                </CardActionArea>



                <CardActions className={classes.marginCard}>


                    <Grid container spacing={0}
                          direction="row"
                          justify="space-between"
                          alignItems="center"
                          className={classes.marginCard}
                          style={{ padding: 0}}>

                        <Grid item style={{ padding: 0}}>
                                <Skeleton height='4rem'  width='6rem' style={{ padding: 0}}/>
                        </Grid>


                        <Grid item style={{ padding: 0}}>

                            <Skeleton height='4rem'  width='6rem' style={{ padding: 0}} />

                        </Grid>


                    </Grid>


                </CardActions>



            </Box>
        </Card>
    );

}