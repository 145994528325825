import React, {useEffect, useState} from "react";

import { useStorage } from '../hooks/useStorage'
import app from 'firebase/app'
import 'firebase/firestore'

import { useFirebaseApp } from 'reactfire';
import Typography from "@material-ui/core/Typography";



function ImageAvatar ({UserID}) {
    const [file, setFile] = useState(null)
    const [error, setError] =useState(null)

    const firebase = useFirebaseApp()
    const db = app.firestore()



    const types = ['image/png', 'image/jpeg', 'image/jpg']

    const handleChange = (e) => {
        let selectedFile = e.target.files[0]

        if (selectedFile) {
            if(types.includes(selectedFile.type)) {
                setError(null)
                setFile(selectedFile)
            } else {
                setFile(null)
                setError('Файл має бути картинкою (png або ipg)')
            }
        }
    }

    const { progress, url } = useStorage(file)

    const addUser = (UserId, url) =>

        db.collection('users').doc(UserId).set (
            {
                // phoneNumber: authUser.user.phoneNumber,
                // lastSignData: authUser.user.metadata.lastSignInTime
                // name: authUser.user.displayName,
                // email: authUser.user.email,
                photoURL: url,
                // roles: {},

            }, {merge: true},)

    useEffect(()=> {
        if(url){
            addUser(UserID, url)
        }
        console.log ('effect URL')

    }, [UserID, url])


    return (
        <div>
            <label>
                <Typography variant="overline" color="primary"
                            align='center'
                            display='inline'
                style={{cursor: 'pointer'}}>
                    Змінити аватар
                </Typography>
                <input type='file' onChange={handleChange}
                       style={{height: 0, width: 0, opacity: 0}}/>
            </label>

            {error &&
            <Typography variant='caption'>{error}</Typography>
            }
            {file && <Typography variant='caption'>{progress}% uploaded</Typography>}

        </div>
    )
}

export default ImageAvatar