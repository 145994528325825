import * as React from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title } from 'react-admin';
import Typography from "@material-ui/core/Typography";
import NF from '../../../images/NotFOUND.png'

const NotFound = () => {
    return (
    <Card>
        <Title title="Сторінка не знайдена" />
        <CardContent>

            <Typography
                variant='h5'
                color='inherit'
                align='center'>
                <p>404: Сторінка не знайдена</p>
                <img width='200px' height='200px' src={NF} alt='not found'/>
            </Typography>

        </CardContent>
    </Card>
)};

export default NotFound