import React, { useEffect } from "react";
import {
    Dialog,
    DialogContentText,
    FormControl, IconButton,
    TextField
} from "@material-ui/core";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Button from "@material-ui/core/Button";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import CloseIcon from '@material-ui/icons/Close';
import {Transition} from "react-transition-group";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import { withStyles } from '@material-ui/core/styles';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';

import 'react-phone-input-2/lib/material.css'
import PhoneInput from 'react-phone-input-2'
import NumberFormat from 'react-number-format'

import app from 'firebase/app'
import fb from "firebase/app";
import 'firebase/firestore'

import { useFirebaseApp } from 'reactfire';
import {useDocumentData} from "react-firebase-hooks/firestore";
import firebase from "firebase";

import { getAuth, signInWithPhoneNumber } from 'firebase/auth'





const useStyles = makeStyles((theme) => ({
    grow: {
        display:"flex",
        flexGrow: 1,
    },

    margin: {
        margin: theme.spacing(2),
    },

    button_auth: {
        margin: '10px',
        borderRadius: "33px",
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },

    button_auth_small: {
        margin: '5px',
        borderRadius: "30px",
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },
    },

    button: {
        margin: '15px',
        borderRadius: "33px",
        border: '2px solid ',
    },

    dialog: {
        borderRadius: "35px",
    },
}));

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h5" color='primary' style={{fontWeight: '800'}}>{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon fontSize="small"/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
}))(MuiDialogActions);



const SignUp = () => {

    const classes = useStyles();
    // відкриття, закриття форми входу

    const [open, setOpen] = React.useState(false);
    const [user, setUser] = React.useState({
        name: '',
        phone: '',
        verifyCode: '',
        codeIsSend: false,
        confirmation: {},
        error: '',
    })

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setPhone('')
    };

    // onChange function
    const handleChange = e => {
        setUser({
            ...user,
            [e.target.name]: e.target.value,
            error: '',
        })
    };

    const [phone, setPhone] = React.useState('');
    const firebase = useFirebaseApp();

    //=========================
    const db = app.firestore()




    // const captchaRef = useRef(null);
    useEffect(() => {
        window.recaptchaVerifier = new fb.auth.RecaptchaVerifier(
            "recaptcha-container",
            {
                size: "invisible",
                callback: function (response) {
                    onFinish();
                },
            },
        );
    });


    const onFinish = () => {
        const mobile = `+${phone}`
        let appVerifier = window.recaptchaVerifier;
        // const auth = getAuth()

        // signInWithPhoneNumber(auth, mobile, appVerifier)
        fb.auth().signInWithPhoneNumber(mobile, appVerifier)
            .then((confirmationResult) => {
                window.confirmationResult = confirmationResult
                setUser({
                    ...user, codeIsSend: true})
            }).catch(error =>{

            console.log(error)
            window.recaptchaVerifier.reset()
        })


    };

    const onVerifyCode = (event) => {
        event.preventDefault()
        const code = user.verifyCode
        window.confirmationResult.confirm(code).then((authUser) => {

            const uid = authUser.user.uid
            // console.log(uid)
            // console.log(authUser.user)
            addUser(uid, authUser)


        }).catch((error) => {

        })
    }

    const addUser = (uid, authUser) =>

        db.collection('users').doc(uid).set (
            {
                phoneNumber: authUser.user.phoneNumber,
                lastSignData: authUser.user.metadata.lastSignInTime
                // name: authUser.user.displayName,
                // email: authUser.user.email,
                // photoURL: ,
                // roles: {},

            }, {merge: true},)






    return (
        <div>
            <div id="recaptcha-container"> </div>
                <Button className={classes.button_auth}
                        onClick={handleClickOpen}
                        variant="outlined"
                        color="secondary"
                        size="large"
                        startIcon={<PersonOutlineOutlinedIcon fontSize='large'/>}
                >Вхід</Button>
            <IconButton aria-label="delete" className={classes.button_auth_small}
            onClick={handleClickOpen}>
                <ContactPhoneIcon fontSize="large"
                color='secondary'/>
            </IconButton>

                <Dialog
                    className={classes.dialog}
                    open={open}
                    TransitionComponent={Transition}
                    onClose={handleClose}>


                    <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                        Вхід на сайт
                    </DialogTitle>

                    <DialogContent>

                        {!user.codeIsSend
                            ?  <DialogContentText color="primary"
                        variant='subtitle2'>
                            Введіть свій номер телефону
                        </DialogContentText>
                        : <DialogContentText color="primary"
                            variant='subtitle2'>
                            Код підтвердження висланий на телефон <br/> {phone}
                            </DialogContentText>

                        }


                        <FormControl fullWidth variant="outlined" color="secondary"
                                     className={classes.label}>


                            {!user.codeIsSend
                                ?  <PhoneInput
                                    specialLabel={'Телефон'}
                                    country={'ua'}
                                    value={phone}
                                    onChange={setPhone}
                                    customInput={TextField}
                                />
                                : <NumberFormat
                                    name='verifyCode'
                                    label="Код підтвердження"
                                    variant="outlined"
                                    customInput={TextField}
                                    format=" # # # # # # "
                                    onValueChange={(values) => {
                                        handleChange ({
                                            target: {
                                                name: 'verifyCode',
                                                value: values.value,
                                            },
                                        });
                                    }}

                                    allowEmptyFormatting mask=" "/>
                            }


                        </FormControl>

                    </DialogContent>

                    <DialogActions>
                        {!user.codeIsSend
                            ? <Button
                            disabled={phone.length <= 11}
                            onClick={onFinish}
                            autoFocus color="primary" variant='outlined'>
                            Отримати код
                        </Button>
                            : <Button
                                disabled={user.verifyCode.trim().length <= 5}
                                onClick={onVerifyCode}
                                autoFocus color="primary" variant='outlined'>
                                Надіслати код
                            </Button>}

                            </DialogActions>


                    {user.error && <h4> {user.error}</h4>}
                </Dialog>

            </div>
    )
}

export default SignUp