import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {Box, Chip, Grid, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

import DoneIcon from '@material-ui/icons/Done';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CloseIcon from "@material-ui/icons/Close";
import AdditivesList from "./AdditivesList";
import CheeseBordList from "./CheeseBort";

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '25rem',
        minHeight: '32rem',
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        flexGrow: 1,
        position: 'relative'

    },

    img: {
        maxWidth: '100%',
        [theme.breakpoints.down('xs')]: {
            maxWidth: '80%',
            display: 'block',
            margin: 'auto',
        },
    },

    paper: {
        display: 'flex',
        border: `1px solid ${theme.palette.divider}`,
        flexWrap: 'wrap',
    },


    media: {
        height: '60%',
        width: '95%',
        // paddingTop: '5%', // 16:9
        margin: '1% auto',
        '&:hover': {
            transform: 'scale(1.06)'
        }
    },
    chips: {
        margin: '3px',
        border: '2 solid',
    },
    chipsDEC: {
        margin: '3px',
        border: '2 solid',
        textDecoration: 'line-through'
    },

    toBox: {
        borderRadius: '2rem',
        margin: '1rem'
    },

    pricePizza: {
        fontWeight: '700',
        fontFamily: 'Comfortaa',
        fontSize: '1.9rem',
        paddding: '8px'
    },

    marginCard: {
        margin: '1% auto'
    },
    ingr: {
        marginLeft: '16px'
    },

    buttonGroup: {
        border: '0 solid',
        margin: '0'
    }

}));


export default function ChoosePizza({showDialog, pizza, handleShowDialog, addPizzaToBox, price}) {
    const classes = useStyles();
    const handleClose = () => {
        handleShowDialog()
    };


    const [deletedINGR, setDeletedINGR] = React.useState([])


    const [unchangedINGR, setUnchangedINGR] = React.useState(pizza.letIngadient)
    const handleDelete = (chipToDelete) => () => {
        setUnchangedINGR((chips) => chips.filter((chip) => chip.letIngr !== chipToDelete.letIngr));
        setDeletedINGR([...deletedINGR, chipToDelete])
    };

    const handleReturn = (chipToReturn) => () => {
        setDeletedINGR((chips) => chips.filter((chip) => chip.letIngr !== chipToReturn.letIngr));
        setUnchangedINGR([...unchangedINGR, chipToReturn])
    };

    const [addedINGR, setAddedINGR] = React.useState([])
    const handleAdded = (checked) => {
        setAddedINGR(checked)
    }

    const totalPriceAdd = addedINGR.reduce((sumPrice, price) => sumPrice + price.price, 0)


    const addedProduct = {
        category: 'Піца',
        options: {
            deletedProducts: deletedINGR,
            addedProducts: addedINGR,
        },
        name: pizza.name,
        imageURL: pizza.pictures.src,
        price: price + totalPriceAdd,
        count: 1,
    }

    const handleOrder = () => {
        addPizzaToBox(addedProduct)
        handleClose()
    }


    return (
        <div>

            <Dialog open={showDialog} onClose={handleShowDialog} aria-labelledby="form-dialog-title"
                    maxWidth='md'>
                <DialogContent>
                    <Grid container
                          spacing={1}
                          direction="row"
                          justify="flex-start"
                          alignItems="center">


                        <Grid item xs={12} sm={7}>
                            <img src={pizza.pictures.src} alt={pizza.name} className={classes.img}/>

                        </Grid>
                        <Grid item xs={12} sm={5}>

                            <Grid container
                                  spacing={1}
                                  direction="column"
                                  justify="flex-start"
                                  alignItems="flex-start">
                                <Grid item>
                                    <Typography variant='h6' color="secondary" align='left'
                                                className={classes.pricePizza}>
                                        {pizza.name}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography display='inline'
                                                variant='subtitle2'>Розмір: {pizza.possibleOptions[0].size}

                                        <span>,  Вага: {pizza.possibleOptions[0].weight} г.</span>
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Box>

                                        {pizza.constIngadient.map((ingrF, index) => (
                                            <Chip key={index} label={ingrF.constIngr}
                                                  size="small"
                                                  clickable
                                                  variant="outlined"
                                                  color='primary'
                                                  className={classes.chips}
                                                  onDelete={handleDelete}
                                                  deleteIcon={<DoneIcon/>}
                                            />
                                        ))}
                                        {unchangedINGR.map((ingr, index) => (
                                            <Chip key={index} label={ingr.letIngr}
                                                  size="small"
                                                  clickable
                                                  variant="outlined"
                                                  color='primary'
                                                  className={classes.chips}
                                                  onDelete={handleDelete(ingr)}
                                                  deleteIcon={<CloseIcon/>}
                                            />
                                        ))}
                                        {deletedINGR.map((ingr, index) => (
                                            <Chip key={index} label={ingr.letIngr}
                                                  size="medium"
                                                  clickable
                                                  variant="outlined"
                                                  color='secondary'
                                                  className={classes.chipsDEC}
                                                  onDelete={handleReturn(ingr)}
                                                  deleteIcon={<AddCircleOutlineIcon/>}
                                            />
                                        ))}
                                    </Box>
                                </Grid>

                                {/*<Grid item style={{width: '100%'}}>*/}
                                {/*    /!*<Typography display='inline' variant='h6' color='secondary'>Сирний борт*!/*/}
                                {/*    /!*</Typography>*!/*/}
                                {/*    <CheeseBordList handleAdded={handleAdded}/>*/}


                                {/*</Grid>*/}

                                <Grid item style={{width: '100%'}}>
                                    {/*<Typography display='inline' variant='h6' color='secondary'>Додатки до піци*/}
                                    {/*</Typography>*/}
                                    <AdditivesList handleAdded={handleAdded}/>


                                </Grid>
                            </Grid>


                        </Grid>

                    </Grid>


                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        До меню
                    </Button>
                    <Button onClick={handleOrder}
                            variant="outlined" color="secondary" disableElevation size='large'
                            className={classes.toBox}>
                        Замовити за {addedProduct.price} грн
                    </Button>

                </DialogActions>
            </Dialog>
        </div>
    );
}