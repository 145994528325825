import React from "react";

import {useCollectionData} from 'react-firebase-hooks/firestore'
import firebase from 'firebase/app';

import {makeStyles, createMuiTheme, ThemeProvider} from '@material-ui/core/styles'
import {Box, CardActionArea, Container, Paper} from "@material-ui/core";

import Typography from '@material-ui/core/Typography';

import {ukUA} from "@material-ui/core/locale";

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#4c8c4a',
            main: '#025d0a',
            dark: '#003300',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ff867f',
            main: '#f50404',
            dark: '#c50e29',
            contrastText: '#fff',
        },
    },

}, ukUA);


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(0),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        height: '200px'
    },
    title: {
        margin: '2rem 0',
        padding: '1rem 1rem 1rem',
        fontWeight: '900',
        fontFamily: 'Comfortaa',
        fontSize: '2rem',

    },
    pricePizza: {
        fontWeight: '700',
        fontFamily: 'Comfortaa',
        fontSize: '1.2rem',
        padding: '8px',
        marginBottom: '10px',
        marginTop: '20px',

    },
    card: {
        width: '90px',
        height: '165px',
        margin: '4px',

    },
}));

const Resale = ({addSaucesToBox, addDrinkToBox, location}) => {

    const classes = useStyles();

    const [sause, loading, error] = useCollectionData(
        firebase.firestore().collection('sauses'),
        {
            snapshotListenOptions: {includeMetadataChanges: true},
        },
    );

    const [drink, load, err] = useCollectionData(
        firebase.firestore().collection('drinks'),
        {
            snapshotListenOptions: {includeMetadataChanges: true},
        }
    );

    return (
        <Container className={classes.root} spacing={0} maxWidth='lg'>
            <ThemeProvider theme={theme}>


                <Typography variant='h3' color="secondary" align='left' className={classes.pricePizza}>
                    Радимо до Вашого замовлення:
                </Typography
                >


                {error && <strong>Error: {JSON.stringify(error)}</strong>}
                {loading && <p>Loading</p>}
                <Box style={{display: 'flex', overflowX: 'auto'}}>
                    {sause && sause.map((sauce, index) => (
                        <div key={index}>
                            <Box>
                                <CardActionArea onClick={() => addSaucesToBox(
                                    {
                                        category: 'Соуси',
                                        options: {
                                            deletedProducts: [],
                                            addedProducts: []
                                        },
                                        capacity: '',
                                        name: sauce.name,
                                        imageURL: sauce.pictures.src,
                                        price: location === 2 ? sauce.price_ : sauce.price,
                                        count: 1,
                                    }
                                )}>
                                    <Paper className={classes.card}>

                                        <img width='100%' src={sauce.pictures.src} alt="sauce"/>
                                        <Typography color='secondary'>
                                            {location === 2 ? sauce.price_ : sauce.price}.00</Typography>
                                        <Typography variant='subtitle2'>{sauce.name}</Typography>
                                    </Paper>
                                </CardActionArea>
                            </Box>
                        </div>)
                    )
                    }
                    {drink && drink.map((drink) => (
                        <div key={drink.id}>

                            <Box>
                                <CardActionArea onClick={() => addDrinkToBox(
                                    {
                                        category: 'Напої',
                                        capacity: drink.weight,
                                        options: {
                                            deletedProducts: [],
                                            addedProducts: []
                                        },
                                        name: drink.name,
                                        imageURL: drink.pictures.src,
                                        price: location === 2 ? drink.price_ : drink.price,
                                        count: 1
                                    }
                                )}>
                                    <Paper className={classes.card}>
                                        <img width='100%' src={drink.pictures.src} alt="sauce"/>
                                        <Typography color='secondary'>
                                            {location === 2 ? drink.price_ : drink.price}.00</Typography>
                                        <Typography variant='subtitle2'>{drink.name}</Typography>
                                    </Paper>
                                </CardActionArea>
                            </Box>
                        </div>)
                    )}
                </Box>

            </ThemeProvider>
        </Container>
    );
};


export default Resale