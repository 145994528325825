import * as React from "react";
import {
    Create, useEditController, EditContextProvider,
    NumberField, ImageInput, SimpleForm, List, TextInput, NumberInput,
    Datagrid, TextField, ImageField
} from 'react-admin';

import {makeStyles} from '@material-ui/core/styles';
import {cloneElement} from "react";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

const theme = createMuiTheme ({
    overrides: {
        RaImageField: {
            image: {
                maxHeight: '4rem',
                margin: 0,

            }

        }
    }
})


const useStyles = makeStyles({
    inlineBlock: {display: 'inline-flex', marginRight: '1rem'},
});

export const AddsList = props => {

    return (
        <ThemeProvider theme={theme}>
        <List {...props} title="ДОДАТКИ ДО піци">
        <Datagrid rowClick="edit">
            <ImageField source="pictures.src" title="pictures.title" label='Малюнок'>
                <div>
                    <img width='10px' height='10px' src="pictures.src" alt='pictures.title'/>
                </div>
            </ImageField>
            <TextField source="name" label='Назва'/>
            <NumberField source="weight" label='Вага, гр'/>
            <NumberField source="price" label='Ціна, грн'/>
        </Datagrid>
    </List>
        </ThemeProvider>
)};


export const AddsCreate = (props) => {
    const classes = useStyles();
    return (
        <Create {...props} title='Додати додатки'>
            <SimpleForm>
                <ImageInput source="pictures" label="Зображення" accept="image/*">

                    <ImageField source="src" title="title"/>
                </ImageInput>
                <TextInput source='name' label='Назва додатку'
                           formClassName={classes.inlineBlock}/>
                <NumberInput source="weight" label='Вага в гр.'
                             formClassName={classes.inlineBlock}/>
                <NumberInput source="price" label='Ціна'
                             formClassName={classes.inlineBlock}/>
            </SimpleForm>
        </Create>)
}

//============

const MyEdit = props => {
    const controllerProps = useEditController(props);
    const {
        basePath, record, redirect, resource, save,
        saving, version
    } = controllerProps;

    return (
        <EditContextProvider value={controllerProps}>
            <div>

                {cloneElement(props.children, {
                    basePath, record, redirect, resource,
                    save, saving, version,
                })
                }
            </div>
        </EditContextProvider>
    );

}


export const AddsEdit = (props) => {
    const classes = useStyles();
    return (<MyEdit title='Редагування'  {...props} undoable={false}>
            <SimpleForm>
                <TextInput disabled source="id"/>

                <ImageInput source="pictures" label="Зображення" accept="image/*">

                    <ImageField source="src" title="title"/>
                </ImageInput>
                <TextInput source='name' label='Назва додатка'
                           formClassName={classes.inlineBlock}/>
                <NumberInput source="weight" label='Вага в гр.'
                             formClassName={classes.inlineBlock}/>
                <NumberInput source="price" label='Ціна'
                             formClassName={classes.inlineBlock}/>

            </SimpleForm>
        </MyEdit>
    );
}