import React from "react";

import {FirebaseAuthProvider, FirebaseDataProvider} from 'react-admin-firebase';
import firebaseConfig from '../Firebase/firebaseConfig'
import {Admin, Resource} from 'react-admin';
import {AuthCheck, useFirebaseApp} from "reactfire";
import 'firebase/firestore'
import app from "firebase";


import {UserEdit, UserList} from "./Users/Users";


import MyLayout from './MyLayout';
import {StockCreate, StockEdit, StockList} from "./Stocks/Stoksc";
import Dashboard from "./Dashboard/Dashboard";
import NotFound from "./NotFound/NotFound";

import polyglotI18nProvider from 'ra-i18n-polyglot';
import ukrainianMessages from 'ra-language-ukrainian';
import {SausesCreate, SausesEdit, SausesList} from "./Sauses/Sauses";
import {DrinksCreate, DrinksEdit, DrinksList} from "./Drinks/Drinks";
import {AddsCreate, AddsEdit, AddsList} from "./Additives/Additives";
import {SnacksCreate, SnacksEdit, SnacksList} from "./Snacks/Snacks";
import {BurgerCreate, BurgerEdit, BurgerList} from "./Burgers/burgers";
import {PizzasCreate, PizzasEdit, PizzasList} from "./Pizzas/Pizzas";
import {CombosCreate, CombosEdit, CombosList} from "./Combo/Combo";
import {TagCreate, TagEdit, TagsList} from "./Tags/Tags";
import {PizzariaList, PizzeriaCreate, PizzeriaEdit} from "./Pizzarias/Pizzarias";
import {OrdersList} from "./Orders/Orders";
import Landing from "../pages/Landing/Landing";
import {CitiesCreate, CitiesEdit, CitiesList} from "./Сities/Cities";
import {BigRollsCreate, BigRollsEdit, BigRollsList} from "./Sushi/Big_rolls/BigRolls";
import {MakiRollsCreate, MakiRollsEdit, MakiRollsList} from "./Sushi/Maki_rolls/MakiRolls";
import {CheeseRollsCreate, CheeseRollsEdit, CheeseRollsList} from "./Sushi/Cheese_rolls/CheeseRolls";
import {BrandRollsCreate, BrandRollsEdit, BrandRollsList} from "./Sushi/Brand_rolls/BrandRolls";
import {LiteRollsCreate, LiteRollsEdit, LiteRollsList} from "./Sushi/Lite_rolls/LiteRolls";
import {SetsCreate, SetsEdit, SetsList} from "./Sushi/Sets/Sets";
import {LiteSetsCreate, LiteSetsEdit, LiteSetsList} from "./Sushi/Lite_sets/LeitSets";
import {CheeseBoardCreate, CheeseBoardEdit, CheeseBoardList} from "./CheeseBoard /CheeseBoard";





const i18nProvider = polyglotI18nProvider(() => ukrainianMessages, 'ua', { allowMissing: true });

const config = firebaseConfig






const AdminPanel = () => {

    return (
        <AuthCheck fallback={<Landing />}>
            <AdminPan />
        </AuthCheck>

    )
}
export default AdminPanel

const AdminPan = () => {
    const db = app.firestore()
    const firebaseApp = useFirebaseApp()
    const options = {
        app: firebaseApp,
    }



    const dataProvider = FirebaseDataProvider(config, options);
    const authProvider = FirebaseAuthProvider(config, options)



    return (


        <Admin
            catchAll={NotFound}
            // loginPage={SignUp}
            layout={MyLayout}
            title='Pizza 7 stars - ADMIN'
            dashboard={Dashboard}
            dataProvider={dataProvider}
            authProvider={authProvider}
            i18nProvider={i18nProvider}>

            {permissions => [
            <Resource
                name="stocks"
                options={{ label: 'Акції' }}
                list={StockList}
                create={StockCreate}
                edit={StockEdit}/>,

                <Resource
                    name="cities"
                    options={{ label: 'Міста' }}
                    list={CitiesList}
                    create={CitiesCreate}
                    edit={CitiesEdit}/>,


            <Resource
                name="tags"
                options={{ label: 'Акційні наліпки' }}
                list={TagsList}
                create={TagCreate}
                edit={TagEdit}/>,

            <Resource
                name="pizzas"
                options={{ label: 'ПІЦА' }}
                list={PizzasList}
                create={PizzasCreate}
                edit={PizzasEdit}
            />,

                <Resource
                    name="burgers"
                    options={{ label: 'Бургери' }}
                    list={BurgerList}
                    create={BurgerCreate}
                    edit={BurgerEdit}
                />,

            <Resource
                name="combos"
                options={{ label: 'Комбо-меню' }}
                list={CombosList}
                create={CombosCreate}
                edit={CombosEdit}
            />,

            <Resource
                name="sauses"
                options={{ label: 'Соуси' }}
                list={SausesList}
                create={SausesCreate}
                edit={SausesEdit}/>,

            <Resource
                name="drinks"
                options={{ label: 'Напої' }}
                list={DrinksList}
                create={DrinksCreate}
                edit={DrinksEdit}/>,

                <Resource
                    name="additives"
                    options={{label: 'Додатки'}}
                    list={AddsList}
                    create={AddsCreate}
                    edit={AddsEdit}/>,

                <Resource
                    name="cheesebord"
                    options={{label: 'Сирний борт'}}
                    list={CheeseBoardList}
                    create={CheeseBoardCreate}
                    edit={CheeseBoardEdit}/>,

                <Resource
                    name="snacks"
                    options={{label: 'Снекі'}}
                    list={SnacksList}
                    create={SnacksCreate}
                    edit={SnacksEdit}/>,

                // <Resource
                //     name="bigRolls"
                //     options={{ label: 'Великі роли' }}
                //     list={BigRollsList}
                //     create={BigRollsCreate}
                //     edit={BigRollsEdit}
                // />,

                // <Resource
                //     name="makiRolls"
                //     options={{ label: 'Макі роли' }}
                //     list={MakiRollsList}
                //     create={MakiRollsCreate}
                //     edit={MakiRollsEdit}
                // />,

                // <Resource
                //     name="cheeseRolls"
                //     options={{ label: 'Макі роли' }}
                //     list={CheeseRollsList}
                //     create={CheeseRollsCreate}
                //     edit={CheeseRollsEdit}
                // />,

                // <Resource
                //     name="brandRolls"
                //     options={{ label: 'Фірмові роли' }}
                //     list={BrandRollsList}
                //     create={BrandRollsCreate}
                //     edit={BrandRollsEdit}
                // />,
                //
                // <Resource
                //     name="liteRolls"
                //     options={{ label: 'Lite роли' }}
                //     list={LiteRollsList}
                //     create={LiteRollsCreate}
                //     edit={LiteRollsEdit}
                // />,

                // <Resource
                //     name="sets"
                //     options={{ label: 'СЕТИ' }}
                //     list={SetsList}
                //     create={SetsCreate}
                //     edit={SetsEdit}
                // />,
                //
                // <Resource
                //     name="liteSets"
                //     options={{ label: 'Lite СЕТИ' }}
                //     list={LiteSetsList}
                //     create={LiteSetsCreate}
                //     edit={LiteSetsEdit}
                // />,

            <Resource name="users" options={{ label: 'Замовлення' }}
                      list={UserList}
                      edit={UserEdit}/>,

            <Resource
                name="pizzaerias"
                options={{ label: 'Піцерії' }}
                list={PizzariaList}
                create={PizzeriaCreate}
                edit={PizzeriaEdit}/>,

            <Resource
                name="orders"
                options={{ label: 'Замовлення' }}
                list={OrdersList}
            />
            ]}

        </Admin>
    )
}