import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import Button from '@material-ui/core/Button';
import pizzaDefault from '../../../images/pizza.png'
import {Skeleton} from "@material-ui/lab";
import {Box, Grid, Typography} from "@material-ui/core";

import {useDocumentData} from "react-firebase-hooks/firestore";
import firebase from 'firebase/app';
import DefaultCard from "./defaultCard";


const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '25rem',
        maxHeight: '600px',
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        flexGrow: 1,
        position: 'relative'

    },

    cardBody: {
        minHeight: '27rem'

    },


    media: {
        height: '60%',
        width: '70%',
        // paddingTop: '5%', // 16:9
        margin: '1% auto',
        '&:hover': {
            transform: 'scale(1.06)'
        }
    },

    toBox: {
        borderRadius: '2rem',
        margin: '0.5rem'
    },
    pricePizza: {
        fontWeight: '700',
        fontFamily: 'Comfortaa',
        fontSize: '2rem'
    },

    marginCard: {
        margin: '1% auto'
    },
    ingr: {
        marginLeft: '16px'
    },

}));

export default function SaucesCard(props) {
    const classes = useStyles();

    const [sauce, loading, error] = useDocumentData(
        firebase.firestore().doc('sauses/' + props.id),
        {
            snapshotListenOptions: {includeMetadataChanges: true},
        }
    );

    let price = 0

    let place = localStorage.getItem('place')

    if (!loading && place === '2') {
        price = sauce.price_
    } else if (!loading) {
        price = sauce.price
    }

    let addedProduct

    if (!loading && sauce) {
        addedProduct = {
            category: 'Соуси',
            options: {
                deletedProducts: [],
                addedProducts: []
            },
            capacity: '',
            name: sauce.name,
            imageURL: sauce.pictures.src,
            price: price,
            count: 1
        }
    } else {
        addedProduct = null
    }


    return (

        <Card className={classes.root} elevation={0}>
            {error && <strong>Error: {JSON.stringify(error)}</strong>}
            {loading && (<DefaultCard/>)}

            <Box
                position="absolute"
                top={0}
                right={0}
                zIndex="tooltip">
            </Box>
            <Box displaay='flex'
                 flexDirection="column">

                <CardActionArea className={classes.cardBody}>


                    <CardMedia
                        className={classes.media}
                        component="img"
                        alt="Snack"
                        image={!loading && sauce ? sauce.pictures.src : pizzaDefault}/>
                    <Typography variant='h6' color="secondary" align='center' className={classes.pricePizza}>
                        {!loading && sauce ? sauce.name : <Skeleton height='1rem'/>}
                    </Typography>
                    <Typography variant='body1' color="textSecondary" component="p" className={classes.ingr}>
                        {!loading && sauce ? sauce.info : <Skeleton height='1rem'/>}
                    </Typography>
                </CardActionArea>


                <CardActions className={classes.cardAction}>

                    <Grid container spacing={1}
                          direction="row"
                          justify="space-between"
                          alignItems="center"
                          className={classes.marginCard}
                          style={{padding: 0}}>

                        <Grid item>
                            <Typography variant='h3' color='textPrimary' className={classes.pricePizza}>
                                {!loading && sauce ?
                                    price + '.00'
                                    : <Skeleton/>
                                }
                            </Typography>
                        </Grid>


                        <Grid item style={{padding: 0}}>
                            <Button variant="outlined" color="secondary" disableElevation size='large'
                                    className={classes.toBox}
                                    onClick={() => {
                                        props.addSaucesToBox(addedProduct)
                                    }}>
                                ЗАМОВИТИ
                            </Button>


                        </Grid>


                    </Grid>


                </CardActions>


            </Box>
        </Card>
    );

}