import * as React from "react";
import {
    Create, useEditController, EditContextProvider,
    BooleanInput, BooleanField, DateInput,
    ImageInput, SimpleForm, List, TextInput,
    Datagrid, TextField, DateField, ImageField, RadioButtonGroupInput
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import {cloneElement} from "react";


const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
});


const Location = (props) =>
    (
        <RadioButtonGroupInput source={props.sourse}
                               defaultValue={props.defaultValue}
                               row={false}
                               choices={[
                                   {id: '0', name: 'Діятиме скізь'},
                                   {id: '1', name: 'Не діє в Житомирі'},
                                   {id: '2', name: 'Не діє в Києві'}
                               ]}/>
    )


export const StockList = (props) => (
    <List {...props} title="АКЦІЇ">
            <Datagrid rowClick="edit">
                <TextField source="name" label='Назва акції'/>
                <TextField source="location" label='Регіон'/>
                <DateField source="createdata" label='початок дії'/>
                <BooleanField source="isActive" label='Актина акція?' />
                <ImageField source="pictures.src" title="pictures.title" label='Візуалізація'>
                    <div>
                        <img width='400px' src="pictures.src" alt='title' />
                    </div>
                </ImageField>
                <TextField source="info" label='Опис'/>
            </Datagrid>
    </List>
);

export const StockCreate = (props) => {
    const classes = useStyles();
    return (
    <Create {...props} title="Створити акцію">
        <SimpleForm>
            <TextInput source='name' label='Назва Акції'
                       formClassName={classes.inlineBlock}/>
            <BooleanInput label="Акція активна?" source="isActive"
                          formClassName={classes.inlineBlock}/>

            <Location sourse='location' defaultValue='none'/>

            <DateInput source="createdata" formClassName={classes.inlineBlock}/>
            <ImageInput source="pictures" label="Візуалізація акції" accept="image/*">

                <ImageField source="src" title="title"/>
            </ImageInput>


            <TextInput source='info' options={{ multiLine: true }}
                       label='Опис Акції' fullWidth/>
        </SimpleForm>
    </Create>)}


//================================



//============

const MyEdit = props => {
    const controllerProps = useEditController(props);
    const {
        basePath, // deduced from the location, useful for action buttons
        // defaultTitle, // the translated title based on the resource, e.g. 'Post #123'
        // loaded, // boolean that is false until the record is available
        // loading, // boolean that is true on mount, and false once the record was fetched
        record, // record fetched via dataProvider.getOne() based on the id from the location
        redirect, // the default redirection route. Defaults to 'list'
        resource, // the resource name, deduced from the location. e.g. 'posts'
        save, // the update callback, to be passed to the underlying form as submit handler
        saving, // boolean that becomes true when the dataProvider is called to update the record
        version, // integer used by the refresh feature
    } = controllerProps;

    return (
        <EditContextProvider value={controllerProps}>
            <div>

                {cloneElement (props.children, {
                    basePath,
                    record,
                    redirect,
                    resource,
                    save,
                    saving,
                    version,
                })

                }
            </div>
        </EditContextProvider>
    );

}


export const StockEdit = (props) => {
    const classes = useStyles();
    return (<MyEdit title='Редагування'  {...props} undoable={false}>
            <SimpleForm>
            <TextInput disabled source="id" />
            <TextInput source='name' label='Назва Акції'
                       formClassName={classes.inlineBlock}/>
                <Location sourse='location' defaultValue='none'/>
            <BooleanInput label="Акція активна?" source="isActive"
                          formClassName={classes.inlineBlock}/>
            <DateInput source="createdata" formClassName={classes.inlineBlock}/>
            <ImageInput source="pictures" label="Візуалізація акції" accept="image/*">

                <ImageField source="src" title="title"
                            formClassName={classes.imageInput}/>
            </ImageInput>


            <TextInput source='info'
                       label='Опис Акції' fullWidth/>
        </SimpleForm>
    </MyEdit>
);
}


