


const firebaseConfig =
    {
        apiKey: "AIzaSyBH-dBTbcR567_XTLRGDgYP_Td-DUr0Hb4",
        authDomain: "milano-pizza-3f24d.firebaseapp.com",
        databaseURL: "https://milano-pizza-3f24d-default-rtdb.europe-west1.firebasedatabase.app",
        projectId: "milano-pizza-3f24d",
        storageBucket: "milano-pizza-3f24d.appspot.com",
        messagingSenderId: "277194633270",
        appId: "1:277194633270:web:09e4822367b7600f9c6897",
        measurementId: "G-F07400D2KW"
    }


export default firebaseConfig


