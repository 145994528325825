import * as React from "react";
import {
    Create, useEditController, EditContextProvider,
    NumberField, ImageInput, SimpleForm, List, TextInput, NumberInput,
    Datagrid, TextField, ImageField, ArrayInput, SimpleFormIterator,
    ArrayField, RadioButtonGroupInput
} from 'react-admin';

import AddBoxIcon from '@material-ui/icons/AddBox';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import {makeStyles} from '@material-ui/core/styles';
import {cloneElement} from "react";


const useStyles = makeStyles({
    inlineBlock: {display: 'inline-flex', marginRight: '1rem'},
});

const Promotion = (props) => {
    return (
        <RadioButtonGroupInput source={props.sourse}
                               defaultValue={props.defaultValue}
                               row={false}
                               choices={[
                                   {id: 'none', name: 'Жодної категорії'},
                                   {id: 'top', name: 'Популярно'},
                                   {id: 'new', name: 'Новинка'},
                                   {id: 'week', name: 'Піца тиждня'},
                               ]}/>
    )
}

export const CombosList = props => (
    <List {...props} title='COMBO-меню'>
        <Datagrid rowClick="edit">
            <ImageField source="pictures.src" title="pictures.title" label='Малюнок'>
                <div>
                    <img width='100px' height='100px' src="pictures.src" alt='pictures.title'/>
                </div>
            </ImageField>
            <TextField source="promotion" label='Акція'/>
            <TextField source="name" label='Назва'/>
            <TextField source="info" label='Опис'/>
            <ArrayField source='possibleOptions' label='Доступні варіанти КОМБО'>
                <Datagrid>
                    <TextField source="size" label='Розмір'/>
                    <NumberField source="price" label='Ціна, грн'/>
                    <NumberField source="price_" label='Ціна +, грн'/>
                </Datagrid>
            </ArrayField>

        </Datagrid>
    </List>
);


export const CombosCreate = (props) => {
    const classes = useStyles();
    return (
        <Create {...props} title='Додати с'>
            <SimpleForm>
                <ImageInput source="pictures" label="Зображення" accept="image/*">

                    <ImageField source="src" title="title"/>
                </ImageInput>

                <TextInput source='name' label='Назва КОМБО'
                           formClassName={classes.inlineBlock}/>
                <Promotion sourse='promotion' defaultValue='none'/>
                <TextInput source='info' label='Опис' fullWidth={true} multiline/>



                <ArrayInput source="possibleOptions" label='Доступні варіанти продукту'>
                    <SimpleFormIterator addButton={<AddBoxIcon />} removeButton={<HighlightOffIcon />}>
                        <TextInput source='size' label='Розмір'
                                   formClassName={classes.inlineBlock}/>
                        <NumberInput source="price" label='Ціна '
                                     formClassName={classes.inlineBlock}/>
                        <NumberInput source="price_" label='Ціна +'
                                     formClassName={classes.inlineBlock}/>
                    </SimpleFormIterator>
                </ArrayInput>


            </SimpleForm>
        </Create>)
}

//============

const MyEdit = props => {
    const controllerProps = useEditController(props);
    const {
        basePath, record, redirect, resource, save,
        saving, version
    } = controllerProps;

    return (
        <EditContextProvider value={controllerProps}>
            <div>

                {cloneElement(props.children, {
                    basePath, record, redirect, resource,
                    save, saving, version,
                })
                }
            </div>
        </EditContextProvider>
    );

}


export const CombosEdit = (props) => {
    const classes = useStyles();
    return (<MyEdit title='Редагування'  {...props} undoable={false}>
            <SimpleForm>
                <TextInput disabled source="id"/>

                <ImageInput source="pictures" label="Зображення" accept="image/*">

                    <ImageField source="src" title="title"/>
                </ImageInput>
                <TextInput source='name' label='Назва КОМБО'
                           formClassName={classes.inlineBlock}/>
                <Promotion sourse='promotion' defaultValue='none'/>

                <TextInput source='info' label='Опис' fullWidth={true} multiline/>

                <ArrayInput source="possibleOptions" label='Доступні варіанти продукту'>
                    <SimpleFormIterator addButton={<AddBoxIcon />} removeButton={<HighlightOffIcon />}>
                        <TextInput source='size' label='Розмір'
                                   formClassName={classes.inlineBlock}/>
                        <NumberInput source="price" label='Ціна'
                                     formClassName={classes.inlineBlock}/>
                        <NumberInput source="price_" label='Ціна +'
                                     formClassName={classes.inlineBlock}/>
                    </SimpleFormIterator>
                </ArrayInput>

            </SimpleForm>
        </MyEdit>
    );
}