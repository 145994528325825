import React from "react";

import CssBaseline from '@material-ui/core/CssBaseline'
import ListSubheader from '@material-ui/core/ListSubheader'

import Header from "../../header/header";
import {Box} from "@material-ui/core";
import NavBar from "../../NavBar/NavBar";

import "firebase/firestore";


import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import Pizzas from "./pizzas";
import Burgers from "./Burgers";
import Combos from "./Combos";
import StocksCarousel from "../../StocksCarousel/Stockscarousel";
import Snacks from "./Snacks";
import Sauses from "./Sauces";
import Drinks from "./Drinks";
import Footer from "../../footer/footer";
// import {useDocumentData} from "react-firebase-hooks/firestore";
// import firebase from 'firebase/app';
// import BigRolls from "./Sushi/BigRolls";
// import MakiRolls from "./Sushi/MakiRolls";
// import CheeseRolls from "./Sushi/CheeseRolls";
// import BrandRolls from "./Sushi/BrandRolls";
// import LiteRolls from "./Sushi/LiteRolls";
// import Sets from "./Sushi/Sets";
// import LiteSets from "./Sushi/LiteSets";
// import ChooseCity from "./ChooseCity";
// import {act} from "@testing-library/react";



const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#4c8c4a',
            main: '#1b5e20',
            dark: '#003300',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ff867f',
            main: '#ff5252',
            dark: '#c50e29',
            contrastText: '#000',
        },
    },

});

const App = () => {

    //===============================
    const [pizzaBox, setPizzaBox] = React.useState({
        products: [],
        totalCount: 0,
        totalPrice: 0,
    })


    // const [seeSushi, setSeeSushi] = React.useState( false)

    const [location, setLoc] = React.useState('')

    const setLocation = (loc) => {

        setLoc(loc)
    }

    // if(location === ''){
    // setLoc(localStorage.getItem('place'))
    // }

    // localStorage.plase ? setLocation(1) : setLocation(0)


    const [city, setCity] = React.useState({
        name: 'Звягель',
        phoneNumber: '063-866-70-47',
        schedule: 'з 10.00 до 20.00'
    });
    //




    const addProductToBox = (product) => {
        const productIndex = pizzaBox.products.findIndex(
            ({name, category, capacity, options}) => name === product.name
                & category === product.category
                & capacity === product.capacity
                // & options === product.options
        )
        const productItem = pizzaBox.products[productIndex]

        let newProduct

        if (productItem) {
            newProduct = {
                ...productItem,
                count: productItem.count + 1,
                price: productItem.price
            }
        } else {
            newProduct = {
                category: product.category,
                capacity: product.capacity || '',
                options: product.options,
                name: product.name,
                imageURL: product.imageURL,
                price: product.price,
                count: 1
            }
        }
        if (productIndex < 0) {
            setPizzaBox({
                ...pizzaBox,
                products: [...pizzaBox.products, newProduct],
                totalCount: pizzaBox.totalCount + product.count,
                totalPrice: pizzaBox.totalPrice + product.price
            })
        } else {
            setPizzaBox({
                ...pizzaBox,
                products: [...pizzaBox.products.slice(0, productIndex),
                    newProduct,
                    ...pizzaBox.products.slice(productIndex + 1)
                ],
                totalCount: pizzaBox.totalCount + product.count,
                totalPrice: pizzaBox.totalPrice + product.price
            })
        }

    }

    //Add 1 Item to PizzaBox
    const incrProductCount = (index) => {
        const IncrProduct = pizzaBox.products[index]
        let newProduct = {
            ...IncrProduct,
            count: IncrProduct.count + 1,
            price: IncrProduct.price
        }
        setPizzaBox({
            ...pizzaBox,
            products: [...pizzaBox.products.slice(0, index),
                newProduct,
                ...pizzaBox.products.slice(index + 1)
            ],
            totalCount: pizzaBox.totalCount + 1,
            totalPrice: pizzaBox.totalPrice + IncrProduct.price
        })
    }

    //Delete Product from PizzaBox
    const delProduct = (index) => {
        setPizzaBox({
            ...pizzaBox,
            totalCount: pizzaBox.totalCount - pizzaBox.products[index].count,
            totalPrice: pizzaBox.totalPrice - (pizzaBox.products[index].count * pizzaBox.products[index].price),
            products: [...pizzaBox.products.slice(0, index),
                ...pizzaBox.products.slice(index + 1)
            ],
        })
    }

    // Remove 1 Item from Basket
    const decrProductCount = (index) => {
        const DecrProduct = pizzaBox.products[index]



        let newProduct = {
            ...DecrProduct,
            count: DecrProduct.count - 1,
            price: DecrProduct.price
        }
        setPizzaBox({
            ...pizzaBox,
            products: [...pizzaBox.products.slice(0, index),
                newProduct,
                ...pizzaBox.products.slice(index + 1)
            ],
            totalCount: pizzaBox.totalCount - 1,
            totalPrice: pizzaBox.totalPrice - DecrProduct.price
        })

        if (newProduct.count === 0) {
            delProduct(index)
        }
    }

    // === Get WeekDay ===================================================

    // function getWeekDay(date) {
    //     let days = ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'];
    //     return days[date.getDay()];
    // }
    // let date = new Date();
    // let WeekDay = getWeekDay(date)

    //=== End Get WeekDay =================================================


    // //===========Action -50% to second продуукт ================
    // useEffect(() => {
    //
    //     let CopyPizzaBox = pizzaBox
    //
    //     let getGiftArr = CopyPizzaBox.products.filter(function (prodPizza) {
    //         return prodPizza.category === 'Піца Акція 50% на другу'
    //     })
    //     let CountGifts
    //     CountGifts = getGiftArr.reduce((totalGifts, {count}) => totalGifts += count, 0)
    //
    //
    //
    //         for (let i = CountGifts; i > 0; i = i - 1) {
    //             let actionIndex = CopyPizzaBox.products.findIndex(
    //                 ({category}) => category === 'Піца Акція 50% на другу')
    //             //+++++++++++++++++++++++++++++++++++++++++++
    //             let changeProduct = CopyPizzaBox.products[actionIndex]
    //
    //
    //             //++++++++++++++++++++++++++++++++++++++++++++
    //             let facts_Price
    //             let addedsSum = 0
    //
    //             if (changeProduct.options.addedProducts.length > 0) {
    //                 addedsSum = changeProduct.options.addedProducts.reduce(function(sum, elem) {
    //                     return sum + elem.price;
    //                 }, 0);
    //
    //                 console.log(addedsSum)
    //                 console.log(changeProduct.price)
    //
    //                 facts_Price =  changeProduct.price - addedsSum
    //             } else {
    //                 facts_Price  = changeProduct.price
    //             }
    //             //++++++++++++++++++++++++++++++++++++++++++++
    //
    //
    //
    //             let replacePizza = {
    //                 category: 'Піца',
    //                 options: {
    //                     deletedProducts: changeProduct.options.deletedProducts,
    //                     addedProducts: changeProduct.options.addedProducts
    //                 },
    //                 capacity: changeProduct.capacity,
    //                 name: changeProduct.name,
    //                 imageURL: changeProduct.imageURL,
    //                 price: (facts_Price * 2) + addedsSum,
    //                 count: changeProduct.count
    //             }
    //
    //             const dubleProductIndex = CopyPizzaBox.products.findIndex(
    //                 ({name, category, options}) => name === changeProduct.name
    //                     & category === 'Піца'
    //                     & options === changeProduct.options
    //             )
    //             //
    //             console.log(facts_Price)
    //             console.log(dubleProductIndex)
    //
    //             if (dubleProductIndex < 0) {
    //                 CopyPizzaBox.products.splice(actionIndex, 1, replacePizza)
    //                 CopyPizzaBox.totalPrice = CopyPizzaBox.totalPrice + (facts_Price )
    //             } else {
    //                 CopyPizzaBox.products[dubleProductIndex].count = CopyPizzaBox.products[dubleProductIndex].count + 1
    //                 CopyPizzaBox.products.splice(actionIndex, 1)
    //                 CopyPizzaBox.totalPrice = CopyPizzaBox.totalPrice + (facts_Price )
    //             }
    //         }
    //         //    ======================================================================
    //
    //     if (WeekDay === 'ВТ' || WeekDay === 'СР' || WeekDay === 'ПН' || WeekDay === 'ЧТ') {
    //         let getPizzaArr = CopyPizzaBox.products.filter(function (prodPizza) {
    //             return prodPizza.category === 'Піца'
    //         })
    //         let CountPizza = getPizzaArr.reduce((totalPizza, {count}) => totalPizza += count, 0)
    //
    //         getGiftArr = CopyPizzaBox.products.filter(function (prodPizza) {
    //             return prodPizza.category === 'Піца Акція 50% на другу'
    //         })
    //         CountGifts = getGiftArr.reduce((totalGifts, {count}) => totalGifts += count, 0)
    //
    //         let AllPizza = CountPizza + CountGifts
    //
    //         for (let CountAction = 1; CountAction <= Math.floor(AllPizza / 2); CountAction++) {
    //
    //             let onlyPizza = CopyPizzaBox.products.filter(function (prodPizza) {
    //                 return prodPizza.category === 'Піца'
    //             })
    //
    //             let minPrice = onlyPizza.reduce((prev, curr) => prev.price < curr.price ? prev : curr)
    //
    //             let fact_Price = minPrice.price
    //             let addedSum = 0
    //
    //             if (minPrice.options.addedProducts.length > 0) {
    //                 addedSum = minPrice.options.addedProducts.reduce(function(sum, elem) {
    //                     return sum + elem.price;
    //                 }, 0);
    //
    //                 fact_Price =  minPrice.price - addedSum
    //             }
    //
    //
    //
    //             let addedActionPizza = {
    //                 category: 'Піца Акція 50% на другу',
    //                 options: {
    //                     deletedProducts: minPrice.options.deletedProducts,
    //                     addedProducts: minPrice.options.addedProducts,
    //                 },
    //                 capacity: '',
    //                 name: minPrice.name,
    //                 imageURL: minPrice.imageURL,
    //                 price: (fact_Price / 2) + addedSum,
    //                 count: 1
    //             }
    //
    //
    //
    //             let actionIndex = CopyPizzaBox.products.findIndex(
    //                 ({name, category, options}) =>
    //                     name === minPrice.name
    //                     & category === minPrice.category
    //                     )
    //
    //             const DecrProduct = CopyPizzaBox.products[actionIndex]
    //
    //             let newProduct = {
    //                 ...DecrProduct,
    //                 count: DecrProduct.count - 1,
    //                 price: DecrProduct.price
    //             }
    //
    //             if (minPrice.count !== 1) {
    //                 CopyPizzaBox.products.splice(actionIndex, 1, newProduct)
    //                 CopyPizzaBox.products.push(addedActionPizza)
    //                 CopyPizzaBox.totalPrice = CopyPizzaBox.totalPrice - fact_Price/2
    //             }
    //
    //             if (minPrice.count === 1) {
    //                 CopyPizzaBox.products.splice(actionIndex, 1, addedActionPizza)
    //                 CopyPizzaBox.totalPrice = CopyPizzaBox.totalPrice - fact_Price/2
    //             }
    //         }
    //         return setPizzaBox({
    //             ...pizzaBox,
    //             products: CopyPizzaBox.products,
    //             totalPrice: CopyPizzaBox.totalPrice
    //         })
    //     }
    //     //    ======================================================================
    // }, [pizzaBox.totalCount])


    //===========Action -50% to second Sushi РОЛ ================
    // useEffect(() => {
    //
    //     let CopyPizzaBox = pizzaBox
    //
    //     let getGiftArr = CopyPizzaBox.products.filter(function (prodPizza) {
    //         return prodPizza.category === 'Суші рол Акція 50% на другий'
    //     })
    //     let CountGifts
    //     CountGifts = getGiftArr.reduce((totalGifts, {count}) => totalGifts += count, 0)
    //
    //
    //
    //         for (let i = CountGifts; i > 0; i = i - 1) {
    //             let actionIndex = CopyPizzaBox.products.findIndex(
    //                 ({category}) => category === 'Суші рол Акція 50% на другий')
    //             //+++++++++++++++++++++++++++++++++++++++++++
    //             let changeProduct = CopyPizzaBox.products[actionIndex]
    //
    //             let replacePizza = {
    //                 category: 'Суші роли',
    //                 options: {
    //                     deletedProducts: [],
    //                     addedProducts: []
    //                 },
    //                 capacity: '',
    //                 name: changeProduct.name,
    //                 imageURL: changeProduct.imageURL,
    //                 price: changeProduct.price * 2,
    //                 count: changeProduct.count
    //             }
    //
    //             const dubleProductIndex = CopyPizzaBox.products.findIndex(
    //                 ({name, category, capacity}) => name === changeProduct.name
    //                     & category === 'Суші роли'
    //                     & capacity === changeProduct.capacity
    //             )
    //
    //             if (dubleProductIndex < 0) {
    //                 CopyPizzaBox.products.splice(actionIndex, 1, replacePizza)
    //                 CopyPizzaBox.totalPrice = CopyPizzaBox.totalPrice + replacePizza.price / 2
    //             } else {
    //                 CopyPizzaBox.products[dubleProductIndex].count = CopyPizzaBox.products[dubleProductIndex].count + 1
    //                 CopyPizzaBox.products.splice(actionIndex, 1)
    //                 CopyPizzaBox.totalPrice = CopyPizzaBox.totalPrice + replacePizza.price / 2
    //             }
    //         }
    //         //    ======================================================================
    //
    //     // if (WeekDay === 'ВТ' || WeekDay === 'СР' || WeekDay === 'ПН' || WeekDay === 'ЧТ' ||  WeekDay === 'ПТ') {
    //     //     let getPizzaArr = CopyPizzaBox.products.filter(function (prodPizza) {
    //     //         return prodPizza.category === 'Суші роли'
    //     //     })
    //     //     let CountPizza = getPizzaArr.reduce((totalPizza, {count}) => totalPizza += count, 0)
    //     //
    //     //     getGiftArr = CopyPizzaBox.products.filter(function (prodPizza) {
    //     //         return prodPizza.category === 'Суші рол Акція 50% на другий'
    //     //     })
    //     //     CountGifts = getGiftArr.reduce((totalGifts, {count}) => totalGifts += count, 0)
    //     //
    //     //     let AllPizza = CountPizza + CountGifts
    //     //
    //     //     for (let CountAction = 1; CountAction <= Math.floor(AllPizza / 2); CountAction++) {
    //     //
    //     //         let onlyPizza = CopyPizzaBox.products.filter(function (prodPizza) {
    //     //             return prodPizza.category === 'Суші роли'
    //     //         })
    //     //
    //     //         let minPrice = onlyPizza.reduce((prev, curr) => prev.price < curr.price ? prev : curr)
    //     //
    //     //         let addedActionPizza = {
    //     //             category: 'Суші рол Акція 50% на другий',
    //     //             options: {
    //     //                 deletedProducts: [],
    //     //                 addedProducts: []
    //     //             },
    //     //             capacity: '',
    //     //             name: minPrice.name,
    //     //             imageURL: minPrice.imageURL,
    //     //             price: minPrice.price / 2,
    //     //             count: 1
    //     //         }
    //     //
    //     //         let actionIndex = CopyPizzaBox.products.findIndex(
    //     //             ({name, category}) => name === minPrice.name & category === minPrice.category)
    //     //
    //     //         const DecrProduct = CopyPizzaBox.products[actionIndex]
    //     //
    //     //         let newProduct = {
    //     //             ...DecrProduct,
    //     //             count: DecrProduct.count - 1,
    //     //             price: DecrProduct.price
    //     //         }
    //     //
    //     //         if (minPrice.count !== 1) {
    //     //             CopyPizzaBox.products.splice(actionIndex, 1, newProduct)
    //     //             CopyPizzaBox.products.push(addedActionPizza)
    //     //             CopyPizzaBox.totalPrice = CopyPizzaBox.totalPrice - addedActionPizza.price
    //     //         }
    //     //
    //     //         if (minPrice.count === 1) {
    //     //             CopyPizzaBox.products.splice(actionIndex, 1, addedActionPizza)
    //     //             CopyPizzaBox.totalPrice = CopyPizzaBox.totalPrice - addedActionPizza.price
    //     //         }
    //     //     }
    //     //     return setPizzaBox({
    //     //         ...pizzaBox,
    //     //         products: CopyPizzaBox.products,
    //     //         totalPrice: CopyPizzaBox.totalPrice
    //     //     })
    //     // }
    //     //    ======================================================================
    // }, [pizzaBox.totalCount])
    //
    //
    //
    // //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    // //===========Action -50% to second Sushi СЕТ  ================
    // // useEffect(() => {
    // //
    // //     let CopyPizzaBox = pizzaBox
    // //
    // //     let getGiftArr = CopyPizzaBox.products.filter(function (prodPizza) {
    // //         return prodPizza.category === 'Суші сет Акція 50% на другий'
    // //     })
    // //     let CountGifts
    // //     CountGifts = getGiftArr.reduce((totalGifts, {count}) => totalGifts += count, 0)
    // //
    // //         for (let i = CountGifts; i > 0; i = i - 1) {
    // //             let actionIndex = CopyPizzaBox.products.findIndex(
    // //                 ({category}) => category === 'Суші сет Акція 50% на другий')
    // //             //+++++++++++++++++++++++++++++++++++++++++++
    // //             let changeProduct = CopyPizzaBox.products[actionIndex]
    // //
    // //             let replacePizza = {
    // //                 category: 'Суші сет',
    // //                 options: {
    // //                     deletedProducts: [],
    // //                     addedProducts: []
    // //                 },
    // //                 capacity: '',
    // //                 name: changeProduct.name,
    // //                 imageURL: changeProduct.imageURL,
    // //                 price: changeProduct.price * 2,
    // //                 count: changeProduct.count
    // //             }
    // //
    // //             const dubleProductIndex = CopyPizzaBox.products.findIndex(
    // //                 ({name, category, capacity}) => name === changeProduct.name
    // //                     & category === 'Суші сет'
    // //                     & capacity === changeProduct.capacity
    // //             )
    // //
    // //             if (dubleProductIndex < 0) {
    // //                 CopyPizzaBox.products.splice(actionIndex, 1, replacePizza)
    // //                 CopyPizzaBox.totalPrice = CopyPizzaBox.totalPrice + replacePizza.price / 2
    // //             } else {
    // //                 CopyPizzaBox.products[dubleProductIndex].count = CopyPizzaBox.products[dubleProductIndex].count + 1
    // //                 CopyPizzaBox.products.splice(actionIndex, 1)
    // //                 CopyPizzaBox.totalPrice = CopyPizzaBox.totalPrice + replacePizza.price / 2
    // //             }
    // //         }
    //         //    ======================================================================
    //
    //     // if (WeekDay === 'ВТ' || WeekDay === 'СР' || WeekDay === 'ПН' || WeekDay === 'ЧТ' ||  WeekDay === 'ПТ') {
    //     //     let getPizzaArr = CopyPizzaBox.products.filter(function (prodPizza) {
    //     //         return prodPizza.category === 'Суші сет'
    //     //     })
    //     //     let CountPizza = getPizzaArr.reduce((totalPizza, {count}) => totalPizza += count, 0)
    //     //
    //     //     getGiftArr = CopyPizzaBox.products.filter(function (prodPizza) {
    //     //         return prodPizza.category === 'Суші сет Акція 50% на другий'
    //     //     })
    //     //     CountGifts = getGiftArr.reduce((totalGifts, {count}) => totalGifts += count, 0)
    //     //
    //     //     let AllPizza = CountPizza + CountGifts
    //     //
    //     //     for (let CountAction = 1; CountAction <= Math.floor(AllPizza / 2); CountAction++) {
    //     //
    //     //         let onlyPizza = CopyPizzaBox.products.filter(function (prodPizza) {
    //     //             return prodPizza.category === 'Суші сет'
    //     //         })
    //     //
    //     //         let minPrice = onlyPizza.reduce((prev, curr) => prev.price < curr.price ? prev : curr)
    //     //
    //     //         let addedActionPizza = {
    //     //             category: 'Суші сет Акція 50% на другий',
    //     //             options: {
    //     //                 deletedProducts: [],
    //     //                 addedProducts: []
    //     //             },
    //     //             capacity: '',
    //     //             name: minPrice.name,
    //     //             imageURL: minPrice.imageURL,
    //     //             price: minPrice.price / 2,
    //     //             count: 1
    //     //         }
    //     //
    //     //         let actionIndex = CopyPizzaBox.products.findIndex(
    //     //             ({name, category}) => name === minPrice.name & category === minPrice.category)
    //     //
    //     //         const DecrProduct = CopyPizzaBox.products[actionIndex]
    //     //
    //     //         let newProduct = {
    //     //             ...DecrProduct,
    //     //             count: DecrProduct.count - 1,
    //     //             price: DecrProduct.price
    //     //         }
    //     //
    //     //         if (minPrice.count !== 1) {
    //     //             CopyPizzaBox.products.splice(actionIndex, 1, newProduct)
    //     //             CopyPizzaBox.products.push(addedActionPizza)
    //     //             CopyPizzaBox.totalPrice = CopyPizzaBox.totalPrice - addedActionPizza.price
    //     //         }
    //     //
    //     //         if (minPrice.count === 1) {
    //     //             CopyPizzaBox.products.splice(actionIndex, 1, addedActionPizza)
    //     //             CopyPizzaBox.totalPrice = CopyPizzaBox.totalPrice - addedActionPizza.price
    //     //         }
    //     //     }
    //     //     return setPizzaBox({
    //     //         ...pizzaBox,
    //     //         products: CopyPizzaBox.products,
    //     //         totalPrice: CopyPizzaBox.totalPrice
    //     //     })
    //     // }
    //     //    ======================================================================
    // }, [pizzaBox.totalCount])















    //=========ACTION + every second sale 50% + ===============================


    // const [actionPizza, setActionPizza] = React.useState( false)



    // === end Action +MislivskaPIZZA =================================================



    // // === Action +Cola == (canceled) =================================================
    //
    // const [set_WMaki, loading, error] = useDocumentData(
    //     firebase.firestore().doc('sets/2aqN6S0sOiUVYVvCBdoR'),
    //     {
    //         snapshotListenOptions: {includeMetadataChanges: true},
    //     }
    // );
    //
    //
    // let addedGiftSet
    //
    // if (!loading && set_WMaki) {
    //     addedGiftSet = {
    //         category: 'Акція + Всякі макі',
    //         options: {
    //             deletedProducts: [],
    //             addedProducts: []
    //         },
    //         capacity: set_WMaki.weight,
    //         name: set_WMaki.name,
    //         imageURL: set_WMaki.pictures.src,
    //         price: 0,
    //         count: 1
    //     }
    // } else {
    //     addedGiftSet = null
    // }
    //
    //
    // useEffect(() => {
    //     const getSetActionArr = pizzaBox.products.filter(function (prodPizza) {
    //         return prodPizza.name === ' Риба до верху' || prodPizza.name === 'BIG сет' ||
    //             prodPizza.name === 'Історія дракона'
    //
    //     })
    //
    //     const CountSetsAction = getSetActionArr.reduce((totalPizza, {count}) => totalPizza += count, 0)
    //
    //
    //     const getGiftArr = pizzaBox.products.filter(function (prodPizza) {
    //         return prodPizza.category === 'Акція + Всякі макі'
    //     })
    //     const CountGifts = getGiftArr.reduce((totalGifts, {count}) => totalGifts += count, 0)
    //
    // //
    //     if (WeekDay === 'ВТ' || WeekDay === 'СР' || WeekDay === 'ПН' || WeekDay === 'ЧТ' ) {
    //
    //         if (CountSetsAction !== CountGifts && CountGifts === CountSetsAction - 1) {
    //             addProductToBox(addedGiftSet)
    //         }
    //
    //         if (CountSetsAction !== CountGifts && CountGifts === CountSetsAction + 1) {
    //             addProductToBox(addedGiftSet)
    //             const giftIndex = pizzaBox.products.findIndex(
    //                         ({name, category}) => name === addedGiftSet.name &
    //                             category === addedGiftSet.category)
    //                     decrProductCount(giftIndex)
    //         }
    //
    //     }
    // }, [pizzaBox, addedGiftSet])

    // === end Action + Set WM =================================================




    // === Action +Cola == (canceled) =================================================

    // const [cola, loading, error] = useDocumentData(
    //     firebase.firestore().doc('drinks/C2OWd4vZaW5j2lHB0hfV'),
    //     {
    //         snapshotListenOptions: {includeMetadataChanges: true},
    //     }
    // );
    //
    // let addedGiftCola
    //
    // if (!loading && cola) {
    //     addedGiftCola = {
    //         category: 'Подарунок',
    //         options: {
    //             deletedProducts: [],
    //             addedProducts: []
    //         },
    //         capacity: cola.weight,
    //         name: cola.name,
    //         imageURL: cola.pictures.src,
    //         price: 0,
    //         count: 1
    //     }
    // } else {
    //     addedGiftCola = null
    // }
    //
    //
    // useEffect(() => {
    //     const getPizzaArr = pizzaBox.products.filter(function (prodPizza) {
    //         return prodPizza.category === 'Піца'
    //     })
    //     const CountPizza = getPizzaArr.reduce((totalPizza, {count}) => totalPizza += count, 0)
    //     const getGiftArr = pizzaBox.products.filter(function (prodPizza) {
    //         return prodPizza.category === 'Подарунок'
    //     })
    //     const CountGifts = getGiftArr.reduce((totalGifts, {count}) => totalGifts += count, 0)
    //
    //     if (CountPizza === 3 && CountGifts === 0) {
    //         addProductToBox(addedGiftCola)
    //     }
    //     if (CountPizza === 5 && CountGifts === 1) {
    //         addProductToBox(addedGiftCola)
    //     }
    //     if (CountPizza <= 4 && CountGifts === 2) {
    //         const giftIndex = pizzaBox.products.findIndex(
    //             ({name, category}) => name === addedGiftCola.name &
    //                 category === addedGiftCola.category)
    //         decrProductCount(giftIndex)
    //     }
    //     if (CountPizza <= 2 && CountGifts === 1) {
    //         const giftIndex = pizzaBox.products.findIndex(
    //             ({name, category}) => name === addedGiftCola.name &
    //                 category === addedGiftCola.category)
    //         decrProductCount(giftIndex)
    //     }
    // }, [pizzaBox, addedGiftCola])

    // === end Action +Cola == (canceled) =================================================


    //===============================


    return (
        <ThemeProvider theme={theme}>

            <CssBaseline/>
            <div>

                <Header location={location}
                        setLocation={setLocation}
                        city={city}
                        setCity={setCity}
                        setPizzaBox={setPizzaBox}
                />


                    <Box>

                        <ListSubheader disableGutters={true}>

                            <NavBar pizzaBox={pizzaBox}
                                    incrProductCount={incrProductCount}
                                    delProduct={delProduct}
                                    decrProductCount={decrProductCount}
                                    setPizzaBox={setPizzaBox}
                                    addSaucesToBox={addProductToBox}
                                    addDrinkToBox={addProductToBox}
                                    // seeSushi={seeSushi}
                                    // setSeeSushi={setSeeSushi}
                                    location={location}
                                    city={city}/>

                        </ListSubheader>
                        <StocksCarousel id='actions'
                                        // setSeeSushi={setSeeSushi}
                                        loc={location}
                        />

                        {/*{seeSushi ?*/}

                        {/*    <div>*/}
                        {/*        <Sets id='sets' addSushiToBox={addProductToBox} location={location}/>*/}
                        {/*        <LiteSets id='liteSets' addSushiToBox={addProductToBox} location={location}/>*/}
                        {/*        <BigRolls id='bigRolls' addSushiToBox={addProductToBox} location={location}/>*/}
                        {/*        <LiteRolls id='liteRolls' addSushiToBox={addProductToBox} location={location}/>*/}
                        {/*        <MakiRolls id='makiRolls' addSushiToBox={addProductToBox} location={location}/>*/}
                        {/*        <CheeseRolls id='cheeseRolls' addSushiToBox={addProductToBox} location={location}/>*/}
                        {/*        <BrandRolls id='brandRolls' addSushiToBox={addProductToBox} location={location}/>*/}
                        {/*    </div> */}
                        {/*    :*/}

                            <div>
                                <Pizzas id='pizzas' addPizzaToBox={ addProductToBox}
                                        location={location}
                                        setLocation={setLocation}/>
                                <Burgers id='burgers' addPizzaToBox={addProductToBox} location={location}/>
                                <Combos id='combos' addComboToBox={addProductToBox} location={location}/>
                                <Snacks id='snacks' addSnackToBox={addProductToBox} location={location}/>
                                <Sauses id='sauces' addSaucesToBox={addProductToBox} location={location}/>
                                {/*<DialogChooseGift openGiftDialog={openGiftDialog}*/}
                                {/*                  onClose={handleCloseGift}*/}
                                {/*                  />*/}
                            </div>
                        {/*}*/}

                        <Drinks id='drinks' addDrinkToBox={addProductToBox} location={location}/>
                        <Footer/>


                    </Box>




            </div>

        </ThemeProvider>
    );

}

export default App;
