import React, {useEffect} from "react";
import {
    Avatar,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField,
    Typography
} from "@material-ui/core";
import FaceIcon from '@material-ui/icons/Face';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import {makeStyles} from "@material-ui/core/styles";


import Link from '@material-ui/core/Link';
import * as ROUTES from '../constants/routes'
import Button from "@material-ui/core/Button";

import {useDocumentData} from 'react-firebase-hooks/firestore';
import ImageAvatar from "./Avatar";
import Grid from "@material-ui/core/Grid";

import {useFirebaseApp, useUser} from "reactfire";
import app from "firebase";
import 'firebase/firestore'



const useStyles = makeStyles((theme) => ({

    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    paper: {
        border: '1px solid',
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
    },
    large: {
        width: theme.spacing(8),
        height: theme.spacing(8),
    },
    avatar: {
        width: theme.spacing(16),
        height: theme.spacing(16),
    },
}));



const UserButton = () => {

    const user = useUser()
    const firebase = useFirebaseApp();
    const db = app.firestore()


    const uid = user.data.uid

    const [activeUser, setActiveUser] = React.useState({
        name: '',
        phone: '',
        city: '',
        address: '',
        error: '',
        photoURL: '',
    })

    const signOUT = () => {
        firebase.auth().signOut().then(() => {
            // Sign-out successful
        }).catch((error) => {
            // An error happened.
        });
    }


    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [isAdmin, setIsAdmin] = React.useState(false)
    const open = Boolean(anchorEl);




    const [currentUser, loading, error] = useDocumentData(
        firebase.firestore().doc('users/'+ uid),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );

    useEffect(()=> {
        if(currentUser){
            if(currentUser.role === 'Admin'){
                setIsAdmin(true)
            }
        }

        if(currentUser) {
            setActiveUser({
                name: currentUser.name || '',
                phone: currentUser.phoneNumber,
                city: currentUser.city || '',
                address: currentUser.address || '',
                error: '',
                photoURL: currentUser.photoURL || '',
            })
        }


    }, [currentUser])



    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleInputChange = (e) => {
       const input = e.target.name
        setActiveUser({...activeUser, [input]: e.target.value})
    }

    const handleDialogOpen = () => {
        setDialogOpen(true)
        handleClose()
    }

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    const handleSave = () => {
        db.collection('users').doc(uid).set(
            {
                name: activeUser.name,
                city: activeUser.city,
                address: activeUser.address
            }, {merge: true},)
            .then((data) => {
                console.log('запис успішний', data)
                handleDialogClose()
            }).catch((error)=> {
                console.log(error)
        })

    }




    return (
        <div>

            {currentUser && currentUser.photoURL ?
            <Avatar alt="current User" src={activeUser.photoURL} className={classes.large}  onClick={handleMenu}/>
            : <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
                size='medium'
                style={{ padding: 0}}
            >
                <FaceIcon color='primary' fontSize='large' style={{ width: '1.6em', height: '1.6em' }}/>
            </IconButton>}


            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={handleDialogOpen}>Профіль</MenuItem>
                {isAdmin && (
                <MenuItem onClick={handleClose}>
                    <Link href={ROUTES.ADMIN} underline='none' color='inherit'>Admin</Link>
                </MenuItem>)}
                <MenuItem onClick={signOUT}>Вийти</MenuItem>
            </Menu>


        {/*    =========================================*/}

            <Dialog open={dialogOpen} onClose={handleDialogClose} aria-labelledby="form-dialog-title"
                    className={classes.root}>
                {error && <strong>Error: {JSON.stringify(error)}</strong>}
                {loading && <span>Loading...</span>}
                {currentUser && (
                    <div>
                <DialogTitle id="form-dialog-title" >
                    <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="flex-start"
                    >
                    <Grid item>
                        <Typography variant="h6" className={classes.title}>Профіль </Typography>
                    </Grid>
                        <Grid item>
                            <Grid
                                container
                                direction="column"
                                justify="center"
                                alignItems="center"
                            >
                    <Avatar alt="current User"
                            src={activeUser.photoURL}
                            className={classes.avatar}
                            onChange={handleInputChange}
                    />
                            <ImageAvatar UserID={uid}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogTitle>

                <DialogContent>
                    <TextField
                        disabled={true}
                        autoFocus
                        margin="dense"
                        id="phone"
                        label="Телефон"
                        type="text"
                        fullWidth
                        defaultValue={activeUser.phone}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        name='name'
                        label="Ім'я, призвіще"
                        type="text"
                        fullWidth
                        value={activeUser.name}
                        onChange={handleInputChange}
                        // defaultValue={currentUser.name}

                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="city"
                        name='city'
                        label="Місто"
                        type="text"
                        fullWidth
                        value={activeUser.city}
                        onChange={handleInputChange}
                        isRequired={true}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="address"
                        name='address'
                        label="Адреса"
                        type="text"
                        fullWidth
                        value={activeUser.address}
                        onChange={handleInputChange}

                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="secondary">
                        Скасувати
                    </Button>
                    <Button onClick={handleSave} color="primary">
                        Запам'ятати
                    </Button>
                </DialogActions>
                </div>)}
            </Dialog>


        </div>)}

export default UserButton
