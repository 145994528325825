import React, {forwardRef} from "react";
import {
    Box,
    FormControl,
    FormControlLabel,
    MenuItem, Radio,
    RadioGroup,
    TextField,
    Typography
} from "@material-ui/core";
import SignUp from "../../SignUp/SignUp";


import { makeStyles} from '@material-ui/core/styles';
import {useCollectionData} from "react-firebase-hooks/firestore";

import fb from 'firebase/app';

//DataPicker
import DatePicker from "react-datepicker";
import setHours from 'date-fns/setHours'
import setMinutes from 'date-fns/setMinutes'
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import uk from 'date-fns/esm/locale/uk';
import Button from "@material-ui/core/Button";
registerLocale('uk', uk)


const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(3),
            width: '25ch',

        },
        backgroundColor: theme.palette.background.paper,
        flexGrow: 1,
        margin: theme.spacing(2),

    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        minWidth: '100%'
    },
    pricePizza: {
        fontWeight: '800',
        fontFamily: 'Comfortaa',
        fontSize: '1.3rem',
        padding: '8px',
        margin: '5px',
    },

    textOrder: {
        fontWeight: '800',
        fontFamily: 'Comfortaa',
        fontSize: '1rem',
        padding: '8px',
        marginBottom: '5px',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    large: {
        width: theme.spacing(8),
        height: theme.spacing(8),
    },
    avatar: {
        width: theme.spacing(16),
        height: theme.spacing(16),
    },
    formControl: {
        minWidth: '100%',
    },
    time: {
        minWidth: '100%',
        margin: '10px 0',
        padding: '5px',
    },
}));

export default function Ordering({activeUser, user, inputChange, city,
                                 choosePizzeria, pizzeria, delivery, chooseDelivery,
                                     choosesTime, chooseTime,
                                 time, putTime, choosePayMetod, payMetod, deliveryCount}) {
    const classes = useStyles();

    //========== User & Address ===========
    const handleInputChange = (e) => {
        const input = e.target.name
        const value = e.target.value
        inputChange(input, value)
    }
    //========== City ===========

    const [cities, load, err] = useCollectionData(
        fb.firestore().collection('cities'),
        {snapshotListenOptions: { includeMetadataChanges: true },},
    )
    // const handleChangeCity = (event) => {
    //     chooseCity(event.target.value);
    // };

    let LocalStorageCity = localStorage.getItem('city')

    // console.log(city)
    // console.log(LocalStorageCity)

    //========== Pizzeria`s ===========
    const [pizzaria, loading, error] = useCollectionData(
        fb.firestore().collection('pizzaerias').orderBy('name', 'asc'),
        {snapshotListenOptions: { includeMetadataChanges: true },},
    );
    const handleChangePizzaria = (event) => {
        choosePizzeria(event.target.value);
    };

    //=========== Delivery ===========
    const handleChange = (event) => {
        chooseDelivery(event.target.value);
    };

//===========Select Time ==========
    const handleChooseTime = (event) => {
        choosesTime(event.target.value);
        if (event.target.value === "00:00") {
            putTime('найближчим часом')
        }

    };
//     const currentTime = () => {
//         let Data
//         Data = new Date();
//         let hours = Data.getHours()
//         if(hours <10) {
//             hours = 11
//         } else if(hours >= 20) {
//             hours =11
//         } else {hours = hours + 1}
//
//         let minutes =  Math.round(Data.getMinutes()/10)*10
//         if(minutes === 60){
//             minutes = 59
//         }
//         return `${hours}:${minutes}`
//     }

    //===========Select Time ==========


    const [startDate, setStartDate] = React.useState(
        new Date()
    );

    const filterPassedTime = time => {
        const currentDate = new Date();
        const selectedDate = new Date(time);

        return currentDate.setHours(currentDate.getHours() + 1) < selectedDate.getTime();
    }



    //============Pay Metod ==================
    const handleChangePayMetod = (event) => {
        choosePayMetod(event.target.value);
    };

    const ExampleCustomInput = forwardRef(
        ({ value, onClick }, ref) => (
            <Button color='secondary' variant="outlined" onClick={onClick} ref={ref} disabled={chooseTime !== 'на час'}>
                {value}
            </Button>
        ),
    );


    return(
        <Box>
                <Typography variant='h3' color="secondary" align='center' className={classes.pricePizza}>
                Оформлення замовлення
                </Typography>

            {user.data === null ?
                <Box>
                    <SignUp />
                    <Typography variant='h3' color="textPrimary" align='center' className={classes.textOrder}>
                        Для оформлення замовлення авторизуйтесь, будь-ласка
                    </Typography>
                </Box>:
                <Box>
                    <form className={classes.root} noValidate={false} autoComplete="off">
                        <div>
                            <TextField
                                disabled={true}
                                margin="dense"
                                autoFocus
                                id="phone"
                                label="Телефон"
                                type="text"
                                value={activeUser.phone}
                                variant="outlined"
                                fullWidth
                                size="small"
                            />
                            <TextField
                                margin="dense"
                                id="name"
                                name='name'
                                label="Ім'я, призвіще"
                                type="text"
                                value={activeUser.name}
                                onChange={handleInputChange}
                                variant="outlined"
                                fullWidth
                                required
                                error={activeUser.name === ''}

                            />

                            <TextField
                                id="outlined-select-currency"
                                margin="dense"
                                // select
                                name='city'
                                label='Місто'
                                value={city.name !== '' ? city.name : LocalStorageCity}
                                // onChange={handleChangeCity}
                                variant="outlined"
                                fullWidth
                                required
                                error={city === ''}
                                disabled={true}
                            >
                                {load && <span>Loading</span>}
                                {cities && cities.map((city, ind) => (
                                    <MenuItem key={ind} value={city}>
                                        <Typography>{city.name}</Typography>
                                    </MenuItem>
                                ))}
                            </TextField>



                            <Typography variant="button" display="block" color='secondary' align='left' style={{marginTop: 5}}>Доставка або самовивіз</Typography>
                                <RadioGroup row aria-label="delivery" name="delivery" value={delivery} onChange={handleChange}>
                                    <FormControlLabel value="Доставка" control={<Radio size='small'/>} label="Доставка" />
                                    <FormControlLabel value="Самовивіз" control={<Radio size='small'/>} label="Самовивіз" />
                                </RadioGroup>


                            <TextField
                                id="outlined-select-currency"
                                margin="dense"
                                select
                                name='pizzeria'
                                label='Забрати в піцерії'
                                value={pizzeria}
                                onChange={handleChangePizzaria}
                                variant="outlined"
                                fullWidth
                                required
                                disabled={delivery === 'Доставка'}
                                error={delivery !== 'Доставка' && pizzeria === ''}
                            >
                                {loading && <span>Loading</span>}
                                {pizzaria && pizzaria.map((pizzeria, index) => (
                                    <MenuItem key={index} value={pizzeria}>
                                        <Box>
                                        <Typography>{pizzeria.city}, {pizzeria.address}</Typography>
                                        </Box>
                                        {/*<Typography>, {pizzeria.address}</Typography>*/}
                                    </MenuItem>
                                ))}
                            </TextField>



                            <TextField
                                autoFocus
                                margin="dense"
                                id="address"
                                name='address'
                                label="Адреса"
                                type="text"
                                value={activeUser.address}
                                onChange={handleInputChange}
                                variant="outlined"
                                fullWidth
                                required
                                disabled={delivery !== 'Доставка'}
                                error={delivery === 'Доставка' && activeUser.address === ''}
                            />
                            <Typography variant="button" display="block" color='secondary' align='left' style={{marginTop: 5}}>Час виконання замовлення</Typography>

                            <RadioGroup row aria-label="delivery" name="delivery" value={chooseTime} onChange={handleChooseTime}>
                                <FormControlLabel value="00:00" control={<Radio size='small'/>} label="Швидко" />
                                <FormControlLabel value='на час' control={<Radio size='small'/>} label="Призначити час" />
                            </RadioGroup>

                            {/*<TimePicker*/}
                            {/*    name='time'*/}
                            {/*    start={currentTime()}*/}
                            {/*    end='21:00'*/}
                            {/*    step={15}*/}
                            {/*    format={24}*/}
                            {/*    className={classes.time}*/}
                            {/*    value={time}*/}
                            {/*    onChange={handleChangeTime}/>*/}

                            {/*    ======================== */}

<DatePicker
    locale = "uk"
    type='select'
    selected={startDate}
    onChange={date => {setStartDate(date); putTime(date)}}
    showTimeSelect
    dateFormat = "Pp"
    popperPlacement='top-center'
    timeIntervals={15}
    minDate={(new Date())}
    minTime={setHours(setMinutes(new Date(), 0), 11)}
    maxTime={setHours(setMinutes(new Date(), 30), 21)}
    filterTime={filterPassedTime}
    customInput={<ExampleCustomInput />}
/>


                            {/*==================================*/}


                            <FormControl fullWidth component="fieldset">
                                <Typography variant="button" display="block" color='secondary' align='left' style={{marginTop: 5}}>Оплата</Typography>
                                <RadioGroup row aria-label="Оплата" name="payMetod" value={payMetod} onChange={handleChangePayMetod}>
                                    <FormControlLabel value="готівкою" control={<Radio size='small'/>} label="готівкою" />
                                    {/*<FormControlLabel value="карткою кур'єру" control={<Radio size='small'/>} label="карткою кур'єру" />*/}
                                    <FormControlLabel value="на сайті" control={<Radio size='small'/>} label="на сайті (скоро)" disabled />
                                </RadioGroup>
                            </FormControl>

                            <Typography color='secondary'  align='left'>Вартість доставки: <span>{deliveryCount}</span></Typography>

                        </div>
                    </form>
                </Box>
            }



        </Box>
    )

}