import * as React from 'react';


import { useSelector } from 'react-redux';
import {
    Collapse,
    Divider,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
    StepLabel,
    useMediaQuery
} from '@material-ui/core';
import { MenuItemLink, getResources } from 'react-admin';
import ReceiptIcon from '@material-ui/icons/Receipt';
import GroupIcon from '@material-ui/icons/Group';
import {ExpandLess, ExpandMore} from "@material-ui/icons";
import FastfoodIcon from '@material-ui/icons/Fastfood';
import RoomIcon from '@material-ui/icons/Room';

import PizzaIcon from '../../images/SVG_icon/pizza (1).svg'
import CheeseIcon from '../../images/SVG_icon/029-cheese.svg'
import SnackIcon from '../../images/SVG_icon/french-fries.svg'
import DrinkIcon from '../../images/SVG_icon/softdrinks (1).svg'
import AddedIcon from '../../images/SVG_icon/ingredients.svg'
import SauceIcon from '../../images/SVG_icon/condiments.svg'
import ComboIcon from '../../images/SVG_icon/pizza.svg'
import BurgerIcon from '../../images/SVG_icon/burger.svg'
import SushiIcon from '../../images/SVG_icon/sushi (2).svg'
import ChildCareIcon from "@material-ui/icons/ChildCare";
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import StorefrontIcon from '@material-ui/icons/Storefront';
import app from "firebase";
import {useDocumentData} from "react-firebase-hooks/firestore";
import {useEffect} from "react";
import {useFirebaseApp} from "reactfire";






const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(2),
    },
}));



const Menu = ({ onMenuClick, logout }) => {
    const classes = useStyles();
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    const resources = useSelector(getResources);

    const [openLink, setOpenLink] = React.useState(false);
    const [openSushi, setOpenSushi] = React.useState(false);

    const handleClick = () => {
        setOpenLink(!openLink);
    };
    const handleSushi = () => {
        setOpenSushi(!openSushi);
    };

    //=============
    const firebaseApp = useFirebaseApp()
    let user = app.auth().currentUser;
    let name, email, photoUrl, uid, emailVerified;

    if (user != null) {
        name = user.displayName;
        email = user.email;
        photoUrl = user.photoURL;
        emailVerified = user.emailVerified;
        uid = user.uid;  // The user's ID, unique to the Firebase project. Do NOT use
                         // this value to authenticate with your backend server, if
                         // you have one. Use User.getToken() instead.
    }

    const [isAdmin, setIsAdmin] = React.useState(false)
    const [permissions, setPermissions] = React.useState('User')





    const [currentUser, loading, error] = useDocumentData(
        firebaseApp.firestore().doc('users/'+uid),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );




    useEffect(()=> {
        if(currentUser){
            if(currentUser.role === 'Admin'){
                setIsAdmin(true)
                setPermissions('Admin')
                console.log(permissions)
                console.log(isAdmin)
            }
        }


    }, [currentUser, isAdmin,user])
    //============
    return (
        <>
        {(isAdmin === true) ?
        <div>
            <br/>
            <Divider />
            <br/>
            <MenuItemLink
                to="/orders"
                primaryText="Замовлення"
                leftIcon={<ReceiptIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            <br/>
            <Divider />
            <MenuItemLink
                to="/cities"
                primaryText="Міста"
                leftIcon={<RoomIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />

            <MenuItemLink
                to="/pizzaerias"
                primaryText="Піцерії"
                leftIcon={<StorefrontIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            <br/>
            <Divider />
            <br/>
            <MenuItemLink
                to="/users"
                primaryText="Користувачі"
                leftIcon={<GroupIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            <br/>

            <Divider />

<ListItem
    button onClick={handleClick}>
    <ListItemIcon>
        <FastfoodIcon />
    </ListItemIcon>
    <ListItemText primary='Меню' />
    {openLink ? <ExpandLess /> : <ExpandMore />}
</ListItem>
            <Collapse in={openLink} timeout='auto' unmountOnExit>
                <MenuItemLink sidebarIsOpen={open}
                              primaryText='Піца'
                              onClick={onMenuClick}
                              className={classes.nested}
                              leftIcon={<StepLabel
                                  icon={<img src={PizzaIcon}
                                             alt='' width="25px" height="25px"/>} /> }
                              to="/pizzas"/>
                <MenuItemLink sidebarIsOpen={open}
                              primaryText='Бургер'
                              onClick={onMenuClick}
                              className={classes.nested}
                              leftIcon={<StepLabel
                                  icon={<img src={BurgerIcon}
                                             alt='' width="25px" height="25px"/>} /> }
                              to="/burgers"/>

                <MenuItemLink sidebarIsOpen={open}
                              primaryText='Комбо-меню'
                              onClick={onMenuClick}
                              className={classes.nested}
                              leftIcon={<StepLabel
                                  icon={<img src={ComboIcon}
                                             alt='' width="25px" height="25px"/>} /> }
                              to="/combos"/>

                <MenuItemLink sidebarIsOpen={open}
                              primaryText='Снекі'
                              onClick={onMenuClick}
                              className={classes.nested}
                              leftIcon={<StepLabel
                                  icon={<img src={SnackIcon}
                                             alt='' width="25px" height="25px"/>} /> }
                              to="/snacks"/>
                <MenuItemLink sidebarIsOpen={open}
                              primaryText='Напої'
                              onClick={onMenuClick}
                              className={classes.nested}
                              leftIcon={<StepLabel
                                  icon={<img src={DrinkIcon}
                                             alt='' width="25px" height="25px"/>} /> }
                              to="/drinks"/>
                <MenuItemLink sidebarIsOpen={open}
                              primaryText='Додатки'
                              onClick={onMenuClick}
                              className={classes.nested}
                              leftIcon={<StepLabel
                                  icon={<img src={AddedIcon}
                                             alt='' width="25px" height="25px"/>} /> }
                              to="/additives"/>
                <MenuItemLink sidebarIsOpen={open}
                              primaryText='Сирний борт'
                              onClick={onMenuClick}
                              className={classes.nested}
                              leftIcon={<StepLabel
                                  icon={<img src={CheeseIcon}
                                             alt='' width="25px" height="25px"/>} /> }
                              to="/cheesebord"/>
                <MenuItemLink sidebarIsOpen={open}
                              primaryText='Соуси'
                              onClick={onMenuClick}
                              className={classes.nested}
                              leftIcon={<StepLabel
                                  icon={<img src={SauceIcon}
                                             alt='' width="25px" height="25px"/>} /> }
                              to="/sauses"/>




            </Collapse>


            {/*<ListItem*/}
            {/*    button onClick={handleSushi}>*/}
            {/*    <ListItemIcon>*/}
            {/*        <img src={SushiIcon}*/}
            {/*             alt='' width="25px" height="25px"/>*/}
            {/*    </ListItemIcon>*/}
            {/*    <ListItemText primary='Суші меню' />*/}
            {/*    {openSushi ? <ExpandLess /> : <ExpandMore />}*/}
            {/*</ListItem>*/}
            {/*<Collapse in={openSushi} timeout='auto' unmountOnExit>*/}
            {/*    <MenuItemLink sidebarIsOpen={open}*/}
            {/*                  primaryText='Великі роли'*/}
            {/*                  onClick={onMenuClick}*/}
            {/*                  className={classes.nested}*/}
            {/*                  leftIcon={<StepLabel*/}
            {/*                      // icon={<img src={PizzaIcon}*/}
            {/*                      //            alt='' width="25px" height="25px"/>}*/}
            {/*                  /> }*/}
            {/*                  to="/bigRolls"/>*/}

            {/*    <MenuItemLink sidebarIsOpen={open}*/}
            {/*                  primaryText='Макі роли'*/}
            {/*                  onClick={onMenuClick}*/}
            {/*                  className={classes.nested}*/}
            {/*                  leftIcon={<StepLabel*/}
            {/*                      // icon={<img src={PizzaIcon}*/}
            {/*                      //            alt='' width="25px" height="25px"/>}*/}
            {/*                  /> }*/}
            {/*                  to="/makiRolls"/>*/}

            {/*    <MenuItemLink sidebarIsOpen={open}*/}
            {/*                  primaryText='Сирні роли'*/}
            {/*                  onClick={onMenuClick}*/}
            {/*                  className={classes.nested}*/}
            {/*                  leftIcon={<StepLabel*/}
            {/*                      // icon={<img src={PizzaIcon}*/}
            {/*                      //            alt='' width="25px" height="25px"/>}*/}
            {/*                  /> }*/}
            {/*                  to="/cheeseRolls"/>*/}

            {/*    <MenuItemLink sidebarIsOpen={open}*/}
            {/*                  primaryText='Фірмові роли'*/}
            {/*                  onClick={onMenuClick}*/}
            {/*                  className={classes.nested}*/}
            {/*                  leftIcon={<StepLabel*/}
            {/*                      // icon={<img src={PizzaIcon}*/}
            {/*                      //            alt='' width="25px" height="25px"/>}*/}
            {/*                  /> }*/}
            {/*                  to="/brandRolls"/>*/}

            {/*    <MenuItemLink sidebarIsOpen={open}*/}
            {/*                  primaryText='Lite роли'*/}
            {/*                  onClick={onMenuClick}*/}
            {/*                  className={classes.nested}*/}
            {/*                  leftIcon={<StepLabel*/}
            {/*                      // icon={<img src={PizzaIcon}*/}
            {/*                      //            alt='' width="25px" height="25px"/>}*/}
            {/*                  /> }*/}
            {/*                  to="/liteRolls"/>*/}

            {/*    <MenuItemLink sidebarIsOpen={open}*/}
            {/*                  primaryText='СЕТИ'*/}
            {/*                  onClick={onMenuClick}*/}
            {/*                  className={classes.nested}*/}
            {/*                  leftIcon={<StepLabel*/}
            {/*                      // icon={<img src={PizzaIcon}*/}
            {/*                      //            alt='' width="25px" height="25px"/>}*/}
            {/*                  /> }*/}
            {/*                  to="/sets"/>*/}

            {/*    <MenuItemLink sidebarIsOpen={open}*/}
            {/*                  primaryText='Lite СЕТИ'*/}
            {/*                  onClick={onMenuClick}*/}
            {/*                  className={classes.nested}*/}
            {/*                  leftIcon={<StepLabel*/}
            {/*                      // icon={<img src={PizzaIcon}*/}
            {/*                      //            alt='' width="25px" height="25px"/>}*/}
            {/*                  /> }*/}
            {/*                  to="/liteSets"/>*/}

            {/*    /!*<MenuItemLink sidebarIsOpen={open}*!/*/}
            {/*    /!*              primaryText='Комбо-меню'*!/*/}
            {/*    /!*              onClick={onMenuClick}*!/*/}
            {/*    /!*              className={classes.nested}*!/*/}
            {/*    /!*              leftIcon={<StepLabel*!/*/}
            {/*    /!*                  icon={<img src={ComboIcon}*!/*/}
            {/*    /!*                             alt='' width="25px" height="25px"/>} /> }*!/*/}
            {/*    /!*              to="/combos"/>*!/*/}

            {/*    /!*<MenuItemLink sidebarIsOpen={open}*!/*/}
            {/*    /!*              primaryText='Снекі'*!/*/}
            {/*    /!*              onClick={onMenuClick}*!/*/}
            {/*    /!*              className={classes.nested}*!/*/}
            {/*    /!*              leftIcon={<StepLabel*!/*/}
            {/*    /!*                  icon={<img src={SnackIcon}*!/*/}
            {/*    /!*                             alt='' width="25px" height="25px"/>} /> }*!/*/}
            {/*    /!*              to="/snacks"/>*!/*/}
            {/*    /!*<MenuItemLink sidebarIsOpen={open}*!/*/}
            {/*    /!*              primaryText='Напої'*!/*/}
            {/*    /!*              onClick={onMenuClick}*!/*/}
            {/*    /!*              className={classes.nested}*!/*/}
            {/*    /!*              leftIcon={<StepLabel*!/*/}
            {/*    /!*                  icon={<img src={DrinkIcon}*!/*/}
            {/*    /!*                             alt='' width="25px" height="25px"/>} /> }*!/*/}
            {/*    /!*              to="/drinks"/>*!/*/}
            {/*    /!*<MenuItemLink sidebarIsOpen={open}*!/*/}
            {/*    /!*              primaryText='Додатки'*!/*/}
            {/*    /!*              onClick={onMenuClick}*!/*/}
            {/*    /!*              className={classes.nested}*!/*/}
            {/*    /!*              leftIcon={<StepLabel*!/*/}
            {/*    /!*                  icon={<img src={AddedIcon}*!/*/}
            {/*    /!*                             alt='' width="25px" height="25px"/>} /> }*!/*/}
            {/*    /!*              to="/additives"/>*!/*/}
            {/*    /!*<MenuItemLink sidebarIsOpen={open}*!/*/}
            {/*    /!*              primaryText='Соуси'*!/*/}
            {/*    /!*              onClick={onMenuClick}*!/*/}
            {/*    /!*              className={classes.nested}*!/*/}
            {/*    /!*              leftIcon={<StepLabel*!/*/}
            {/*    /!*                  icon={<img src={SauceIcon}*!/*/}
            {/*    /!*                             alt='' width="25px" height="25px"/>} /> }*!/*/}
            {/*    /!*              to="/sauses"/>*!/*/}




            {/*</Collapse>*/}
            {/*==========*/}

            <Divider />

            <MenuItemLink
                to="/stocks"
                primaryText="Акції"
                leftIcon={<ChildCareIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />

            {isAdmin &&
            <MenuItemLink
                to="/tags"
                primaryText="Акційні наліпки"
                leftIcon={<LoyaltyIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />}

            {/*{resources.map(resource => (*/}
            {/*    <MenuItemLink*/}
            {/*        key={resource.name}*/}
            {/*        to={`/${resource.name}`}*/}
            {/*        primaryText={*/}
            {/*            (resource.options && resource.options.label) ||*/}
            {/*            resource.name*/}
            {/*        }*/}
            {/*        leftIcon={*/}
            {/*            resource.icon ? <resource.icon /> : <DefaultIcon />*/}
            {/*        }*/}
            {/*        onClick={onMenuClick}*/}
            {/*        sidebarIsOpen={open}*/}
            {/*    />*/}
            {/*))}*/}

            {isXSmall}
        </div>: <div >
                <br/>
                <Divider />
                <br/>
                <MenuItemLink
                    to="/"
                    primaryText="Ви не Адміністратор"
                    leftIcon={<ReceiptIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
                    </div>}
                    </>
    );
};

export default Menu;