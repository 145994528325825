import React from "react";

import {useCollectionData} from 'react-firebase-hooks/firestore'
import firebase from 'firebase/app';

import { Carousel } from "react-responsive-carousel";
import "./carousel.min.css"; // requires a loader
import Skeleton from '@material-ui/lab/Skeleton'
import {Container, Paper} from "@material-ui/core";


const StocksCarousel = ({id, setSeeSushi}) => {

    let loc = localStorage.getItem('place')
    let [value, loading, error] = useCollectionData(
        firebase.firestore().collection('stocks').where('location', '!=', loc),
        {
            snapshotListenOptions: {includeMetadataChanges: true},
        }
    );

    if (loading) {
        value = []
    }

    const changeSushi = (index) => {
        if (index === 1) {
            setSeeSushi(true)
        } else {
            setSeeSushi(false)
        }
    }



    return (
<div id={id}>
        {error && <strong>Error: {JSON.stringify(error)}</strong>}
        {loading && <Skeleton height='100%' width='100%' animation="wave" /> }
        <Carousel
            autoPlay={true}
            infiniteLoop
            centerMode
            centerSlidePercentage={80}
            showArrows
            showThumbs={false}
            showStatus={false}
            interval={4500}
            swipeable
            emulateTouch
            useKeyboardArrows
            stopOnHover
            onClickItem={index => changeSushi(index)}>

            {value.map((pictures) => (

                <Container key={pictures.pictures.title}>

                <Paper variant='outlined' square={false}
                       key={pictures.pictures.title}
                       style={{borderRadius: '2vw', overflow: 'hidden', cursor: 'pointer'}}>
                <img height='auto' src={pictures.pictures.src} alt='action'/>
                </Paper>
                </Container>

                ))}



        </Carousel>
</div>
    )
}

export default StocksCarousel