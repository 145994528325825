import React from "react";


import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import {BrowserRouter as Router, Route} from "react-router-dom";
import * as ROUTES from "../constants/routes";
import AdminPanel from "../Admin/Admin";
import Landing from "../pages/Landing/Landing";

import UbuntuRegular from '../../fonts/Ubuntu-Regular.ttf';
import {CssBaseline} from "@material-ui/core";

const Ubuntu = {
    fontFamily: 'Ubuntu',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 400,
    src: `
    local('Ubuntu'),
    local('Ubuntu-Regular'),
    url(${UbuntuRegular}) format('ttf')
  `,
    unicodeRange:
        'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#4c8c4a',
            main: '#1b5e20',
            dark: '#003300',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ff867f',
            main: '#f32525',
            dark: '#f60428',
            contrastText: '#fff',
        },
    },

    typography: {
        fontFamily: [
            'Ubuntu',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
            '"Days One"',
            '"Pacifico"',
            '"Pattaya"',
            "'Prosto One'",
            "'Russo One'",
            "'Appetite'",
            "'Comfortaa'"

        ].join(','),
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': [Ubuntu],
            }
        }
    }
});





const App = () => {

    return (
        <Router>

            <ThemeProvider theme={theme}>
                <CssBaseline />
            <Route exact path={ROUTES.LANDING} component={Landing} />
            <Route path={ROUTES.ADMIN} component={AdminPanel} />
            </ThemeProvider>

        </Router>

    );

}

export default App;
