import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';

import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import Button from '@material-ui/core/Button';
import pizzaDefault from '../../../images/pizza.png'
import {Skeleton} from "@material-ui/lab";
import {Avatar, Box, Grid, Typography} from "@material-ui/core";

import { useDocumentData} from "react-firebase-hooks/firestore";
import firebase from 'firebase/app';
import DefaultCard from "./defaultCard";



const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '25rem',
        minHeight: '30rem',
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        flexGrow: 1,
        position: 'relative'

    },

    cardBody: {
        minHeight: '25rem'

    },


    media: {
        height: '60%',
        width: '95%',
        margin: '1% auto',
        '&:hover': {
            transform: 'scale(1.06)'
        }},

    toBox: {
        borderRadius: '2rem',
        margin: '0.5rem'
    },
    pricePizza: {
        fontWeight: '700',
        fontFamily: 'Comfortaa',
        fontSize: '1.8rem'
    },

    marginCard: {
        margin: '1% auto'
    },
    ingr: {
        marginLeft: '16px'
    },

    label: {
        width: theme.spacing(6),
        height: theme.spacing(6),
        backgroundColor: 'red',
    },


}));

export default function DrinksCard(props) {
    const classes = useStyles();

    const [drink, loading, error] = useDocumentData(
        firebase.firestore().doc('drinks/' + props.id),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );

    let price = 0

    let place = localStorage.getItem('place')

    if (!loading && place === '2') {
        price = drink.price_
    } else if (!loading) {
        price = drink.price
    }

    let addedProduct

    if (!loading && drink) {
        addedProduct = {
            category: 'Напої',
            capacity: drink.weight,
            options: {
                deletedProducts: [],
                addedProducts: []
            },
            name: drink.name,
            imageURL: drink.pictures.src,
            price: price,
            count: 1
        }
    } else {
        addedProduct = null
    }


            return (

        <Card className={classes.root} elevation={0} >
            {error && <strong>Error: {JSON.stringify(error)}</strong>}
            {loading && (<DefaultCard />)}


            <Box displaay='flex'
                 flexDirection="column">

                <CardActionArea className={classes.cardBody}>


                    <CardMedia
                        className={classes.media}
                        component="img"
                        alt="Snack"
                        image={!loading && drink ? drink.pictures.src : pizzaDefault}/>

                    <Box
                        position="absolute"
                        top='5%'
                        left='5%'
                        zIndex={0}
                    >
                        <Avatar className={classes.label}>
                            {!loading && drink ?
                                drink.weight + 'L'
                                : <Skeleton />
                            }
                        </Avatar>
                    </Box>

                    <Typography variant='h6' color="secondary" align='center' className={classes.pricePizza}>
                        {!loading && drink ? drink.name : <Skeleton height='1rem' />}
                    </Typography>
                    {/*<Typography variant='body1' color="textSecondary" component="p"  className={classes.ingr}>*/}
                    {/*    {!loading && drink ? drink.info : <Skeleton height='1rem' />}*/}
                    {/*</Typography>*/}
                </CardActionArea>


                <CardActions className={classes.cardAction}>

                    <Grid container spacing={1}
                          direction="row"
                          justify="space-between"
                          alignItems="center"
                          className={classes.marginCard}
                          style={{ padding: 0}}>

                        <Grid item>
                            <Typography variant='h3' color='textPrimary' className={classes.pricePizza}>
                                {!loading && drink ?
                                    price + '.00'
                                    : <Skeleton />
                                }
                            </Typography>
                        </Grid>


                        <Grid item style={{ padding: 0}}>
                            <Button variant="outlined" color="secondary" disableElevation size='large'
                                    className={classes.toBox}
                                    onClick={() => {props.addDrinkToBox(addedProduct)}}>
                                ЗАМОВИТИ
                            </Button>



                        </Grid>


                    </Grid>


                </CardActions>



            </Box>
        </Card>
    );

}