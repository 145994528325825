import * as React from 'react';
import { AppBar } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {createMuiTheme, makeStyles, ThemeProvider} from '@material-ui/core/styles';
import {Box} from "@material-ui/core";
import SignUp from "../SignUp/SignUp";
import UserButton from "../header/UserButton";
import {useUser} from "reactfire";
import logo_it from "../../images/logo_average.png";
import Link from "@material-ui/core/Link";
import * as ROUTES from "../constants/routes";
import {ukUA} from "@material-ui/core/locale";

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#4c8c4a',
            main: '#fff',
            dark: '#003300',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ff867f',
            main: '#f50404',
            dark: '#c50e29',
            contrastText: '#fff',
        },
    },

}, ukUA);




const useStyles = makeStyles((theme) => ({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },

    appBar: {
        backgroundColor: 'gray',
        marginBottom: theme.spacing(1),
    },

    spacer: {
        flex: 3,
    },

    button_auth: {
        color: 'wait'
    },

    logo_it: {
        height: '60px',
        margin: '5px',
        display: 'block',
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },

    },
}));

const MyAppBar = props => {
    const classes = useStyles();
    const user = useUser()
    return (
        <ThemeProvider theme={theme}>
        <AppBar {...props} userMenu={user.data === null ? <SignUp /> : <UserButton/>} className={classes.appBar}>
            <Box className={classes.title}>
                <Link href={ROUTES.LANDING} underline='none' >
                <img className={classes.logo_it} alt="Logo Italia" src={logo_it}/>
                </Link>
            </Box>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />

            <Box className={classes.title}/>
            <span className={classes.spacer} />


        </AppBar>
        </ThemeProvider>
    );
};

export default MyAppBar;