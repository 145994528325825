import * as React from "react";
import {
    useEditController, EditContextProvider,
    List, Datagrid, TextField,
    ArrayField, Show, SimpleShowLayout, SingleFieldList, ChipField, DateField
} from 'react-admin';


import {createMuiTheme, makeStyles, ThemeProvider} from '@material-ui/core/styles';
import {cloneElement} from "react";


const theme = createMuiTheme ({
    overrides: {
        RaImageField: {
            image: {
                maxHeight: '5rem',
                margin: 0,

            }

        }
    }
})


// const useStyles = makeStyles({
//     inlineBlock: {
//         display: 'inline-flex',
//         marginRight: '1rem',
//     },
// });

const ShowOrder = props => (
    <Show {...props}
    title='Замовлення детально'>
        <SimpleShowLayout>
        <ArrayField source='products' label='Товари' >
            <Datagrid>
                <TextField source='category'/>
                <TextField source='name' label='назва'/>
                <TextField source='capacity' label='об єм'/>
                <TextField source='count' label='кількість'/>
                <TextField source='price' label='ціна*'/>
                <TextField source="options.chooseSauce.sauceName" label='Доданий соус' />
                <TextField source="options.chooseSauce.saucePrice" label='Ціна соусу' />
                <ArrayField source="options.addedProducts" label='Додані продукти'>
                    <SingleFieldList>
                        <ChipField source="name" size='small'/>
                    </SingleFieldList>
                </ArrayField>
                <ArrayField source="options.deletedProducts" label='Прибрати продкути'>
                    <SingleFieldList>
                        <ChipField source="letIngr" size='small'/>
                    </SingleFieldList>
                </ArrayField>


            </Datagrid>
        </ArrayField>
        </SimpleShowLayout>
    </Show>
)


export const OrdersList = props => (
    <ThemeProvider theme={theme}>
        <List {...props} title='Замовлення' sort={{ field: 'orderTime', order: 'DESC' }}
              exporter={false}>
            <Datagrid rowClick="edit" expand={<ShowOrder />}>
                <DateField source="orderTime" label='Замовлення створено' showTime/>
                <TextField source="leadTime" label='Час виконання'/>
                <TextField source="userName" label='Замовник'/>
                <TextField source="userPhone" label='Телефон замовника'/>
                <TextField source="addressDelivery" label='Адреса'/>
                <TextField source="city" label='Місто'/>
                <TextField source="delivery" label='Тип доставки'/>
                <TextField source="pizzeria.name" label='Точка видачі'/>
                <TextField source="countDelivery" label='Вартість доставки'/>
                <TextField source="orderCount" label='Вартість замовлення'/>
                <TextField source="payStatus" label='Статус оплати'/>

            </Datagrid>
        </List>
    </ThemeProvider>
);


// export const OrdersCreate = (props) => {
//     const classes = useStyles();
//
//     return (
//         <Create {...props} title='Додати піцу'>
//             <SimpleForm>
//                 <Box formClassName={classes.inlineBlock}>
//                     <TextInput source='name' label='Назва піци' validate={[required()]}/>
//                     <NumberInput source="id#" label='Порядковий номер'
//                                  formClassName={classes.inlineBlock}/>
//                     <Promotion sourse='promotion' defaultValue='none'/>
//                 </Box>
//
//
//                 <ImageInput source="pictures" label="Зображення" accept="image/*"
//                             formClassName={classes.inlineBlock}>
//                     <ImageField source="src" title="title"/>
//                 </ImageInput>
//
//
//                 <ArrayInput source="possibleOptions" label='Доступні варіанти вибору' validate={[required()]}>
//                     <SimpleFormIterator addButton={<AddBoxIcon/>} removeButton={<HighlightOffIcon/>}
//                                         formClassName={classes.inlineBlock}>
//                         <TextInput source='surName' label='Назва варіанту'
//                                    formClassName={classes.inlineBlock}/>
//                         <TextInput source='size' label='Розмір (діаметр)'
//                                    formClassName={classes.inlineBlock}/>
//                         <NumberInput source="weight" label='Вага'
//                                      formClassName={classes.inlineBlock}/>
//                         <NumberInput source="price" label='Ціна'
//                                      formClassName={classes.inlineBlock}/>
//                     </SimpleFormIterator>
//                 </ArrayInput>
//
//
//                 <TextInput source='description' label='Опис' fullWidth={true}/>
//                 <TextInput source='info' label='Інформація' fullWidth={true}/>
//
//
//                 <ArrayInput source="constIngadient" label='Базові складові рецепту' validate={[required()]}>
//                     <SimpleFormIterator addButton={<AddBoxIcon/>} removeButton={<HighlightOffIcon/>}>
//                         <TextInput source='constIngr' label='Інградієнт'
//                                    formClassName={classes.inlineBlock}
//                                    validate={[required()]}/>
//                     </SimpleFormIterator>
//                 </ArrayInput>
//                 <ArrayInput source="letIngadient" label='Cкладові рецепту, що видаляються' validate={[required()]}>
//                     <SimpleFormIterator addButton={<AddBoxIcon/>} removeButton={<HighlightOffIcon/>}>
//                         <TextInput source='letIngr' label='Інградієнт'
//                                    formClassName={classes.inlineBlock}/>
//                     </SimpleFormIterator>
//                 </ArrayInput>
//
//
//             </SimpleForm>
//         </Create>)
// }

//============

//==========================================================
//===Використовувати якщо потрібно редагувати
// const MyEdit = props => {
//     const controllerProps = useEditController(props);
//     const {
//         basePath, record, redirect, resource, save,
//         saving, version
//     } = controllerProps;
//
//     return (
//         <EditContextProvider value={controllerProps}>
//             <div>
//
//                 {cloneElement(props.children, {
//                     basePath, record, redirect, resource,
//                     save, saving, version,
//                 })
//                 }
//             </div>
//         </EditContextProvider>
//     );
//
// }
//==============================================
//==============================================
// export const PizzasEdit = (props) => {
//     const classes = useStyles();
//     return (<MyEdit title='Редагування'  {...props} undoable={false}>
//             <SimpleForm>
//                 <TextInput source='name' label='Назва піци'
//                            formClassName={classes.inlineBlock}/>
//                 <NumberInput source="id#" label='Номер' formClassName={classes.inlineBlock}/>
//                 <TextInput disabled source="id" formClassName={classes.inlineBlock}/>
//                 <div>
//
//                     <ImageInput source="pictures" label="Зображення" accept="image/*"
//                                 formClassName={classes.inlineBlock}>
//                         <ImageField source="src" title="title"/>
//                     </ImageInput>
//                     <Promotion sourse='promotion' defaultValue='none'
//                                formClassName={classes.inlineBlock}/>
//
//                 </div>
//
//
//
//
//
//                 {/*<Box>*/}
//                 {/*    <TextInput source='name' label='Назва піци'*/}
//                 {/*               // validate={[required()]}*/}
//                 {/*    />*/}
//                 {/*    <NumberInput source="id#" label='Порядковий номер'*/}
//                 {/*                 formClassName={classes.inlineBlock}/>*/}
//
//                 {/*</Box>*/}
//
//
//
//
//
//
//
//                 <ArrayInput source="possibleOptions" label='Доступні варіанти вибору' validate={[required()]}>
//                     <SimpleFormIterator addButton={<AddBoxIcon/>} removeButton={<HighlightOffIcon/>}
//                                         formClassName={classes.inlineBlock}>
//                         <TextInput source='surName' label='Назва варіанту'
//                                    formClassName={classes.inlineBlock}/>
//                         <TextInput source='size' label='Розмір (діаметр)'
//                                    formClassName={classes.inlineBlock}/>
//                         <NumberInput source="weight" label='Вага'
//                                      formClassName={classes.inlineBlock}/>
//                         <NumberInput source="price" label='Ціна'
//                                      formClassName={classes.inlineBlock}/>
//                     </SimpleFormIterator>
//                 </ArrayInput>
//
//                 <TextInput source='description' label='Опис' fullWidth={true}/>
//                 <TextInput source='info' label='Інформація' fullWidth={true}/>
//
//
//                 <ArrayInput source="constIngadient" label='Базові складові рецепту' validate={[required()]}>
//                     <SimpleFormIterator addButton={<AddBoxIcon/>} removeButton={<HighlightOffIcon/>}>
//                         <TextInput source='constIngr' label='Інградієнт'
//                                    formClassName={classes.inlineBlock}
//                                    validate={[required()]}/>
//                     </SimpleFormIterator>
//                 </ArrayInput>
//                 <ArrayInput source="letIngadient" label='Cкладові рецепту, що видаляються' validate={[required()]}>
//                     <SimpleFormIterator addButton={<AddBoxIcon/>} removeButton={<HighlightOffIcon/>}>
//                         <TextInput source='letIngr' label='Інградієнт'
//                                    formClassName={classes.inlineBlock}/>
//                     </SimpleFormIterator>
//                 </ArrayInput>
//             </SimpleForm>
//         </MyEdit>
//     );
// }