import * as React from "react";
import {
    Create, useEditController, EditContextProvider,
    NumberField, ImageInput, SimpleForm, List, TextInput, NumberInput,
    Datagrid, TextField, ImageField, RadioButtonGroupInput, required
} from 'react-admin';

import {createMuiTheme, makeStyles, ThemeProvider} from '@material-ui/core/styles';
import {cloneElement} from "react";


const theme = createMuiTheme ({
    overrides: {
        RaImageField: {
            image: {
                maxHeight: '5rem',
                margin: 0,

            }

        }
    }
})


const useStyles = makeStyles({
    inlineBlock: {
        display: 'inline-flex',
        marginRight: '1rem',
    },
});



const Promotion = (props) => {
    return (
        <RadioButtonGroupInput source={props.sourse}
                               defaultValue={props.defaultValue}
                               row={false}
                               choices={[
                                   {id: 'none', name: 'Жодної категорії'},
                                   {id: 'top', name: 'Популярно'},
                                   {id: 'new', name: 'Новинка'},
                               ]}/>
    )
}

export const BigRollsList = props => (
    <ThemeProvider theme={theme}>
        <List {...props} title='СУШІ - Великі роли' sort={{ field: 'sortNumber', order: 'ASC' }}
              exporter={false}>
            <Datagrid rowClick="edit">
                <NumberField source="sortNumber" label='№ з/п'/>
                <TextField source="name" label='Назва'/>
                <ImageField source="pictures.src" title="pictures.title" label='Зображення' />
                <TextField source="description" label='Склад'/>
                <TextField source="promotion" label='Акція'/>
                <NumberField source="weight" label='Вага,g'/>
                <NumberField source="price" label='Ціна,грн'/>
                <NumberField source="price_" label='Ціна+, грн'/>
                <TextField source="size" label='Розмір'/>
            </Datagrid>
        </List>
    </ThemeProvider>
);


export const BigRollsCreate = (props) => {
    const classes = useStyles();

    return (
        <Create {...props} title='Додати Великі роли'>
            <SimpleForm>
                <TextInput source='name' label='Назва ролу' validate={[required()]}/>
                <NumberInput source="sortNumber" label='№ з/п'
                             formClassName={classes.inlineBlock}/>
                <Promotion sourse='promotion' defaultValue='none'/>
                <ImageInput source="pictures" label="Зображення" accept="image/*"
                            formClassName={classes.inlineBlock}>
                    <ImageField source="src" title="title"/>
                </ImageInput>
                <TextInput source='size' label='Обсяг, шт'
                           formClassName={classes.inlineBlock}/>
                <NumberInput source="weight" label='Вага, г.'
                             formClassName={classes.inlineBlock}/>
                <NumberInput source="price" label='Ціна, грн.'
                             formClassName={classes.inlineBlock}/>
                <NumberInput source="price_" label='Ціна+, грн.'
                             formClassName={classes.inlineBlock}/>
                <TextInput source='description' label='Склад' fullWidth={true}/>

            </SimpleForm>
        </Create>)
}

//============

const MyEdit = props => {
    const controllerProps = useEditController(props);
    const {
        basePath, record, redirect, resource, save,
        saving, version
    } = controllerProps;

    return (
        <EditContextProvider value={controllerProps}>
            <div>

                {cloneElement(props.children, {
                    basePath, record, redirect, resource,
                    save, saving, version,
                })
                }
            </div>
        </EditContextProvider>
    );

}


export const BigRollsEdit = (props) => {
    const classes = useStyles();
    return (<MyEdit title='Редагування'  {...props} undoable={false}>
            <SimpleForm>
                <TextInput source='name' label='Назва ролу'
                           formClassName={classes.inlineBlock}/>
                <NumberInput source="sortNumber" label='№ з/п' formClassName={classes.inlineBlock}/>
                <TextInput disabled source="id" formClassName={classes.inlineBlock}/>
                <div>
                    <ImageInput source="pictures" label="Зображення" accept="image/*"
                                formClassName={classes.inlineBlock}>
                        <ImageField source="src" title="title"/>
                    </ImageInput>
                    <Promotion sourse='promotion' defaultValue='none'
                               formClassName={classes.inlineBlock}/>
                </div>
                <TextInput source='size' label='Обсяг, шт'
                           formClassName={classes.inlineBlock}/>
                <NumberInput source="weight" label='Вага, г.'
                             formClassName={classes.inlineBlock}/>
                <NumberInput source="price" label='Ціна, грн.'
                             formClassName={classes.inlineBlock}/>
                <NumberInput source="price_" label='Ціна+, грн.'
                             formClassName={classes.inlineBlock}/>
                <TextInput source='description' label='Склад' fullWidth={true}/>
            </SimpleForm>
        </MyEdit>
    );
}