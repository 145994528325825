import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import Button from '@material-ui/core/Button';
import pizzaDefault from '../../../images/pizza.png'
import {Alert, Skeleton} from "@material-ui/lab";
import {Box, Grid, Snackbar, Typography} from "@material-ui/core";

import PromotionLabel from "../Landing/PromotionLabel";
import {useDocumentData} from "react-firebase-hooks/firestore";
import firebase from 'firebase/app';
import DefaultCard from "./defaultCard";
import ChooseCombo from "../Dialogs/ChooseCombo";


const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '25rem',
        minHeight: '32rem',
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        flexGrow: 1,
        position: 'relative'

    },

    cardBody: {
        minHeight: '27rem'

    },


    media: {
        height: '60%',
        width: '95%',
        margin: '1% auto',
        '&:hover': {
            transform: 'scale(1.06)'
        }},

    toBox: {
        borderRadius: '2rem',
        margin: '0.5rem'
    },
    pricePizza: {
        fontWeight: '700',
        fontFamily: 'Comfortaa',
        fontSize: '2.1rem'
    },

    marginCard: {
        margin: '1% auto'
    },
    ingr: {
        marginLeft: '16px'
    },

}));

export default function ComboCard(props) {
    const classes = useStyles();

    const [combo, loading, error] = useDocumentData(
        firebase.firestore().doc('combos/' + props.id),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }

    );



    let price = 0

    let place = localStorage.getItem('place')

    if (!loading && place === '2') {
        price = combo.possibleOptions[0].price_
    } else if (!loading) {
        price = combo.possibleOptions[0].price
    }

    let addedProduct

    if(!loading && combo) {
        addedProduct = {
            category: 'КОМБО',
            options: {
                deletedProducts: [],
                addedProducts: []
            },
            capacity: '',
            name: combo.name,
            imageURL: combo.pictures.src,
            price: price,
            count: 1
        }
    }else {
        addedProduct = null
    }

    const [open, setOpen] = React.useState(false);

    const [showDialog, setShowDialog]= React.useState(false)

    const handleShowDialog = () => {
        setShowDialog(!showDialog)
    }


    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };



    let comboName

    if(!loading && combo) {
        comboName = combo.possibleOptions[0].size

    if (combo.possibleOptions.length > 1) {
        comboName = ''
    }
        }




    return (
        <Box>
            {error && <strong>Error: {JSON.stringify(error)}</strong>}
            {loading && (<DefaultCard />)}

        <Card className={classes.root} elevation={0} >


            <Box display='flex'
                 flexDirection="column">


                <CardActionArea className={classes.cardBody}
                                onClick={handleShowDialog}>


                    <CardMedia
                        className={classes.media}
                        component="img"
                        alt="COMBO"
                        image={!loading && combo.pictures ? combo.pictures.src : pizzaDefault}/>
                    <Box
                        position="absolute"
                        top={0}
                        right={0}
                        zIndex={0}
                    >
                        <PromotionLabel promotion={!loading && combo.promotion} />
                    </Box>
                    <Typography variant='h6' color="secondary" align='center' className={classes.pricePizza}>
                        {!loading && combo ? combo.name + ' ' + comboName : <Skeleton height='1rem' />}
                    </Typography>
                    <Typography variant='body1' color="textSecondary" component="p"  className={classes.ingr}>
                        {!loading && combo ? combo.info : <Skeleton height='1rem' />}
                    </Typography>
                </CardActionArea>


                <CardActions className={classes.cardAction}>

                    <Grid container spacing={1}
                          direction="row"
                          justify="space-between"
                          alignItems="center"
                          className={classes.marginCard}
                          style={{ padding: 0}}>

                        {/*<Grid item>*/}
                        {/*    <Typography color='textPrimary' className={classes.pricePizza}>*/}
                        {/*        {!loading && combo.possibleOptions ? price + '.00' : <Skeleton height='1rem' />}*/}
                        {/*    </Typography>*/}
                        {/*</Grid>*/}


                        <Grid item style={{ padding: 0}}>

                            {!loading && combo.name === 'FRI PIZZA' ?
                                <Button variant="outlined" color="secondary" disableElevation size='large'
                                        className={classes.toBox} onClick={handleShowDialog}>
                                    ОБРАТИ
                                </Button> :

                                <Button variant="outlined" color="secondary" disableElevation size='large'
                                        className={classes.toBox} onClick={handleShowDialog}>
                                    ОБРАТИ
                                </Button>
                                // <Button variant="outlined" color="secondary" disableElevation size='large'
                                //         className={classes.toBox}
                                //         onClick={() => {props.addComboToBox(addedProduct); handleClick()}}>
                                //     ЗАМОВИТИ
                                // </Button>

                            }

                            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                                <Alert onClose={handleClose} severity="success">
                                    До кошика додано COMBO
                                </Alert>
                            </Snackbar>

                            {!loading &&
                            <ChooseCombo showDialog={showDialog} handleShowDialog={handleShowDialog}
                                         combo={combo} addComboToBox={props.addComboToBox}
                                         loading={loading}
                                        price={price}
                                        location={props.location}/>
                            }


                        </Grid>


                    </Grid>


                </CardActions>



            </Box>
        </Card>
        </Box>
    );

}