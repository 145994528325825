import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import Button from '@material-ui/core/Button';
import pizzaDefault from '../../../images/pizza.png'
import {Alert, Skeleton} from "@material-ui/lab";
import {Box, FormControl, Grid, Snackbar, Typography} from "@material-ui/core";
import PromotionLabel from "../Landing/PromotionLabel";
import ChoosePizza from "../Dialogs/ChoosePizza";

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import {useCollectionData} from "react-firebase-hooks/firestore";
import firebase from "firebase";


const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '25rem',
        minHeight: '32rem',
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        flexGrow: 1,
        position: 'relative'

    },

    cardBody: {
        minHeight: '32rem',

    },


    media: {
        height: '60%',
        width: '95%',
        // paddingTop: '5%', // 16:9
        margin: '1% auto',
        '&:hover': {
            transform: 'scale(1.06)'
        }
    },

    toBox: {
        borderRadius: '2rem',
        margin: '0.5rem'
    },
    pricePizza: {
        fontWeight: '700',
        fontFamily: 'Comfortaa',
        fontSize: '2.1rem'
    },

    marginCard: {
        margin: '1% auto'
    },
    ingr: {
        marginLeft: '16px'
    },

}));

export default function PizzaCard(props) {
    const classes = useStyles();

    // let price = props.possibleOptions[0].price
    //
    // if (props.location === 2) {
    //     price = props.possibleOptions[0].price_
    // }

    //========== Chees Bord ==============

    const [cheesebord, load, err] = useCollectionData(
        firebase.firestore().collection('cheesebord'),
        {snapshotListenOptions: {includeMetadataChanges: true},}
    );

    const [checked, setChecked] = React.useState([]);

    const[addedINGR, setAddedINGR] = React.useState([])

    const[totalPriceAdd, setTotalPriceAdd] = React.useState(0)

    let place = localStorage.getItem('place')


    let price = 0
    let chPrice


    if (place && place === '2') {
        price = props.possibleOptions[0].price_ + totalPriceAdd
        if(!load ) {
            place === '2' ? chPrice = cheesebord[0].price_ : chPrice = cheesebord[0].price
        }
    } else {
        price = props.possibleOptions[0].price + totalPriceAdd
        if(!load ) {
            place === '2' ? chPrice = cheesebord[0].price_ : chPrice = cheesebord[0].price
        }
    }

    if(!load ) {
        place === '2' ? chPrice = cheesebord[0].price_ : chPrice = cheesebord[0].price
    }

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
            setTotalPriceAdd(chPrice)


        } else {
            newChecked.splice(currentIndex, 1);
            setTotalPriceAdd(0)
        }
        setChecked(newChecked);
        setAddedINGR(newChecked)


    };








    // const [choiceCheeseBord, setChoiceCheeswBoed] = React.useState(false)
    //
    // const handleChange = () => {
    //     setChoiceCheeswBoed(!choiceCheeseBord)
    // };

    // const [cheesebord, loading, error] = useDocumentData(
    //     firebase.firestore().doc('cheesebord/x8roebim9y99I9tQPLXH'),
    //     {
    //         snapshotListenOptions: {includeMetadataChanges: true},
    //     }
    // );
    //
    // let addedINGR = {}
    //
    //
    //
    //
    // if (choiceCheeseBord === true && !loading) {
    //     price = price + cheesebord.price
    //     addedINGR = cheesebord
    // }





    const addedProduct = {
        category: 'Піца',
        options: {
            deletedProducts: [],
            addedProducts: addedINGR,
        },
        capacity: '',
        name: props.name,
        imageURL: props.pictures.src,
        price: price,
        count: 1
    }


    const [open, setOpen] = React.useState(false);

    //================
    const [showDialog, setShowDialog] = React.useState(false)

    const handleShowDialog = () => {
        setShowDialog(!showDialog)
    }


    //================

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };




    return (

        <Card className={classes.root} elevation={0}>


            <Box display='flex'
                 flexDirection="column">


                <CardActionArea className={classes.cardBody}
                                onClick={handleShowDialog}>


                    <CardMedia
                        className={classes.media}
                        component="img"
                        alt="PIZZA"
                        image={!props.loading && props.pictures ? props.pictures.src : pizzaDefault}/>
                    <Box
                        position="absolute"
                        top={0}
                        right={0}
                        zIndex={0}
                    >
                        <PromotionLabel promotion={props.promotion}/>
                    </Box>


                    <Typography variant='h6' color="secondary" align='center' className={classes.pricePizza}>
                        {!props.loading && props.name ? props.name : <Skeleton height='1rem'/>}
                    </Typography>
                    <Typography variant='body1' color="textSecondary" component="p" className={classes.ingr}>
                        {props.constIngadient.map((ingrF, index) => (
                            <span key={index}>{ingrF.constIngr}, </span>
                        ))}
                        {props.letIngadient.map((ingr, index) => (
                            <span key={index}>{ingr.letIngr}, </span>
                        ))}
                    </Typography>
                </CardActionArea>

                {err && <strong>Error: {JSON.stringify(err)}</strong>}
                {load && (<p>loading</p>)}
                {cheesebord && (
                    <div>

                        {cheesebord.map((value, index) => (

                                <Grid item key={index} md={12} sm={12} xs={12}>

                                    <FormControl fullWidth={true}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                icon={<AddCircleIcon color="secondary"/>}
                                                checkedIcon={<ThumbUpAltIcon/>}
                                                name="checked"
                                                checked={checked.indexOf(value) !== -1}
                                                onChange={handleToggle(value)}
                                            />}
                                        label={<Typography color='secondary'>Додати Сирний борт (сир Філадельфія) за {place === '2' ? value.price_ : value.price}.00 грн</Typography>}
                                        labelPlacement="end"
                                        style={{padding: 0, margin: 5}}
                                    />
                                    </FormControl>
                                </Grid>
                            )
                        )}

                    </div>
                )}


                <CardActions>





                    <Grid container spacing={1}
                          direction="row"
                          justify="space-between"
                          alignItems="center"
                          className={classes.marginCard}
                          style={{padding: 0}}>



                        <Grid item>
                            <Typography variant='h2' color='textPrimary' className={classes.pricePizza}>
                                {props.possibleOptions.length === 1 ? price + '.00' : 'від' + price + '.00'}
                            </Typography>
                        </Grid>


                        <Grid item style={{padding: 0}}>

                            {!props.loading && props.possibleOptions.length === 1 ?
                                <Button variant="outlined" color="secondary" disableElevation size='large'
                                        className={classes.toBox} onClick={() => {
                                    props.addPizzaToBox(addedProduct);

                                    handleClick();

                                }}>
                                    ЗАМОВИТИ
                                </Button> :
                                <Button variant="contained" color="secondary" disableElevation size='large'
                                        className={classes.toBox}>
                                    ОБРАТИ
                                </Button>
                            }
                            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                                <Alert onClose={handleClose} severity="success">
                                    До кошика додано піцу
                                </Alert>
                            </Snackbar>

                            <ChoosePizza showDialog={showDialog} handleShowDialog={handleShowDialog}
                                         pizza={props} addPizzaToBox={props.addPizzaToBox}
                                            price={price}/>

                        </Grid>


                    </Grid>


                </CardActions>


            </Box>
        </Card>
    );

}