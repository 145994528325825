import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {
    Box,
    Container, FormControl,
    Paper,
} from "@material-ui/core";

import logo from "../../images/LOGO_new.jpg";
import logo_small from "../../images/LOGO_small.png";
import logo_average from "../../images/LOGO_small.png";

import "firebase/auth"

import { useUser } from 'reactfire'
import SignUp from "../SignUp/SignUp";
import UserButton from "./UserButton";

import Fade from 'react-reveal/Fade'
import Jump from 'react-reveal/Jump';
import {useCollectionData} from "react-firebase-hooks/firestore";
import fb from 'firebase/app';


const useStyles = makeStyles((theme) => ({
    grow: {
        display:"none",
        flexGrow: 1,

        [theme.breakpoints.up('md')]: {
            display: 'block',

        },
    },

    appBar: {
        boxShadow: 'none',
        marginBottom: '3px',
        padding: '0'
    },

    margin: {
        margin: theme.spacing(0),
    },

    title: {
        fontWeight: "600",
        lineHeight: '1,4',
        color: 'rgb(61 61 61)',
        padding: '0px',
        fontSize: '1.8rem',
        [theme.breakpoints.up('md')]: {
            fontSize: '2.125rem',
        },

        [theme.breakpoints.down('xs')]: {
            display: 'block',
            fontSize: '1.4rem',
            margin: 0,
        },

    },

    schedule: {
        fontWeight: "800",
        lineHeight: '2',
        color: 'rgb(61 61 61)',
        padding: '0px',
        fontSize: '1.4rem',
        [theme.breakpoints.up('md')]: {
            fontSize: '1,6rem',
        },

        [theme.breakpoints.down('xs')]: {
            display: 'block',
            fontSize: '1.2rem',
            margin: 0,
        },

    },

    subtitle: {
        fontWeight: "700",
        lineHeight: '0.6',
        padding: '0px',
        marginLeft: 0,
        [theme.breakpoints.down('md')]: {
            width: '100%',
            height: 'auto',
            lineHeight: '1.5',
            fontSize: '1rem'


        },
    },

    logo: {
        height: '130px',
        margin: '10px',
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block',
        },

    },
    logo_small: {
        height: '80px',
        margin: '7px',
        display: 'block',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },

    },

    logo_it: {
        height: '60px',
        margin: '5px',
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },

    },

    info: {
        fontFamily: 'MrDodo',
        position: 'relative',
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(3),
        },

    },


    button: {
        margin: '15px',
        borderRadius: "33px",
        border: '2px solid ',

    },

    dialog: {
        borderRadius: "33px",

    },
    hidden: {
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    }
}));

 const PrimarySearchAppBar = ({location, setLocation, city, setCity, setPizzaBox}) => {
    const classes = useStyles();

    const user = useUser()




    // const [city, setCity] = React.useState({
    //     name: 'Житомир',
    //     phoneNumber: '067-523-35-33'
    // });
    const [open, setOpen] = React.useState(false);

    //========== City ===========

    // const [cities, load, err] = useCollectionData(
    //     fb.firestore().collection('cities'),
    //     {snapshotListenOptions: { includeMetadataChanges: true },},
    // )


         // setCity({
         //     name: "PDZUTKM",
         //     phoneNumber: "063-866-70-47",
         //     schedule: "з 10.00 до 20.00"
         // });



     console.log(city)

    // const handleChange = (event) => {
    //     setCity({
    //         name: event.target.value.name,
    //         phoneNumber: event.target.value.phoneNumber,
    //         schedule: event.target.value.schedule
    //     });
    //     localStorage.setItem('city', event.target.value.name)
    //     localStorage.setItem('place', event.target.value.location)
    //     localStorage.setItem('cityPhone', event.target.value.phoneNumber)
    //     localStorage.setItem('schedule', event.target.value.schedule)
    //     setLocation(event.target.value.location)
    //     setPizzaBox(
    //         {
    //             products: [],
    //             totalCount: 0,
    //             totalPrice: 0,
    //         }
    //     )
    // };
    //
    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    // const firebase = useFirebaseApp();

    // const signOUT = () => {
    //     firebase.auth().signOut().then(() => {
    //         // Sign-out successful
    //         console.log(user.data)
    //     }).catch((error) => {
    //         // An error happened.
    //     });
    // }

    return (
        <Paper elevation={0}>
            <Container>
            <AppBar position="static" color="transparent" className={classes.appBar}>
                <Paper elevation={0}>
                    <Toolbar style={{padding: '3px'}}>
                        <Fade left>
                        <Box>
                            <img height='100%' className={classes.logo_it} alt="Logo Italy" src={logo_small}/>
                            <img className={classes.logo} alt="Logo Pizza7stars" src={logo}/>
                            <img className={classes.logo_small} alt="Small Logo Pizza 7 Stars" src={logo_average}/>

                        </Box>
                        </Fade>

                        <Box p={1} className={classes.info}>
                            <Typography display='block' variant="h6" className={classes.title} onClick={handleOpen}>{city.phoneNumber }</Typography>
                            <FormControl>
                                <Typography variant='h5' align='left' className={classes.subtitle} color='secondary' display='inline'>
                                    Доставка піци по {city.name}
                                        {/*{load && <span>Loading</span>}*/}
                                        {/*{cities && cities.map((city, ind) => (*/}
                                        {/*    <MenuItem key={ind} value={city}>*/}
                                        {/*        <Typography variant='subtitle1' align='left' className={classes.subtitle} color='primary' display='inline'>{city.name}</Typography>*/}
                                        {/*    </MenuItem>*/}
                                        {/*))}*/}
                                </Typography>
                                <Jump>
                                <Typography display='block' variant="h6" className={classes.schedule}>працюємо {city.schedule}</Typography>
                                </Jump>
                            </FormControl>
                        </Box>



                        <div className={classes.grow}>
                        </div>



                        {user.data === null ? <SignUp /> : <UserButton/>}



                    </Toolbar>
                </Paper>
            </AppBar>
            </Container>
        </Paper>
    );
}
export default PrimarySearchAppBar