import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Typography
} from "@material-ui/core";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import {useCollectionData} from "react-firebase-hooks/firestore";
import firebase from "firebase/app";
import {ToggleButton, ToggleButtonGroup} from "@material-ui/lab";


const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '25rem',
        minHeight: '32rem',
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        flexGrow: 1,
        position: 'relative',
        color: 'red,'

    },

    img: {
        maxWidth: '100%',
    },

    paper: {
        display: 'flex',
        border: `1px solid ${theme.palette.divider}`,
        flexWrap: 'wrap',
    },


    media: {
        height: '60%',
        width: '95%',
        // paddingTop: '5%', // 16:9
        margin: '1% auto',
        '&:hover': {
            transform: 'scale(1.06)'
        }
    },

    toBox: {
        borderRadius: '2rem',
        margin: '1rem'
    },

    pricePizza: {
        fontWeight: '700',
        fontFamily: 'Comfortaa',
        fontSize: '1.9rem',
        paddding: '8px'
    },

    marginCard: {
        margin: '1% auto'
    },
    ingr: {
        marginLeft: '16px'
    },

    buttonGroup: {
        border: '0 solid',
        margin: '0'
    },

    toggleButton: {
        border: '1px solid red',
        color: 'red',
        width: '80px',

    },

}));

const StyledToggleButton = withStyles({
    root: {
        border: '2px solid red',
        fontSize: '14px',
        fontWeight: '900',
        lineHeight: '15px',
        letterSpacing: '0.25px',
        color: 'rgba(0, 0, 0, 0.87)',
        padding: '10px 10px',
        textTransform: 'none',
        width: '80px',
        '&$selected': {
            backgroundColor: 'red',
            color: 'white',
            '&:hover': {
                backgroundColor: 'red',
                color: 'white',
            },
        },
    },
    selected: {},
})(ToggleButton);


export default function ChooseBurger({showDialog, burger, handleShowDialog, addBurgerToBox, location}) {
    const classes = useStyles();
    const handleClose = () => {
        setValue('')
        setSaucePrice(0)
        handleShowDialog()

    };
    // Choose Sauce
    const [sauce, loading, error] = useCollectionData(
        firebase.firestore().collection('sauses'),
        {snapshotListenOptions: {includeMetadataChanges: true},}
    );

    const [value, setValue] = React.useState('');

    const [saucePrice, setSaucePrice] = React.useState(0)

    const handleChooseSauce = (event) => {
        setValue(event.target.value);
        location === 2 ? setSaucePrice(sauce[0].price_) : setSaucePrice(sauce[0].price)
        value !== {} ?
            location === 2 ? setSaucePrice(sauce[0].price_) : setSaucePrice(sauce[0].price)
                : setSaucePrice(0);
    };

    const handleOrder = () => {
        addBurgerToBox(addedProduct)
        handleClose()
    }


    // // Switch SIZE
    // const [alignment, setAlignment] = React.useState('L');
    //
    // const handleAlignment = (event, newAlignment) => {
    //     setAlignment(newAlignment);
    // };

    // const BurgerIndex = burger.possibleOptions.findIndex((item) => item.size === alignment)
    //
    // let product
    //
    // if (SnackIndex > -1) {
    //     product = snack.possibleOptions[SnackIndex]
    // } else {
    //     product = snack.possibleOptions[0]
    // }

    let price
    if(location === 2){
        price = burger.price_
    } else {
        price = burger.price
    }


    const addedProduct = {
        category: 'Бургер',
        capacity: '',
        options: {
            chooseVariant: '',
            chooseSauce: {
                sauceName: value,
                saucePrice: saucePrice,
            },
            deletedProducts: [],
            addedProducts: []
        },
        name: burger.name,
        imageURL: burger.pictures.src,
        price: price + saucePrice,
        count: 1,
    }


    return (
        <div>

            <Dialog open={showDialog} onClose={handleShowDialog} aria-labelledby="form-dialog-title"
                    maxWidth='md'>
                <DialogContent>
                    <Grid container
                          spacing={1}
                          direction="row"
                          justify="flex-start"
                          alignItems="flex-start">


                        <Grid item xs={12} sm={6}>
                            <img src={burger.pictures.src} alt={burger.name} className={classes.img}/>
                        </Grid>

                        <Grid item xs={12} sm={1}/>

                        <Grid item xs={12} sm={5}>

                            <Grid container
                                  spacing={1}
                                  direction="column"
                                  justify="flex-start"
                                  alignItems="flex-start">
                                <Grid item>
                                    <Typography variant='h6' color="secondary" align='left'
                                                className={classes.pricePizza}>
                                        Бургери
                                    </Typography> <Typography variant='h6' color="secondary" align='left'
                                                              className={classes.pricePizza}>
                                    "{burger.name}"
                                </Typography>
                                </Grid>

                                <Grid item>
                                    <Typography variant='subtitle1'>Опис:
                                    </Typography>
                                    <Typography display='inline' variant='subtitle2'>{burger.info}
                                    </Typography>
                                </Grid>
                                {/*<Grid item>*/}
                                {/*    <Typography variant='subtitle1'>Обрано:*/}
                                {/*    </Typography>*/}
                                {/*    <Typography display='inline' variant='subtitle2'>розмір {product.size} кількість*/}
                                {/*    </Typography>*/}
                                {/*    <Typography display='inline' variant='subtitle2'> ({product.weight})*/}
                                {/*    </Typography>*/}
                                {/*</Grid>*/}


                                {/*Switch SIZE*/}
                                {/*<Grid item>*/}
                                {/*    <ToggleButtonGroup*/}
                                {/*        value={alignment}*/}
                                {/*        exclusive*/}
                                {/*        onChange={handleAlignment}*/}
                                {/*        aria-label="text alignment"*/}
                                {/*    >*/}
                                {/*        <StyledToggleButton value={snack.possibleOptions[0].size} aria-label="L">*/}
                                {/*            {snack.possibleOptions[0].size}*/}
                                {/*        </StyledToggleButton>*/}
                                {/*        <StyledToggleButton value={snack.possibleOptions[1].size} aria-label="XL">*/}
                                {/*            {snack.possibleOptions[1].size}*/}
                                {/*        </StyledToggleButton>*/}
                                {/*        <StyledToggleButton value={snack.possibleOptions[2].size} aria-label="XXL">*/}
                                {/*            {snack.possibleOptions[2].size}*/}
                                {/*        </StyledToggleButton>*/}
                                {/*    </ToggleButtonGroup>*/}
                                {/*</Grid>*/}


                                <Grid item>


                                    <Typography variant='h6' color='secondary'>Додати соус +
                                        {saucePrice} грн
                                    </Typography>
                                    {sauce &&

                                    <FormControl component="fieldset">
                                        <RadioGroup aria-label="gender" name="gender1" value={value}
                                                    onChange={handleChooseSauce}>

                                            {sauce.map((sauce, index) => (
                                                <FormControlLabel key={index} value={sauce.name}
                                                                  control={<Radio style={{padding: 3}}/>}
                                                                  label={sauce.name}/>))}

                                        </RadioGroup>
                                    </FormControl>
                                    }


                                </Grid>
                            </Grid>


                        </Grid>

                    </Grid>


                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        До меню
                    </Button>
                    <Button variant="outlined" color="secondary" disableElevation size='large' className={classes.toBox}
                            onClick={handleOrder}
                    >
                        Замовити за {addedProduct.price} грн
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}