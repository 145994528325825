import React from "react";
import {Box, Divider, Grid, IconButton, makeStyles, Paper, Typography} from "@material-ui/core";
import chefImg from '../../../images/Chef_pizza.jpg'
import Icon from '@material-ui/core/Icon'
import Resale from "./Resale";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,

    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        minWidth: '100%',
        [theme.breakpoints.down('xs')]: {
            fontSize: '0.5rem',

        },
    },

    price: {
        margin: '5px'

    },

    pricePizza: {
        fontWeight: '700',
        fontFamily: 'Comfortaa',
        fontSize: '1.6rem',
        padding: '8px',
        marginBottom: '10px',

    },
    priceText: {
        fontWeight: '600',
        fontFamily: 'Comfortaa',
        fontSize: '1.2rem',
        padding: '8px',
        [theme.breakpoints.down('xs')]: {
            fontSize: '0.9rem',

        },
    },
    divImg: {
        margin: 'auto',
        width: '300px'
    },
    img: {
        maxWidth: '100%',
    },
    imgBasket: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        },
    },
}));

export default function Basket({
                                   pizzaBox,
                                   onClose,
                                   incrProductCount,
                                   delProduct,
                                   decrProductCount,
                                   addSaucesToBox,
                                   addDrinkToBox,
                                   location
                               }) {
    const classes = useStyles();


    return (
        <Box>
            <Typography variant='h3' color="secondary" align='left' className={classes.pricePizza}>
                Ваш кошик
            </Typography>
            {pizzaBox.products.length < 1 ?
                <Box>
                    <Divider/>
                    <Typography variant='h6' color='primary' align='center' className={classes.priceText}>
                        Тут ще порожньо...
                    </Typography>
                    <img src={chefImg} alt='chef waiting' className={classes.img}/>
                    <Divider/>
                    <Typography variant='h6' color='primary' align='center' className={classes.priceText}>
                        Наша команда приготує для Вас найсмачнішу піцу
                    </Typography>
                    <Typography variant='h6' color='secondary' align='center' className={classes.priceText}
                                onClick={() => {
                                    onClose()
                                }}>
                        зробіть замовлення
                    </Typography>

                </Box> :

                //Basket table

                <Grid
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="center"
                    spacing={2}
                >

                    {pizzaBox.products.map((product, index) => (


                        <Grid xl={12} xs={12} item key={index}>

                            <Divider variant="middle"/>
                            <Grid
                                container
                                direction="row"
                                justify="space-evenly"
                                alignItems="center"
                                wrap
                                spacing={2}
                                xs={12}


                            >
                                <Grid item xs={1} md={1} className={classes.imgBasket}>
                                    <Paper elevation={0} className={classes.imgBasket}>
                                        <img src={product.imageURL} alt="product" className={classes.imgBasket}/>
                                    </Paper>

                                </Grid>
                                <Grid item xs={8} sm={4}>
                                    <Paper elevation={0}>
                                        <Typography color='secondary' variant='subtitle2'
                                                    align='left'>{product.category}</Typography>
                                        <Typography variant='h6' align='left'>{product.name}
                                            {product.capacity &&
                                            <span> {product.capacity} </span>}
                                        </Typography>

                                        {product.options.addedProducts &&
                                        product.options.addedProducts.map((added, id) => (
                                            <div>
                                                <Typography variant='caption' align='left' display="block"> До піци
                                                    додано:
                                                    <span key={id}> {added.name} </span>
                                                </Typography>
                                            </div>
                                        ))}

                                        {product.options.deletedProducts &&
                                        product.options.deletedProducts.map((dell, id) => (
                                            <Typography variant='caption' align='left' display="block"> Виключити:
                                                <span key={id}> {dell.letIngr} </span>
                                            </Typography>
                                        ))}

                                        {product.options.chooseSauce &&
                                        <Typography variant='caption' align='left' display="block"> Додано соус:
                                            <span style={{
                                                color: 'red',
                                                fontWeight: 'bold'
                                            }}> {product.options.chooseSauce.sauceName} </span> за ціною:
                                            <span> {product.options.chooseSauce.saucePrice}.00 </span>
                                        </Typography>
                                        }


                                    </Paper>
                                </Grid>
                                <Grid item/>

                                <Grid item xs={3} sm={1}>
                                    <Typography variant='h5' align='center'
                                                color='textPrimary'>{product.price}</Typography>
                                </Grid>
                                <Grid item/>

                                <Grid item xs={1} sm={1}>
                                    <IconButton aria-label="plus" onClick={() => {
                                        incrProductCount(index)
                                    }} disabled={product.category.includes('Акція 50')}>
                                        <Icon color='primary' fontSize="small">add_circle_outline</Icon>
                                    </IconButton>
                                </Grid>
                                <Grid item/>

                                <Grid item xs={2} sm={1}>
                                    <Typography variant='h6' align='center'
                                                className={classes.price}>{product.count}</Typography>
                                </Grid>


                                <Grid item xs={2} sm={1}>
                                    <IconButton aria-label="minus" onClick={() => {
                                        decrProductCount(index)
                                    }} disabled={product.category.includes('Акція 50')}>
                                        <Icon color='primary' fontSize="small">remove_circle_outline</Icon>
                                    </IconButton>
                                </Grid>

                                <Grid item xs={2} sm={1}>
                                    <IconButton aria-label="delete" onClick={() => {
                                        delProduct(index)
                                    }} disabled={product.category === 'Піца Акція -50% на другу'}>
                                        <Icon color='secondary' fontSize="small">delete_sweep</Icon>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>))}


                </Grid>


            }
            <Paper elevation={0} className={classes.paper}>
                <Divider/>
                <Resale addSaucesToBox={addSaucesToBox}
                        addDrinkToBox={addDrinkToBox}
                        location={location}

                />
            </Paper>
        </Box>
    )

}

export function MiniBasket({pizzaBox, incrProductCount, delProduct, decrProductCount}) {
    const classes = useStyles()
    return (
        <Paper elevation={0} style={{margin: '10px'}}>
            {pizzaBox.products.length < 1 ?
                <Box elevation={0}>
                    <Typography variant='h6' color='primary' align='center'>
                        Тут ще порожньо...
                    </Typography>
                    <div className={classes.divImg}>
                        <img src={chefImg} alt='chef waiting' className={classes.img}/>
                    </div>
                    <Divider/>
                    <Typography variant='body1' color='primary' align='center'>
                        Ми приготуємо для Вас найсмачнішу піцу
                    </Typography>
                    <Typography variant='h6' color='secondary' align='center'
                    >
                        зробіть замовлення
                    </Typography>

                </Box> :

                //Basket table
                <Grid
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="center"
                    spacing={2}
                >

                    {pizzaBox.products.map((product, index) => (


                        <Grid xs item key={index}>

                            <Divider variant="middle"/>
                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="center"
                                wrap="nowrap"
                                spacing={2}


                            >
                                <Grid item xs={5}>
                                    <Typography display='block' color='secondary' variant='subtitle2'
                                                align='left'>{product.category}</Typography>
                                    <Typography display='block' variant='body1' align='left'
                                                style={{width: '150px'}}>{product.name}
                                        {product.capacity &&
                                        <span> {product.capacity} </span>}
                                    </Typography>

                                    {(product.options.addedProducts.length !==0 || product.options.deletedProducts.length !==0 ) &&
                                    <Typography variant='caption' align='left' display="block">Рецепт змінено
                                    </Typography>
                                    }

                                    {product.options.chooseSauce &&
                                    <Typography variant='caption' align='left' display="block"
                                                style={{width: '150px'}}> Додано соус:
                                        <span style={{
                                            color: 'red',
                                            fontWeight: 'bold'
                                        }}> {product.options.chooseSauce.sauceName} </span> за ціною:
                                        <span> {product.options.chooseSauce.saucePrice}.00 </span>
                                    </Typography>
                                    }
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography display='block' variant='body2' align='right' color='textPrimary'
                                                style={{width: '40px'}}>{product.price}</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton aria-label="plus" onClick={() => {
                                        incrProductCount(index)
                                    }} disabled={product.category.includes('Акція 50')}>
                                        <Icon fontSize="small">add_circle_outline</Icon>
                                    </IconButton>
                                </Grid>
                                <Grid item/>
                                <Grid item xs={1}>
                                    <Typography display='block' variant='h6' align='center'
                                                style={{width: '30px'}}>{product.count}</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton aria-label="minus" onClick={() => {
                                        decrProductCount(index)
                                    }} disabled={product.category.includes('Акція 50')}>
                                        <Icon fontSize="small">remove_circle_outline</Icon>
                                    </IconButton>
                                </Grid>'
                                <Grid item xs={1}>
                                    <IconButton aria-label="delete" onClick={() => {
                                        delProduct(index)
                                    }} disabled={product.category === 'Піца Акція -50% на другу'}>
                                        <Icon color='secondary' fontSize="small">delete_sweep</Icon>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>))}
                </Grid>

            }

        </Paper>
    )

}

